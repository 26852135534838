import { CustomUploadResponse } from '@geneo2-web/shared-ui';
import { HomeworkContent } from '@protos/learning_management/lms.hw.common.apis_pb';
import {
  HWStudentResponse,
  StudentHWAttemptResult,
  StudentHWCountInfo,
  StudentHomeworkTask,
} from '@protos/learning_management/lms.hw.student.apis_pb';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// import {} from '@protos/common/'

export interface IHomeworkState {
  selected_module_id: string;
  home_homework_list?: StudentHomeworkTask[];
  ended_homework_list?: StudentHomeworkTask[];
  active_homework_Id?: string;
  active_homework_content?: {
    [homeworkId: string]: HomeworkContent | undefined;
  };
  active_homework_student_response?: {
    [homeworkId: string]: HWStudentResponse | undefined;
  };
  active_homework_attempt_result?: {
    [homeworkId: string]: StudentHWAttemptResult | undefined;
  };
  homework_end_path?: string;
  active_homework_session_id?: number;
  selected_homework_data?: {
    homeworkId: number;
    questionIds: string[];
    resumeCheck: boolean;
    reattemptedCheck: boolean;
    SubmissionType: number;
  };
  assigned_homework?: { [pageNumber: number]: StudentHomeworkTask[] };
  ended_homework?: { [pageNumber: number]: StudentHomeworkTask[] };
  hw_info?: {
    hwListInfo: StudentHWCountInfo | undefined;
    totalAssignedPages: number;
    totalEndedPages: number;
  };
  student_hw_upload_data: CustomUploadResponse[];
}

const initialState: IHomeworkState = {
  selected_module_id: 'abcd',
  home_homework_list: [],
  ended_homework_list: [],
  active_homework_content: undefined,
  active_homework_student_response: undefined,
  active_homework_attempt_result: undefined,
  active_homework_Id: undefined,
  homework_end_path: undefined,
  selected_homework_data: undefined,
  assigned_homework: {},
  ended_homework: {},
  hw_info: {
    hwListInfo: undefined,
    totalAssignedPages: 1,
    totalEndedPages: 1,
  },
  student_hw_upload_data: [],
};

export const homeworkSlice = createSlice({
  name: 'homework',
  initialState,
  reducers: {
    setActiveHomeworkList: (state, action) => {
      state.home_homework_list = action.payload;
    },
    setEndedHomeworkList: (state, action) => {
      state.ended_homework_list = action.payload;
    },
    setActiveHomeworkId: (state, action) => {
      state.active_homework_Id = action.payload;
    },
    setActiveHomeworkContent: (
      state,
      action: PayloadAction<{
        homeworkId: number;
        homeworkContent?: HomeworkContent;
      }>
    ) => {
      state.active_homework_content = {
        ...state.active_homework_content,
        [action.payload.homeworkId.toString()]: action.payload.homeworkContent,
      };
    },
    setActiveHomeworkStudentResponse: (
      state,
      action: PayloadAction<{
        homeworkId: number;
        response?: HWStudentResponse;
      }>
    ) => {
      state.active_homework_student_response = {
        ...state.active_homework_student_response,
        [action.payload.homeworkId.toString()]: action.payload.response,
      };
    },
    setActiveHomeworSessionId: (state, action) => {
      state.active_homework_session_id = action.payload;
    },
    setActiveHomeworkAttemptResult: (
      state,
      action: PayloadAction<{
        homeworkId: number;
        result?: StudentHWAttemptResult;
      }>
    ) => {
      state.active_homework_attempt_result = {
        ...state.active_homework_attempt_result,
        [action.payload.homeworkId.toString()]: action.payload.result,
      };
    },
    setHomeworkEndPath: (state, action: PayloadAction<string | undefined>) => {
      state.homework_end_path = action.payload;
    },
    setSelectedHomeworkData: (state, action) => {
      state.selected_homework_data = action.payload;
    },
    setAssignedData: (state, action) => {
      state.assigned_homework = action.payload;
    },
    setEndedData: (state, action) => {
      state.ended_homework = action.payload;
    },
    setHwInfoData: (state, action) => {
      state.hw_info = action.payload;
    },

    setStudentHwUploadData: (state, action) => {
      state.student_hw_upload_data = action.payload;
    },

    resetHomeworkState: (state) => {
      return initialState;
    },
  },
});

export const {
  setActiveHomeworkList,
  setEndedHomeworkList,
  setActiveHomeworkId,
  setActiveHomeworkContent,
  setActiveHomeworkStudentResponse,
  setActiveHomeworkAttemptResult,
  resetHomeworkState,
  setHomeworkEndPath,
  setActiveHomeworSessionId,
  setSelectedHomeworkData,
  setAssignedData,
  setEndedData,
  setHwInfoData,
  setStudentHwUploadData,
} = homeworkSlice.actions;
export default homeworkSlice.reducer;
