import { PartialMessage } from '@bufbuild/protobuf';
import {
  IStyles,
  IconWrapper,
  ImageWrapper,
  ScoreProgressBarLinear,
  SecondaryButton,
  getFileTypeName,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { CloudUpload } from '@protos/learning_management/lms.db_pb';
import { UploadFileResponse } from '@protos/learning_management/lms.hw.student.apis_pb';
import { ChangeEvent, DragEvent, useRef } from 'react';
import { CustomUploadResponse } from '../../../commonUtils/utilTypes';

const styles: IStyles = {
  root: {
    border: '1px dashed #CCE6FE',
    width: '100%',
    maxWidth: { md: pxTovW(620), lg: pxTovW(520) },
    maxHeight: { md: pxTovW(780), lg: pxTovW(680) },
    boxSizing: 'border-box',
    p: {
      xs: `${pxToRem(15)} ${pxToRem(5)}`,
      md: `${pxTovW(27)} ${pxTovW(18)}`,
    },
    overflowY: { md: 'auto' },
    // margin: 'auto',
  },
  fileCollector: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: pxToRem(25), md: pxTovW(14) },
  },
  plusInGray: {
    width: { xs: pxToRem(50), md: pxTovW(69) },
    height: { xs: pxToRem(50), md: pxTovW(69) },
  },
  uploadButton: {
    width: { lg: pxTovW(112) },
    height: { lg: pxTovW(40) },
    backgroundColor: 'primary.main',
    '&:hover': {
      backgroundColor: 'info.main',
    },
  },

  fileDetails: {
    mt: { xs: pxToRem(15), md: pxTovW(30) },
  },
  loadingInfoBox: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(14), md: pxTovW(10) },
    width: 'max-content',
    margin: 'auto',
  },
  fileNameSizeBox: {
    display: 'flex',
    gap: { xs: pxToRem(5), md: pxTovW(14) },
    maxWidth: { xs: pxToRem(190), md: pxTovW(300) },
    boxSizing: 'border-box',
  },
  nameTypo: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
  percentageAndIconBox: {
    display: 'flex',
    gap: { xs: pxToRem(5), md: pxTovW(14) },
  },
  deleteFileHandler: {
    height: { xs: pxToRem(14), md: pxTovW(16) },
    width: { xs: pxToRem(14), md: pxTovW(16) },
    cursor: 'pointer',
  },
};

interface IProps {
  handleDropFunction: (event: DragEvent<HTMLDivElement>) => void;
  handleChangeFunction: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFunction: (indexToDelete: number) => void;
  rootStyle?: SxProps<Theme> | undefined;
  userUploads: CustomUploadResponse[];
}

export const DragAndDropFiles = (props: IProps) => {
  const {
    handleDropFunction,
    handleChangeFunction,
    handleDeleteFunction,
    rootStyle,
    userUploads,
  } = props;

  let rootSx = { ...styles.root };
  if (rootStyle) rootSx = { ...rootSx, ...rootStyle };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const inputRef = useRef<HTMLInputElement | null>(null);
  const countFileUploadSuccess = userUploads
    .filter((data) => data.uploadFileData.isDeleted === false)
    .filter((upload) => upload.fileUploadStatus === 'success').length;
  const totalNonDeletedFiles = userUploads.filter(
    (data) => data.uploadFileData.isDeleted === false
  ).length;
  const isAnyFilePending = userUploads?.some(
    (item) => item.fileUploadStatus === 'pending'
  );
  const tempFileUploadPercentage = 0;

  // Type guard
  function isUploadFileResponse(
    data: PartialMessage<UploadFileResponse> | PartialMessage<CloudUpload>
  ): data is PartialMessage<UploadFileResponse> {
    return 'originalImagePath' in data;
  }

  // Type guard
  function isCloudUpload(
    data: PartialMessage<UploadFileResponse> | PartialMessage<CloudUpload>
  ): data is PartialMessage<CloudUpload> {
    return 'fileName' in data;
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (isAnyFilePending) return;
    await handleDropFunction(event);
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    await handleChangeFunction(event);
  };

  const handleDelete = async (indexToDelete: number) => {
    await handleDeleteFunction(indexToDelete);
  };

  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i];
  };

  return (
    <Box
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      sx={{ ...rootSx, cursor: isAnyFilePending ? 'wait' : 'default' }}
    >
      <input
        type="file"
        multiple
        hidden
        ref={inputRef}
        onChange={handleChange}
      />

      <Box sx={styles.fileCollector}>
        <ImageWrapper
          // onClick={() => {  }}
          name="white_plus_in_gray"
          type="png"
          parentFolder="icons"
          styles={styles.plusInGray}
        />
        <Typography variant={isMobile ? 'h2' : 'h3'} fontWeight={'regular'}>
          Drag & Drop Files Or Upload
        </Typography>

        <SecondaryButton
          onClick={() => {
            if (inputRef.current) inputRef.current.click();
          }}
          disabled={isAnyFilePending}
          styles={styles.uploadButton}
        >
          <Typography
            variant={isMobile ? 'h3' : 'h5'}
            fontWeight={'medium'}
            color="common.white"
          >
            UPLOAD
          </Typography>
        </SecondaryButton>
      </Box>

      {userUploads && userUploads.length > 0 && (
        <Box sx={styles.fileDetails}>
          <Box sx={styles.loadingInfoBox}>
            {isAnyFilePending && (
              <CircularProgress
                color="primary"
                size={isMobile ? pxToRem(15) : pxTovW(24)}
              />
            )}

            {totalNonDeletedFiles > 0 && (
              <Typography variant="h4" color="info.main">
                {`${countFileUploadSuccess} out of ${totalNonDeletedFiles} uploaded`}
              </Typography>
            )}
          </Box>

          <Box sx={{ mt: { md: pxTovW(37) } }}>
            {userUploads.map((file, index) => {
              if (file.uploadFileData.isDeleted === true) return null;
              else
                return (
                  <Box
                    key={index}
                    sx={{
                      pt: {
                        xs: pxToRem(14),
                        md: index !== 0 ? pxTovW(17) : pxTovW(0),
                      },
                      pb: {
                        xs:
                          index !== userUploads.length - 1
                            ? pxToRem(14)
                            : pxToRem(0),
                        md:
                          index !== userUploads.length - 1
                            ? pxTovW(17)
                            : pxTovW(0),
                      },
                      borderBottom:
                        index !== userUploads.length - 1
                          ? '1px solid #D5D5D5'
                          : 'none',

                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: { xs: pxToRem(10), md: pxTovW(15) },
                    }}
                  >
                    <IconWrapper
                      name={getFileTypeName(file.uploadFileData.fileType)}
                      size="md"
                      parentFolder="icons"
                      type="png"
                    />

                    <Box sx={{ flexGrow: '1' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={styles.fileNameSizeBox}>
                          <Typography
                            variant="h5"
                            color="primary.main"
                            sx={styles.nameTypo}
                          >
                            {isUploadFileResponse(file.uploadFileData)
                              ? file.uploadFileData.originalImagePath
                              : isCloudUpload(file.uploadFileData)
                              ? file.uploadFileData.fileName
                              : ''}
                          </Typography>

                          <Typography
                            variant="h5"
                            color="neutral.grey"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            {formatFileSize(file.fileSize)}
                          </Typography>
                        </Box>

                        <Box sx={styles.percentageAndIconBox}>
                          <Typography
                            variant="h5"
                            textAlign="center"
                            color={
                              file.fileUploadStatus === 'pending' ||
                              file.fileUploadStatus === 'success'
                                ? 'primary.main'
                                : 'neutral.crimson'
                            }
                          >
                            {/* //todo: Get percentage of the uploaded file % */}
                            {file.fileUploadStatus === 'success'
                              ? '100%'
                              : file.fileUploadStatus === 'pending'
                              ? tempFileUploadPercentage + '%'
                              : file.fileUploadStatus}
                          </Typography>

                          {/*//^ Green tick OR Error Symbol */}
                          {file.fileUploadStatus !== 'pending' && (
                            <IconWrapper
                              name={
                                file.fileUploadStatus === 'success'
                                  ? 'green-circle-check'
                                  : 'info_red'
                              }
                              size="md"
                              parentFolder="icons"
                              type="png"
                            />
                          )}
                        </Box>
                      </Box>

                      {/* //todo: Get percentage of the uploaded file % */}
                      <ScoreProgressBarLinear
                        score={
                          file.fileUploadStatus === 'success'
                            ? 100
                            : file.fileUploadStatus === 'pending'
                            ? tempFileUploadPercentage
                            : 0
                        }
                        variant="small"
                        customSx={{
                          mt: { xs: pxToRem(10), md: pxTovW(11) },
                          backgroundColor:
                            file.fileUploadStatus === 'pending' ||
                            file.fileUploadStatus === 'success'
                              ? '#CBF0DC'
                              : 'neutral.lightPink',
                        }}
                      />
                    </Box>

                    {file.fileUploadStatus !== 'pending' && (
                      <IconWrapper
                        name="white_x_in_gray"
                        size="md"
                        parentFolder="icons"
                        type="png"
                        onClick={() => handleDelete(index)}
                        customSx={styles.deleteFileHandler}
                      />
                    )}
                  </Box>
                );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};
