// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/analysis_management/analysis.assessment.apis.proto (package geneo.analysis.school.assessment.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FetchAssessmentReportRequest, FetchAssessmentReportResponse } from "./analysis.assessment.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1
 */
export const SchoolAssessmentAPIServiceV1 = {
  typeName: "geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.analysis.school.assessment.apis.SchoolAssessmentAPIServiceV1.fetchSchoolAssessmentReport
     */
    fetchSchoolAssessmentReport: {
      name: "fetchSchoolAssessmentReport",
      I: FetchAssessmentReportRequest,
      O: FetchAssessmentReportResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

