import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  IStyles,
  ImageWrapper,
  SecondaryOutlinedButton,
  StudentSubjectPopup,
  deserify,
  firstLetterImage,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalContext } from '../../app/Context/GlobalContextProvider';
import {
  setHomeSelectedBookId,
  setHomeSelectedSubjectId,
} from '../../pages/Home/reducer/homeDashboard.slice';
import { useAppDispatch, useAppSelector } from '../../reduxStore/reduxHooks';
import { HOME } from '../../routeHandling/RoutesNomenclature';
import { getSubjectsMap } from '../../utils/icons';
import { useSideBarOptions } from '../../utils/sidebarOptions';

import { interactionEvent } from '../../pages/Auth/Login/login_logout.events';
import {
  setActiveHomeworSessionId,
  setStudentHwUploadData,
} from '../../pages/Homework/reducer/homework.slice';
import {
  aiBookCloseEvent,
  aiBookOpenEvent,
} from '../../pages/Learn/utils_learn/learn.events';

const styles: IStyles = {
  root: {
    width: '100vw',
    height: { xs: pxToRem(60), md: pxTovW(100) },
    background: 'grey',
    position: 'fixed',

    borderBottomWidth: pxTovW(1),
    borderBottomStyle: 'solid',
    borderBottomColor: 'neutral.grey',
    boxSizing: 'border-box',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: { xs: `0 ${pxToRem(20)}`, lg: `0 ${pxTovW(240)}` },
    bgcolor: 'common.white',
    zIndex: 1,
  },

  leftArrow: {
    cursor: 'pointer',
    width: { xs: pxToRem(14), md: pxTovW(19) },
    height: { xs: pxToRem(13), md: pxTovW(26) },
    flexBasis: { xs: pxToRem(14), md: pxTovW(19) },
  },
  logo: {
    cursor: 'pointer',
    width: { xs: pxToRem(75), md: pxTovW(100) },
    height: { xs: pxToRem(42), md: pxTovW(56) },
    flexFlow: '1',
  },

  userBox: {
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center',
    gap: pxTovW(10),
  },

  menu: {
    mt: pxTovW(20),
    '& .MuiMenu-list': {
      width: { md: pxTovW(450), lg: pxTovW(352) },
      p: { md: `${pxTovW(12)}`, lg: `${pxTovW(12)} ${pxTovW(10)}` },
      borderRadius: pxTovW(10),
      boxShadow: `0 0 ${pxTovW(10)} #00000038`,
    },
    '& .MuiMenu-paper': {
      borderRadius: pxTovW(10),
    },
  },
  menuItem: {
    borderBottom: '1px solid #D2D2D2',
    p: { md: `${pxTovW(20)} ${pxTovW(15)}` },
    '&:last-child': {
      borderBottom: '1px solid transparent',
    },
    '&:hover': {
      backgroundColor: '#F2F2F2',
      borderBottom: '1px solid transparent',
      borderRadius: pxTovW(10),
    },
  },

  userImage: {
    width: pxTovW(55),
    height: pxTovW(55),
    borderRadius: '50%',
  },

  downArrow: {
    cursor: 'pointer',
    width: pxTovW(13),
    height: pxTovW(7),
  },

  menuTriple: {
    width: pxToRem(16),
    height: pxToRem(14),
  },
};

interface IProps {
  sidebarDisplay: boolean;
  setSidebarDisplay: (arg0: boolean) => void;
}

export default function Header(props: IProps) {
  const { setSidebarDisplay, sidebarDisplay } = props;
  const currentPage = window.location.pathname;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const selectedSubjectId = deserify(
    useAppSelector((state) => state.home.selected_subject_id)
  );
  const { selected_book_id } = deserify(useAppSelector((state) => state.home));
  const options = useSideBarOptions();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const userInfo = deserify(useAppSelector((state) => state.auth.userInfo));
  const lessonSessionId =
    new URLSearchParams(location.search).get('lessonSessionId') || undefined;

  const learnSubjects = userInfo?.learnSubjects || [];
  const subMap = getSubjectsMap(learnSubjects);
  //^ StudentSubjectPopup
  const [modalState, setModalState] = useState(false);
  const navigate = useNavigate();
  const { selectedFunction, setSelectedFunction } = useGlobalContext();

  const selectedChapterResources = deserify(
    useAppSelector((state) => state.learn.selected_chapter_resources)
  );
  const {
    selected_module_lessons,
    selected_lesson_content,
    selected_resource_content,
    selected_resource_info,
    selected_resource_session_id,
  } = deserify(useAppSelector((state) => state.learn));
  const { performance_id } = deserify(
    useAppSelector((state) => state.performance)
  );
  const { selected_homework_data, active_homework_session_id } = deserify(
    useAppSelector((state) => state.homework)
  );

  const goBack = async () => {
    if (selectedFunction) {
      selectedFunction();
      setSelectedFunction(null);
      return;
    } else if (options.some((elem) => elem.route === currentPage)) {
      navigate(HOME);
    } else {
      navigate(-1);
    }
    await interactionEvent({
      url: '',
      context: 'left_arrow',
      name: 'BACK',
      pathSegments: pathSegments,
      lessonSesssionId: lessonSessionId,
      resourceSesssionId: selected_resource_session_id?.toString(),
      homeworkSesssionId: active_homework_session_id?.toString(),
    });
  };

  const commonAiCloseEvents = async () => {
    // if no selectedSubjectId then that means no book should be open
    // if (currentPage !== HOME && !selectedSubjectId) {
    //   aiBookCloseEvent(selected_book_id);
    // }

    // aiChapterCloseEvent(selectedChapterResources?.chapterId);
    // aiTopicCloseEvent(selected_module_lessons?.moduleId);
    // aiLessonCloseEvent(selected_lesson_content?.lessonId);
    // aiResourceCloseEvent(selected_resource_content?.parsedData.resourceId);
    // aiResourcePageCloseEvent(
    //   selected_resource_content?.parsedData.resourceId,
    //   selected_resource_info?.pageNumber
    // );
    // aiContentCloseEvent({
    //   pageNumber: selected_resource_info?.pageNumber,
    //   pageContent: selected_resource_info?.pageContent,
    // });
    // aiPerformanceCloseEvent(performance_id);
    // dispatch(setPerformanceId(undefined));
    // aiHomeworkCloseEvent({ homeworkId: selected_homework_data?.homeworkId });
    // aiHomeworkReviewCloseEvent({
    //   homeworkId: selected_homework_data?.homeworkId,
    // });

    await interactionEvent({
      url: '',
      context: 'navigation_bar',
      name: 'GENEO',
      pathSegments: pathSegments,
      lessonSesssionId: lessonSessionId,
      resourceSesssionId: selected_resource_session_id?.toString(),
      homeworkSesssionId: active_homework_session_id?.toString(),
    });
    dispatch(setActiveHomeworSessionId(undefined));
    dispatch(setStudentHwUploadData([])); //not needed
  };

  const logoClickHandler = async () => {
    await commonAiCloseEvents();
    navigate(HOME);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = async (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLImageElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    await interactionEvent({
      url: '',
      context: 'Navigation_bar',
      name: 'MENU',
      pathSegments: pathSegments,
      lessonSesssionId: lessonSessionId,
      resourceSesssionId: selected_resource_session_id?.toString(),
      homeworkSesssionId: active_homework_session_id?.toString(),
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={styles.root}>
      {currentPage !== HOME ? (
        <Box onClick={goBack}>
          <KeyboardBackspaceIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
        </Box>
      ) : (
        <Box sx={{ display: 'none' }}></Box>
      )}

      <Box
        // onClick={logoClickHandler}
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(14), md: pxTovW(25) },
          margin: { xs: 'auto', md: 'unset' },
        }}
      >
        <ImageWrapper
          name="geneo-logo"
          type="png"
          parentFolder="images"
          styles={styles.logo}
          onClick={logoClickHandler}
        />
        {currentPage === HOME && !isMobile ? (
          <Box>
            <SecondaryOutlinedButton
              size={isIpadOnly ? 'medium' : 'small'}
              witharrow={typeof selectedSubjectId == 'undefined' ? true : false}
              onClick={async () => {
                await interactionEvent({
                  url: '',
                  context: 'Subject_selection',
                  name: 'CHOOSE_SUBJECT',
                  pathSegments: pathSegments,
                });

                setModalState(true);
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h4" fontFamily={'poppins'}>
                {typeof selectedSubjectId == 'number' &&
                subMap[selectedSubjectId]
                  ? subMap[selectedSubjectId].subjectName
                  : 'Choose Subject'}
              </Typography>
              {typeof selectedSubjectId == 'number' && (
                <CloseIcon
                  sx={{
                    color: 'common.black',
                    fontSize: {
                      xs: pxToRem(14),
                      md: pxToRem(14),
                      lg: pxTovW(18),
                    },
                  }}
                  onClick={async (ev) => {
                    ev.stopPropagation();

                    await aiBookCloseEvent(selected_book_id);
                    // console.log('aiBookCloseEvent  - big screen');

                    dispatch(setHomeSelectedSubjectId(undefined));
                    dispatch(setHomeSelectedBookId(undefined));
                  }}
                />
              )}
            </SecondaryOutlinedButton>
          </Box>
        ) : (
          <Box sx={{ display: 'none' }}></Box>
        )}
      </Box>

      <Box sx={styles.userBox}>
        <ImageWrapper
          name="userBlue"
          path={
            userInfo?.profilePics[0]?.url ||
            firstLetterImage(userInfo?.firstName)
          }
          type="png"
          parentFolder="icons"
          styles={styles.userImage}
        />

        <Typography
          sx={{ textTransform: 'capitalize' }}
          variant="bodyText"
          fontWeight="medium"
        >
          {userInfo?.firstName} {userInfo?.lastName}
        </Typography>

        <Box onClick={(e) => handleClick(e)} pt={pxTovW(10)}>
          <KeyboardArrowDownIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={styles.menu}
        >
          {options.map((opt) => (
            <MenuItem
              key={opt.name}
              onClick={() => {
                handleClose();
                opt.onClick();
              }}
              sx={styles.menuItem}
            >
              {/* <Box
                sx={{
                  height: 'auto',
                  width: pxTovW(28),
                  marginRight: pxTovW(15),
                }}
              >
                <ImageWrapper
                  name={opt.icon}
                  parentFolder="icons"
                  type="png"
                  styles={{
                    height: 'auto',
                    maxWidth: pxTovW(22),
                    marginRight: pxTovW(15),
                  }}
                />
              </Box> */}

              <ImageWrapper
                name={opt.icon}
                parentFolder="icons"
                type="png"
                styles={{
                  height: 'auto',
                  maxWidth: pxTovW(22),
                  marginRight: pxTovW(15),
                }}
              />

              <Typography variant="h3" color="neutral.royalBlue">
                {opt.name}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box
        onClick={async (e) => {
          await interactionEvent({
            url: '',
            context: 'Navigation_bar',
            name: 'MENU',
            pathSegments: pathSegments,
            lessonSesssionId: lessonSessionId,
            resourceSesssionId: selected_resource_session_id?.toString(),
            homeworkSesssionId: active_homework_session_id?.toString(),
          });
          setSidebarDisplay(!sidebarDisplay);
        }}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <ImageWrapper
          name="menuTriple"
          type="png"
          parentFolder="icons"
          styles={styles.menuTriple}
        />
      </Box>

      <StudentSubjectPopup
        modalState={modalState}
        setModalState={setModalState}
        displayData={learnSubjects.map((sub) => ({
          subject: sub.subjectName,
          icon: sub.iconUrl,
          color: sub.textColor,
          onClick: async () => {
            await interactionEvent({
              url: 'Student_Home',
              context: 'Subject_selection',
              name: sub.subjectName.toUpperCase(),
              pathSegments: pathSegments,
            });

            await aiBookCloseEvent(selected_book_id);
            // console.log('aiBookCloseEvent  - big screen');
            dispatch(setHomeSelectedSubjectId(sub.subjectId));
            dispatch(setHomeSelectedBookId(sub.bookId));

            //
            await aiBookOpenEvent(sub.bookId);

            setModalState(false);
          },
        }))}
        title="Choose Subject"
      />
    </Box>
  );
}
