import { Box, Chip, SxProps, Typography, useMediaQuery } from '@mui/material';

import { ReactNode, useEffect } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import { IconWrapper } from '../../elements/IconWrapper/Index';
import ImageWrapper from '../../elements/ImageWrapper';
import { theme } from '../../../theme/themeProvider';

const styles: IStyles = {
  root: {
    // border: '1px solid green',
    bgcolor: 'common.white',
    boxSizing: 'border-box',
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    boxShadow: `0 0 ${pxTovW(39)} #E7E7E7D9`,
    '&:hover': { boxShadow: `0 0 ${pxTovW(20)} grey` },
    p: { xs: pxToRem(13), md: pxTovW(9) },
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    display: 'flex',
  },

  mainGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    // maxHeight: { md: pxTovW(139) },
    objectFit: 'cover',
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
  },
  videoIcon: {
    width: {
      xs: pxToRem(32),
      md: pxTovW(33),
    },
    height: {
      xs: pxToRem(32),
      md: pxTovW(33),
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textIconGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  headingChipBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(6), md: pxTovW(13) },
  },
  chip: {
    height: { xs: pxToRem(22), md: pxToRem(16), lg: pxTovW(22) },
    width: 'max-content',
    backgroundColor: 'warning.main',
    fontSize: { xs: pxToRem(10), md: pxToRem(9), lg: pxTovW(10) },
  },

  iconTextBoxContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  completedRibbon: {
    position: 'absolute',
    right: { xs: pxToRem(-20), md: pxTovW(-30) },
    top: { xs: pxToRem(20), md: pxTovW(30) },
    paddingLeft: { xs: pxToRem(20), md: pxTovW(30) },
    paddingRight: { xs: pxToRem(20), md: pxTovW(30) },
    backgroundColor: '#007CDC',
    transform: 'rotate(45deg)',
  },
  rightWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    paddingLeft: '10px',
  },
  ribbon: {
    position: 'absolute',
    top: { xs: pxToRem(2), md: pxTovW(0) },
    right: { xs: pxToRem(-5), md: pxTovW(-4) },
    px: '10px',
    pb: '2px',
    pt: {
      xs: pxToRem(12),
      md: pxToRem(7),
      lg: pxTovW(12),
    },
    width: { xs: pxToRem(80), md: pxToRem(50), lg: pxTovW(80) },
    height: { xs: pxToRem(20), md: pxToRem(13), lg: pxTovW(22) },
    textAlign: 'center',
    background: '#0AA34F',
    clipPath: 'polygon(90% 28%, 90% 0, 100% 28%, 100% 100%, 0 100%, 0 28%)',
    borderTopLeftRadius: '65px',
    borderBottomLeftRadius: '30px',
  },
};

const variantWidth = {
  xs: { small: pxToRem(283), medium: pxToRem(283), large: pxToRem(319) },
  md: { small: pxTovW(244), medium: pxTovW(385), large: pxTovW(457) },
  lg: { small: pxTovW(244), medium: pxTovW(330), large: pxTovW(457) },
};
const variantHeight = {
  xs: { small: pxToRem(102), medium: pxToRem(102), large: pxToRem(127) },
  md: { small: pxTovW(88), medium: pxTovW(130), large: pxTovW(165) },
  lg: { small: pxTovW(88), medium: pxTovW(120), large: pxTovW(165) },
};

interface IconDetails {
  iconName: string;
  text: string;
}
interface IVariants {
  small: string;
  medium: string;
  large: string;
}
interface IProps {
  variant: keyof IVariants;
  image?: string;
  tagName?: string;
  heading: string;
  iconDetails: IconDetails[];
  status?: ReactNode;
  rootStyle?: SxProps;
  showCompletedRibbon?: boolean;
  onClick?: () => void;
  showVideoIcon?: boolean;
  locked?: ReactNode;
  isLocked?: boolean;
}
export const ContentDetailCard = ({
  variant,
  image,
  tagName,
  heading,
  iconDetails,
  status,
  rootStyle,
  showCompletedRibbon,
  onClick,
  showVideoIcon,
  locked,
  isLocked,
}: IProps) => {
  let rootSx = { ...styles.root };
  if (rootStyle) rootSx = { ...rootSx, ...rootStyle };
  useEffect(() => {
    console.log(locked, 'locked');
  }, []);
  return (
    <Box
      onClick={onClick}
      sx={{
        width: {
          xs: variantWidth.xs[variant],
          md: variantWidth.md[variant],
          lg: variantWidth.lg[variant],
        },
        height: {
          xs: variantHeight.xs[variant],
          md: variantHeight.md[variant],
          lg: variantHeight.lg[variant],
        },
        opacity: isLocked ? 0.5 : 1,
        ...rootSx,
      }}
    >
      <Box
        sx={{
          height: '100%',
          // paddingBottom: '100%' /* 1:1 Aspect Ratio */,
          // position: 'relative',
          // width: 'max-content',
          flexGrow: 1,
          aspectRatio: 1 / 1,
          position: 'relative',
        }}
      >
        <ImageWrapper
          name="lessonImage1"
          type="png"
          parentFolder="tempAssets"
          styles={styles.image}
          path={image}
        />
        {showVideoIcon && (
          <ImageWrapper
            name="video-red"
            type="png"
            parentFolder="icons"
            styles={styles.videoIcon}
          />
        )}
      </Box>
      <Box sx={styles.rightWrapper}>
        <Box sx={styles.headingChipBox}>
          {tagName && (
            <Typography variant="cardText">
              <Chip label={tagName} sx={styles.chip} />
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: { xs: pxToRem(6), md: pxTovW(6) },
              height: '100%',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              variant="cardText"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
              }}
            >
              {heading}
            </Typography>
            {locked && <Box>{locked}</Box>}
          </Box>
        </Box>

        {status ? (
          status
        ) : (
          <Box sx={styles.iconTextBoxContainer}>
            {iconDetails.map((elem, index) => (
              <IconTextBox
                key={index}
                iconName={elem.iconName}
                text={elem.text}
                index={index}
              />
            ))}
          </Box>
        )}
      </Box>
      {showCompletedRibbon && (
        <Box>
          {/* <Typography variant="h4" color="common.white">
            Completed
          </Typography> */}
          <Box sx={styles.ribbon}>
            <Typography variant="h5" color="common.white">
              Completed
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface IBox {
  iconName: string;
  text: string;
  index: number;
}
const IconTextBox = ({ iconName, text, index }: IBox) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: pxToRem(4), md: pxTovW(4) },
        alignItems: 'center',
      }}
    >
      <IconWrapper
        name={iconName}
        size={isIpadOnly ? 'md' : 'small'}
        parentFolder="icons"
        type="png"
      />
      <Typography
        variant={isIpadOnly ? 'smallText' : 'subText'}
        fontWeight={'bold'}
        color={index % 2 === 0 ? 'primary' : 'secondary'}
      >
        {text}
      </Typography>
    </Box>
  );
};
