import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { ContentLockStatusType } from '@protos/school_management/school.db_pb';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import ImageWrapper from '../../elements/ImageWrapper';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
interface LockToggleButtonProps {
  isLocked?: boolean | ContentLockStatusType;
  // onClick: (event: React.MouseEvent<HTMLDivElement>, lessonId: string) => void;
  lessonId?: string;
  handleOpenPopup?: (ev?: React.MouseEvent) => void;
}
export const LockToggleButton: React.FC<LockToggleButtonProps> = ({
  isLocked,
  // onClick,
  lessonId,
  handleOpenPopup,
}) => {
  const getImageName = () => {
    if (typeof isLocked === 'boolean') {
      return isLocked ? 'lock' : 'unlock';
    }
    switch (isLocked) {
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED:
        return 'unlock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED:
        return 'lock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED:
        return 'partial_lock';
      default:
        return ''; // or a default image name
    }
  };

  const getTooltipTitle = () => {
    if (typeof isLocked === 'boolean') {
      return isLocked ? 'Lock' : 'Unlock';
    }
    switch (isLocked) {
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED:
        return 'Unlock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED:
        return 'Lock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED:
        return 'Partially Locked';
      default:
        return 'Status Unknown'; // or another default tooltip title
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        display: 'flex',
        border: 'none',
        borderRadius: {
          xs: pxToRem(7),
          md: pxToRem(5),
          lg: pxTovW(7),
        },
        height: {
          xs: pxToRem(30),
          md: pxToRem(20),
          lg: pxTovW(32),
        },
        width: {
          xs: pxToRem(28),
          md: pxToRem(20),
          lg: pxTovW(32),
        },
        boxSizing: 'border-box',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <BootstrapTooltip title={getTooltipTitle()}>
        <ImageWrapper
          onClick={handleOpenPopup}
          name={getImageName()}
          type="png"
          parentFolder="icons"
          styles={{
            height: {
              xs: pxToRem(30),
              md: pxToRem(20),
              lg: pxTovW(32),
            },
            width: {
              xs: pxToRem(28),
              md: pxToRem(20),
              lg: pxTovW(32),
            },
          }}
        />
      </BootstrapTooltip>
    </Box>
  );
};
