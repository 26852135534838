import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from '@mui/material';
import { useState } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
const styles: IStyles = {
  selectContainer: {
    width: { xs: '100%', md: pxTovW(230) },
    height: { xs: 'max-content', md: pxTovW(75) },
    background: '#DAF5FF',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#61BAFF',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#61BAFF',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#61BAFF',
    },
    '&:focus-visible .MuiOutlinedInput-notchedOutline': {
      borderColor: '#61BAFF',
    },
    borderRadius: { xs: pxToRem(5), md: pxTovW(15) },
    fontSize: { xs: pxToRem(14), md: pxTovW(21) },
    fontWeight: '500',
  },
};

interface IProps {
  placeholder?: string;
  value: string;
  optionList: IOptionList[];
  onChange: (value: string) => void;
  disabled?: boolean;
  rootStyles?: SxProps;
}

interface IOptionList {
  name: string | number;
  id: number;
  nonEditableItem?: boolean;
}

export const SelectMenu = (props: IProps) => {
  const { placeholder, value, optionList, onChange, disabled, rootStyles } =
    props;
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  let rootSx = {
    ...styles.selectContainer,
  };
  if (rootStyles) rootSx = { ...rootSx, ...rootStyles };

  return (
    <Box sx={styles.root}>
      <Select
        disabled={disabled ? true : false}
        displayEmpty
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        renderValue={(selected) => {
          if (value === '') {
            return <em>{placeholder}</em>;
          } else {
            return selected;
          }
        }}
        sx={{
          ...rootSx,
          boxShadow: isFocused
            ? `inset 0 0 1px 0 #61BAFF, inset 0 0 2px 1px rgba(97, 186, 255, 0.6), inset 0 0 3px 2px rgba(97, 186, 255, 0.4), inset 0 0 4px 3px rgba(97, 186, 255, 0.2), inset 0 0 5px 4px rgba(97, 186, 255, 0.1)`
            : 'none',
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: { xs: pxToRem(150), md: pxTovW(300) },
              minHeight: { xs: 'max-content', md: 'max-content' },
              padding: 0,
              borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
              '& .MuiList-root': {
                padding: 0,
              },
            },
          },
        }}
      >
        {optionList.map((e: any) => (
          <MenuItem
            key={e.id}
            disabled={e.nonEditableItem ? true : false}
            sx={{
              borderBottom: '1px solid #96FFC5',
              height: { xs: pxToRem(30), md: pxTovW(52) },
              '&:last-child': {
                borderBottom: 'none',
              },
              '&:hover': {
                background: '#ECFFF5',
              },
            }}
            value={e.name}
          >
            {e.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
