// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/school_management/school.db.proto (package geneo.school.db, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Class_ClassEnum } from "@protos/common/common.db_pb";
import { Module_ModuleCategoryEnum } from "@protos/content_management/content.db_pb";

/**
 * @generated from enum geneo.school.db.Feature
 */
export enum Feature {
  /**
   * @generated from enum value: FEATURE_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: FEATURE_CUSTOM_HW = 1;
   */
  CUSTOM_HW = 1,

  /**
   * @generated from enum value: FEATURE_CONNECTED_CLASSROOM = 2;
   */
  CONNECTED_CLASSROOM = 2,

  /**
   * @generated from enum value: FEATURE_AUTO_EVALUATION = 3;
   */
  AUTO_EVALUATION = 3,

  /**
   * @generated from enum value: FEATURE_CONTENT_LOCK = 4;
   */
  CONTENT_LOCK = 4,

  /**
   * @generated from enum value: FEATURE_CONTENT_UPLOAD = 5;
   */
  CONTENT_UPLOAD = 5,

  /**
   * @generated from enum value: FEATURE_VIEW_REPORTS = 6;
   */
  VIEW_REPORTS = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(Feature)
proto3.util.setEnumType(Feature, "geneo.school.db.Feature", [
  { no: 0, name: "FEATURE_UNDEFINED" },
  { no: 1, name: "FEATURE_CUSTOM_HW" },
  { no: 2, name: "FEATURE_CONNECTED_CLASSROOM" },
  { no: 3, name: "FEATURE_AUTO_EVALUATION" },
  { no: 4, name: "FEATURE_CONTENT_LOCK" },
  { no: 5, name: "FEATURE_CONTENT_UPLOAD" },
  { no: 6, name: "FEATURE_VIEW_REPORTS" },
]);

/**
 * @generated from enum geneo.school.db.ContentLockLevelEnum
 */
export enum ContentLockLevelEnum {
  /**
   * Represents an undefined action
   *
   * @generated from enum value: CONTENT_LOCK_LEVEL_UNDEFINED = 0;
   */
  CONTENT_LOCK_LEVEL_UNDEFINED = 0,

  /**
   * Represents a read action
   *
   * @generated from enum value: CONTENT_LOCK_LEVEL_SCHOOL = 1;
   */
  CONTENT_LOCK_LEVEL_SCHOOL = 1,

  /**
   * Represents a read action
   *
   * @generated from enum value: CONTENT_LOCK_LEVEL_SCHOOL_CLASS_SECTION = 2;
   */
  CONTENT_LOCK_LEVEL_SCHOOL_CLASS_SECTION = 2,

  /**
   * Represents a read action
   *
   * @generated from enum value: CONTENT_LOCK_LEVEL_SCHOOL_COURSE = 3;
   */
  CONTENT_LOCK_LEVEL_SCHOOL_COURSE = 3,

  /**
   * Represents a read action
   *
   * @generated from enum value: CONTENT_LOCK_LEVEL_CHAPTER = 4;
   */
  CONTENT_LOCK_LEVEL_CHAPTER = 4,

  /**
   * Represents a read action
   *
   * @generated from enum value: CONTENT_LOCK_LEVEL_TOPIC = 5;
   */
  CONTENT_LOCK_LEVEL_TOPIC = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentLockLevelEnum)
proto3.util.setEnumType(ContentLockLevelEnum, "geneo.school.db.ContentLockLevelEnum", [
  { no: 0, name: "CONTENT_LOCK_LEVEL_UNDEFINED" },
  { no: 1, name: "CONTENT_LOCK_LEVEL_SCHOOL" },
  { no: 2, name: "CONTENT_LOCK_LEVEL_SCHOOL_CLASS_SECTION" },
  { no: 3, name: "CONTENT_LOCK_LEVEL_SCHOOL_COURSE" },
  { no: 4, name: "CONTENT_LOCK_LEVEL_CHAPTER" },
  { no: 5, name: "CONTENT_LOCK_LEVEL_TOPIC" },
]);

/**
 * @generated from enum geneo.school.db.ContentLockStatusType
 */
export enum ContentLockStatusType {
  /**
   * Represents an undefined action
   *
   * @generated from enum value: CONTENT_LOCK_STATUS_UNDEFINED = 0;
   */
  CONTENT_LOCK_STATUS_UNDEFINED = 0,

  /**
   * Represents a read action
   *
   * @generated from enum value: CONTENT_LOCK_STATUS_IS_LOCKED = 1;
   */
  CONTENT_LOCK_STATUS_IS_LOCKED = 1,

  /**
   * Represents a read action
   *
   * @generated from enum value: CONTENT_LOCK_STATUS_IS_UNLOCKED = 2;
   */
  CONTENT_LOCK_STATUS_IS_UNLOCKED = 2,

  /**
   * Represents a read action
   *
   * @generated from enum value: CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED = 3;
   */
  CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ContentLockStatusType)
proto3.util.setEnumType(ContentLockStatusType, "geneo.school.db.ContentLockStatusType", [
  { no: 0, name: "CONTENT_LOCK_STATUS_UNDEFINED" },
  { no: 1, name: "CONTENT_LOCK_STATUS_IS_LOCKED" },
  { no: 2, name: "CONTENT_LOCK_STATUS_IS_UNLOCKED" },
  { no: 3, name: "CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED" },
]);

/**
 * Table structure for table region
 *
 * @generated from message geneo.school.db.Region
 */
export class Region extends Message<Region> {
  /**
   * Primary key for region
   *
   * @generated from field: optional int32 region_id = 1;
   */
  regionId?: number;

  /**
   * Name of the region
   *
   * @generated from field: string region_name = 2;
   */
  regionName = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 3;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 4;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 5;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 6;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<Region>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.Region";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "region_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "region_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_on", kind: "message", T: Timestamp },
    { no: 4, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 5, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Region {
    return new Region().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Region {
    return new Region().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Region {
    return new Region().fromJsonString(jsonString, options);
  }

  static equals(a: Region | PlainMessage<Region> | undefined, b: Region | PlainMessage<Region> | undefined): boolean {
    return proto3.util.equals(Region, a, b);
  }
}

/**
 * Table structure for table school_group
 *
 * @generated from message geneo.school.db.SchoolGroup
 */
export class SchoolGroup extends Message<SchoolGroup> {
  /**
   * Primary key for schoolGroup
   *
   * @generated from field: optional int32 school_group_id = 1;
   */
  schoolGroupId?: number;

  /**
   * Name of the schoolGroup
   *
   * @generated from field: string school_group_name = 2;
   */
  schoolGroupName = "";

  /**
   * Foreign key referencing region.region_id
   *
   * @generated from field: optional int32 region_id = 3;
   */
  regionId?: number;

  /**
   * Address of the schoolGroup
   *
   * @generated from field: string address = 4;
   */
  address = "";

  /**
   * Latitude and longitude of the schoolGroup address
   *
   * @generated from field: optional string address_lat_long = 5;
   */
  addressLatLong?: string;

  /**
   * City of the schoolGroup address
   *
   * @generated from field: string address_city = 6;
   */
  addressCity = "";

  /**
   * State of the schoolGroup address
   *
   * @generated from field: string address_state = 7;
   */
  addressState = "";

  /**
   * Country of the schoolGroup address
   *
   * @generated from field: string address_country = 8;
   */
  addressCountry = "";

  /**
   * Zip code of the schoolGroup address
   *
   * @generated from field: string address_zip_code = 9;
   */
  addressZipCode = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 10;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 11;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 12;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 13;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string school_group_logo_image_url = 14;
   */
  schoolGroupLogoImageUrl?: string;

  /**
   * @generated from field: optional bool is_sso_enabled = 15;
   */
  isSsoEnabled?: boolean;

  /**
   * @generated from field: optional string sso_group_id = 16;
   */
  ssoGroupId?: string;

  /**
   * @generated from field: optional string sso_redirection_url = 17;
   */
  ssoRedirectionUrl?: string;

  constructor(data?: PartialMessage<SchoolGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "school_group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "region_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "created_on", kind: "message", T: Timestamp },
    { no: 11, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 13, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 14, name: "school_group_logo_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "is_sso_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 16, name: "sso_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "sso_redirection_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolGroup {
    return new SchoolGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolGroup {
    return new SchoolGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolGroup {
    return new SchoolGroup().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolGroup | PlainMessage<SchoolGroup> | undefined, b: SchoolGroup | PlainMessage<SchoolGroup> | undefined): boolean {
    return proto3.util.equals(SchoolGroup, a, b);
  }
}

/**
 * Table structure for table school
 *
 * @generated from message geneo.school.db.School
 */
export class School extends Message<School> {
  /**
   * Primary key for school
   *
   * @generated from field: optional int32 school_id = 1;
   */
  schoolId?: number;

  /**
   * Foreign key referencing schoolGroup.school_group_id
   *
   * @generated from field: optional int32 school_group_id = 2;
   */
  schoolGroupId?: number;

  /**
   * Name of the school
   *
   * @generated from field: string school_name = 3;
   */
  schoolName = "";

  /**
   * Foreign key referencing region.region_id
   *
   * @generated from field: optional int32 region_id = 4;
   */
  regionId?: number;

  /**
   * Address of the school
   *
   * @generated from field: string address = 5;
   */
  address = "";

  /**
   * Latitude and longitude of the school address
   *
   * @generated from field: optional string address_lat_long = 6;
   */
  addressLatLong?: string;

  /**
   * City of the school address
   *
   * @generated from field: string address_city = 7;
   */
  addressCity = "";

  /**
   * State of the school address
   *
   * @generated from field: string address_state = 8;
   */
  addressState = "";

  /**
   * Country of the school address
   *
   * @generated from field: string address_country = 9;
   */
  addressCountry = "";

  /**
   * Zip code of the school address
   *
   * @generated from field: string address_zip_code = 10;
   */
  addressZipCode = "";

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 11;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 12;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 13;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 14;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional string branch_name = 15;
   */
  branchName?: string;

  /**
   * @generated from field: optional string school_logo_image_url = 16;
   */
  schoolLogoImageUrl?: string;

  /**
   * @generated from field: geneo.school.db.SPOCInfo school_spoc_info = 17;
   */
  schoolSpocInfo?: SPOCInfo;

  /**
   * @generated from field: geneo.school.db.SPOCInfo geneo_spoc_info = 18;
   */
  geneoSpocInfo?: SPOCInfo;

  /**
   * @generated from field: optional string udise_id = 19;
   */
  udiseId?: string;

  /**
   * @generated from field: optional bool is_active = 20;
   */
  isActive?: boolean;

  /**
   * @generated from field: geneo.school.db.SchoolKyanInfo school_kyan_info = 21;
   */
  schoolKyanInfo?: SchoolKyanInfo;

  /**
   * @generated from field: optional bool isInternal = 22;
   */
  isInternal?: boolean;

  /**
   * @generated from field: optional bool is_sso_enabled = 23;
   */
  isSsoEnabled?: boolean;

  /**
   * @generated from field: optional geneo.school.db.FeaturePlanInfo features_plan_info = 24;
   */
  featuresPlanInfo?: FeaturePlanInfo;

  /**
   * @generated from field: optional string school_code = 25;
   */
  schoolCode?: string;

  constructor(data?: PartialMessage<School>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.School";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "school_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "region_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "created_on", kind: "message", T: Timestamp },
    { no: 12, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 15, name: "branch_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "school_logo_image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "school_spoc_info", kind: "message", T: SPOCInfo },
    { no: 18, name: "geneo_spoc_info", kind: "message", T: SPOCInfo },
    { no: 19, name: "udise_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 21, name: "school_kyan_info", kind: "message", T: SchoolKyanInfo },
    { no: 22, name: "isInternal", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 23, name: "is_sso_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 24, name: "features_plan_info", kind: "message", T: FeaturePlanInfo, opt: true },
    { no: 25, name: "school_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): School {
    return new School().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): School {
    return new School().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): School {
    return new School().fromJsonString(jsonString, options);
  }

  static equals(a: School | PlainMessage<School> | undefined, b: School | PlainMessage<School> | undefined): boolean {
    return proto3.util.equals(School, a, b);
  }
}

/**
 * @generated from message geneo.school.db.FeaturePlanInfo
 */
export class FeaturePlanInfo extends Message<FeaturePlanInfo> {
  /**
   * @generated from field: repeated geneo.school.db.FeatureInfo feature_info = 1;
   */
  featureInfo: FeatureInfo[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp subscription_start_date = 2;
   */
  subscriptionStartDate?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp subscription_end_date = 3;
   */
  subscriptionEndDate?: Timestamp;

  constructor(data?: PartialMessage<FeaturePlanInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.FeaturePlanInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "feature_info", kind: "message", T: FeatureInfo, repeated: true },
    { no: 2, name: "subscription_start_date", kind: "message", T: Timestamp, opt: true },
    { no: 3, name: "subscription_end_date", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeaturePlanInfo {
    return new FeaturePlanInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeaturePlanInfo {
    return new FeaturePlanInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeaturePlanInfo {
    return new FeaturePlanInfo().fromJsonString(jsonString, options);
  }

  static equals(a: FeaturePlanInfo | PlainMessage<FeaturePlanInfo> | undefined, b: FeaturePlanInfo | PlainMessage<FeaturePlanInfo> | undefined): boolean {
    return proto3.util.equals(FeaturePlanInfo, a, b);
  }
}

/**
 * @generated from message geneo.school.db.FeatureInfo
 */
export class FeatureInfo extends Message<FeatureInfo> {
  /**
   * @generated from field: geneo.school.db.Feature feature = 1;
   */
  feature = Feature.UNDEFINED;

  /**
   * @generated from field: bool is_enabled = 2;
   */
  isEnabled = false;

  constructor(data?: PartialMessage<FeatureInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.FeatureInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "feature", kind: "enum", T: proto3.getEnumType(Feature) },
    { no: 2, name: "is_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeatureInfo {
    return new FeatureInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeatureInfo {
    return new FeatureInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeatureInfo {
    return new FeatureInfo().fromJsonString(jsonString, options);
  }

  static equals(a: FeatureInfo | PlainMessage<FeatureInfo> | undefined, b: FeatureInfo | PlainMessage<FeatureInfo> | undefined): boolean {
    return proto3.util.equals(FeatureInfo, a, b);
  }
}

/**
 * @generated from message geneo.school.db.SPOCInfo
 */
export class SPOCInfo extends Message<SPOCInfo> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string phone = 3;
   */
  phone = "";

  constructor(data?: PartialMessage<SPOCInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SPOCInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SPOCInfo {
    return new SPOCInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SPOCInfo {
    return new SPOCInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SPOCInfo {
    return new SPOCInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SPOCInfo | PlainMessage<SPOCInfo> | undefined, b: SPOCInfo | PlainMessage<SPOCInfo> | undefined): boolean {
    return proto3.util.equals(SPOCInfo, a, b);
  }
}

/**
 * @generated from message geneo.school.db.SchoolKyanInfo
 */
export class SchoolKyanInfo extends Message<SchoolKyanInfo> {
  /**
   * @generated from field: string school_id = 1;
   */
  schoolId = "";

  /**
   * @generated from field: string school_name = 2;
   */
  schoolName = "";

  /**
   * @generated from field: repeated string kYan_serial_nos = 3;
   */
  kYanSerialNos: string[] = [];

  constructor(data?: PartialMessage<SchoolKyanInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolKyanInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "kYan_serial_nos", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolKyanInfo {
    return new SchoolKyanInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolKyanInfo {
    return new SchoolKyanInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolKyanInfo {
    return new SchoolKyanInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolKyanInfo | PlainMessage<SchoolKyanInfo> | undefined, b: SchoolKyanInfo | PlainMessage<SchoolKyanInfo> | undefined): boolean {
    return proto3.util.equals(SchoolKyanInfo, a, b);
  }
}

/**
 * Table structure for table school_subscription
 *
 * @generated from message geneo.school.db.SchoolSubscription
 */
export class SchoolSubscription extends Message<SchoolSubscription> {
  /**
   * Primary key for schoolSubscription
   *
   * @generated from field: optional int32 school_subscription_id = 1;
   */
  schoolSubscriptionId?: number;

  /**
   * Foreign key referencing schoolGroup.school_group_id
   *
   * @generated from field: optional int32 school_group_id = 2;
   */
  schoolGroupId?: number;

  /**
   * Foreign key referencing school.school_id
   *
   * @generated from field: int32 school_id = 3;
   */
  schoolId = 0;

  /**
   * Number of licenses for the subscription
   *
   * @generated from field: int32 num_licenses = 4;
   */
  numLicenses = 0;

  /**
   * ID of the payment transaction
   *
   * @generated from field: int64 payment_transaction_id = 5;
   */
  paymentTransactionId = protoInt64.zero;

  /**
   * Start date of the subscription
   *
   * @generated from field: google.protobuf.Timestamp subscription_start_date = 6;
   */
  subscriptionStartDate?: Timestamp;

  /**
   * End date of the subscription
   *
   * @generated from field: google.protobuf.Timestamp subscription_end_date = 7;
   */
  subscriptionEndDate?: Timestamp;

  /**
   * Flag indicating if the subscription is active
   *
   * @generated from field: bool active_subscription = 8;
   */
  activeSubscription = false;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 9;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 10;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 11;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 12;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<SchoolSubscription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolSubscription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_subscription_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "school_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "num_licenses", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "payment_transaction_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "subscription_start_date", kind: "message", T: Timestamp },
    { no: 7, name: "subscription_end_date", kind: "message", T: Timestamp },
    { no: 8, name: "active_subscription", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "created_on", kind: "message", T: Timestamp },
    { no: 10, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolSubscription {
    return new SchoolSubscription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolSubscription {
    return new SchoolSubscription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolSubscription {
    return new SchoolSubscription().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolSubscription | PlainMessage<SchoolSubscription> | undefined, b: SchoolSubscription | PlainMessage<SchoolSubscription> | undefined): boolean {
    return proto3.util.equals(SchoolSubscription, a, b);
  }
}

/**
 * Table structure for table school_class_section
 *
 * @generated from message geneo.school.db.SchoolClassSection
 */
export class SchoolClassSection extends Message<SchoolClassSection> {
  /**
   * Primary key for schoolClassSection
   *
   * @generated from field: optional int32 school_class_section_id = 1;
   */
  schoolClassSectionId?: number;

  /**
   * Name of the section
   *
   * @generated from field: string section_name = 2;
   */
  sectionName = "";

  /**
   * ID of the class associated with the school class. (Foreign Key: common.class)
   *
   * @generated from field: int32 class_id = 3;
   */
  classId = 0;

  /**
   * Foreign key referencing common.academicYear.academic_year_id;
   *
   * @generated from field: int32 academic_year_id = 4;
   */
  academicYearId = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: int32 school_id = 9;
   */
  schoolId = 0;

  /**
   * @generated from field: optional bool isActive = 10;
   */
  isActive?: boolean;

  constructor(data?: PartialMessage<SchoolClassSection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolClassSection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "academic_year_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolClassSection {
    return new SchoolClassSection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolClassSection {
    return new SchoolClassSection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolClassSection {
    return new SchoolClassSection().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolClassSection | PlainMessage<SchoolClassSection> | undefined, b: SchoolClassSection | PlainMessage<SchoolClassSection> | undefined): boolean {
    return proto3.util.equals(SchoolClassSection, a, b);
  }
}

/**
 * Table structure for table course
 *
 * @generated from message geneo.school.db.Course
 */
export class Course extends Message<Course> {
  /**
   * Primary key for course
   *
   * @generated from field: optional int32 course_id = 1;
   */
  courseId?: number;

  /**
   * Foreign key referencing common.board.board_id
   *
   * @generated from field: int32 board_id = 2;
   */
  boardId = 0;

  /**
   * Foreign key referencing common.class.class_id
   *
   * @generated from field: int32 class_id = 3;
   */
  classId = 0;

  /**
   * Foreign key referencing common.subject.subject_id
   *
   * @generated from field: int32 subject_id = 4;
   */
  subjectId = 0;

  /**
   * Foreign key referencing common.teachMedium.teach_medium_id
   *
   * @generated from field: int32 teach_medium_id = 5;
   */
  teachMediumId = 0;

  /**
   * int32 academic_year_id = 6; // Foreign key referencing common.academicYear.academic_year_id
   *
   * Foreign key referencing common.book.book_id
   *
   * @generated from field: int32 book_id = 6;
   */
  bookId = 0;

  /**
   * Code of the course
   *
   * @generated from field: int32 code = 7;
   */
  code = 0;

  /**
   * Name of the course
   *
   * @generated from field: string name = 8;
   */
  name = "";

  /**
   * Short description of the course
   *
   * @generated from field: string short_description = 9;
   */
  shortDescription = "";

  /**
   * Long description of the course
   *
   * @generated from field: optional string long_description = 10;
   */
  longDescription?: string;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 11;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 12;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 13;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 14;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<Course>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.Course";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "board_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "teach_medium_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "short_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "long_description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "created_on", kind: "message", T: Timestamp },
    { no: 12, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Course {
    return new Course().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Course {
    return new Course().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Course {
    return new Course().fromJsonString(jsonString, options);
  }

  static equals(a: Course | PlainMessage<Course> | undefined, b: Course | PlainMessage<Course> | undefined): boolean {
    return proto3.util.equals(Course, a, b);
  }
}

/**
 * Table structure for table school_course
 *
 * @generated from message geneo.school.db.SchoolCourse
 */
export class SchoolCourse extends Message<SchoolCourse> {
  /**
   * Primary key for schoolCourse
   *
   * @generated from field: optional int32 school_course_id = 1;
   */
  schoolCourseId?: number;

  /**
   * Foreign key referencing school.school_id
   *
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * Foreign key referencing course.course_id
   *
   * @generated from field: int32 course_id = 3;
   */
  courseId = 0;

  /**
   * Foreign key referencing common.academicYear.academic_year_id
   *
   * @generated from field: int32 academic_year_id = 4;
   */
  academicYearId = 0;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional bool isActive = 9;
   */
  isActive?: boolean;

  constructor(data?: PartialMessage<SchoolCourse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolCourse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "academic_year_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolCourse {
    return new SchoolCourse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolCourse {
    return new SchoolCourse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolCourse {
    return new SchoolCourse().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolCourse | PlainMessage<SchoolCourse> | undefined, b: SchoolCourse | PlainMessage<SchoolCourse> | undefined): boolean {
    return proto3.util.equals(SchoolCourse, a, b);
  }
}

/**
 * Table structure for table school_teacher_class_course
 *
 * @generated from message geneo.school.db.SchoolTeacherCourse
 */
export class SchoolTeacherCourse extends Message<SchoolTeacherCourse> {
  /**
   * Primary key for schoolTeacherClassCourse
   *
   * @generated from field: optional int32 school_teacher_course_id = 1;
   */
  schoolTeacherCourseId?: number;

  /**
   * Foreign key referencing schoolCourse.school_course_id
   *
   * @generated from field: int32 school_course_id = 2;
   */
  schoolCourseId = 0;

  /**
   * Foreign key referencing schoolClassSection.school_class_section_id
   *
   * @generated from field: int32 school_class_section_id = 3;
   */
  schoolClassSectionId = 0;

  /**
   * User ID of the teacher
   *
   * @generated from field: int64 teacher_id = 4;
   */
  teacherId = protoInt64.zero;

  /**
   * int32 academic_year_id = 5; // Foreign key referencing common.academicYear.academic_year_id
   *
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional bool isActive = 9;
   */
  isActive?: boolean;

  constructor(data?: PartialMessage<SchoolTeacherCourse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolTeacherCourse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_teacher_course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "school_course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolTeacherCourse {
    return new SchoolTeacherCourse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolTeacherCourse {
    return new SchoolTeacherCourse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolTeacherCourse {
    return new SchoolTeacherCourse().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolTeacherCourse | PlainMessage<SchoolTeacherCourse> | undefined, b: SchoolTeacherCourse | PlainMessage<SchoolTeacherCourse> | undefined): boolean {
    return proto3.util.equals(SchoolTeacherCourse, a, b);
  }
}

/**
 * Table structure for table school_student_course
 *
 * @generated from message geneo.school.db.SchoolStudentCourse
 */
export class SchoolStudentCourse extends Message<SchoolStudentCourse> {
  /**
   * Primary key for schoolStudentCourse
   *
   * @generated from field: optional int32 school_student_course_id = 1;
   */
  schoolStudentCourseId?: number;

  /**
   * User ID of the student
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * Foreign key referencing schoolCourse.school_course_id
   *
   * @generated from field: int32 school_course_id = 3;
   */
  schoolCourseId = 0;

  /**
   * Foreign key referencing schoolClassSectionStudent.school_class_section_student_id
   *
   * @generated from field: optional int32 school_class_section_id = 4;
   */
  schoolClassSectionId?: number;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 5;
   */
  createdBy?: bigint;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 6;
   */
  createdOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 7;
   */
  modifiedBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional bool isActive = 9;
   */
  isActive?: boolean;

  constructor(data?: PartialMessage<SchoolStudentCourse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolStudentCourse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_student_course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "school_course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "created_on", kind: "message", T: Timestamp },
    { no: 7, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolStudentCourse {
    return new SchoolStudentCourse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolStudentCourse {
    return new SchoolStudentCourse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolStudentCourse {
    return new SchoolStudentCourse().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolStudentCourse | PlainMessage<SchoolStudentCourse> | undefined, b: SchoolStudentCourse | PlainMessage<SchoolStudentCourse> | undefined): boolean {
    return proto3.util.equals(SchoolStudentCourse, a, b);
  }
}

/**
 * ############################################################################################################
 * #####################################  SSO Module  #########################################
 * ############################################################################################################
 * SSO support
 *
 * @generated from message geneo.school.db.SsoProvider
 */
export class SsoProvider extends Message<SsoProvider> {
  /**
   * @generated from field: int32 sso_provider_id = 1;
   */
  ssoProviderId = 0;

  /**
   * @generated from field: string provider_name = 2;
   */
  providerName = "";

  /**
   * @generated from field: string provider_short_code = 3;
   */
  providerShortCode = "";

  /**
   * @generated from field: string provider_website = 4;
   */
  providerWebsite = "";

  /**
   * @generated from field: optional string description = 5;
   */
  description?: string;

  /**
   * @generated from field: bool is_active = 6;
   */
  isActive = false;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 7;
   */
  createdBy?: bigint;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 8;
   */
  createdOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 9;
   */
  modifiedBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 10;
   */
  modifiedOn?: Timestamp;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 11;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<SsoProvider>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SsoProvider";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_provider_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "provider_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "provider_short_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "provider_website", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "created_on", kind: "message", T: Timestamp },
    { no: 9, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 11, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoProvider {
    return new SsoProvider().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoProvider {
    return new SsoProvider().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoProvider {
    return new SsoProvider().fromJsonString(jsonString, options);
  }

  static equals(a: SsoProvider | PlainMessage<SsoProvider> | undefined, b: SsoProvider | PlainMessage<SsoProvider> | undefined): boolean {
    return proto3.util.equals(SsoProvider, a, b);
  }
}

/**
 * @generated from message geneo.school.db.SchoolSsoConfig
 */
export class SchoolSsoConfig extends Message<SchoolSsoConfig> {
  /**
   * @generated from field: int32 school_sso_config_id = 1;
   */
  schoolSsoConfigId = 0;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 sso_provider_id = 3;
   */
  ssoProviderId = 0;

  /**
   * @generated from field: string sso_school_id = 4;
   */
  ssoSchoolId = "";

  /**
   * @generated from field: bool is_teacher_enabled = 5;
   */
  isTeacherEnabled = false;

  /**
   * @generated from field: bool is_student_enabled = 6;
   */
  isStudentEnabled = false;

  /**
   * @generated from field: bool is_active = 7;
   */
  isActive = false;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 8;
   */
  createdBy?: bigint;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 9;
   */
  createdOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 10;
   */
  modifiedBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 11;
   */
  modifiedOn?: Timestamp;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 12;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<SchoolSsoConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolSsoConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_sso_config_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sso_provider_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "sso_school_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "is_teacher_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "is_student_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "created_on", kind: "message", T: Timestamp },
    { no: 10, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolSsoConfig {
    return new SchoolSsoConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolSsoConfig {
    return new SchoolSsoConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolSsoConfig {
    return new SchoolSsoConfig().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolSsoConfig | PlainMessage<SchoolSsoConfig> | undefined, b: SchoolSsoConfig | PlainMessage<SchoolSsoConfig> | undefined): boolean {
    return proto3.util.equals(SchoolSsoConfig, a, b);
  }
}

/**
 * @generated from message geneo.school.db.SsoSchoolClassSectionConfig
 */
export class SsoSchoolClassSectionConfig extends Message<SsoSchoolClassSectionConfig> {
  /**
   * @generated from field: int32 sso_school_class_section_config_id = 1;
   */
  ssoSchoolClassSectionConfigId = 0;

  /**
   * @generated from field: int32 school_sso_config_id = 2;
   */
  schoolSsoConfigId = 0;

  /**
   * @generated from field: int32 class_id = 3;
   */
  classId = 0;

  /**
   * @generated from field: string sso_class_id = 4;
   */
  ssoClassId = "";

  /**
   * @generated from field: int32 section_id = 5;
   */
  sectionId = 0;

  /**
   * @generated from field: string sso_section_id = 6;
   */
  ssoSectionId = "";

  /**
   * @generated from field: bool is_active = 7;
   */
  isActive = false;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 8;
   */
  createdBy?: bigint;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 9;
   */
  createdOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 10;
   */
  modifiedBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp modified_on = 11;
   */
  modifiedOn?: Timestamp;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 12;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<SsoSchoolClassSectionConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SsoSchoolClassSectionConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sso_school_class_section_config_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_sso_config_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "sso_class_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "sso_section_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "created_on", kind: "message", T: Timestamp },
    { no: 10, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SsoSchoolClassSectionConfig {
    return new SsoSchoolClassSectionConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SsoSchoolClassSectionConfig {
    return new SsoSchoolClassSectionConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SsoSchoolClassSectionConfig {
    return new SsoSchoolClassSectionConfig().fromJsonString(jsonString, options);
  }

  static equals(a: SsoSchoolClassSectionConfig | PlainMessage<SsoSchoolClassSectionConfig> | undefined, b: SsoSchoolClassSectionConfig | PlainMessage<SsoSchoolClassSectionConfig> | undefined): boolean {
    return proto3.util.equals(SsoSchoolClassSectionConfig, a, b);
  }
}

/**
 * Message for base receiver table (Base receiver)
 *
 * @generated from message geneo.school.db.BaseReceiver
 */
export class BaseReceiver extends Message<BaseReceiver> {
  /**
   * Primary key for base_receiver
   *
   * @generated from field: int32 base_receiver_id = 1;
   */
  baseReceiverId = 0;

  /**
   * Base receiver type
   *
   * @generated from field: string type = 2;
   */
  type = "";

  /**
   * Base receiver model
   *
   * @generated from field: string model = 3;
   */
  model = "";

  /**
   * Base receiver version
   *
   * @generated from field: string version = 4;
   */
  version = "";

  /**
   * Base receiver serial number
   *
   * @generated from field: string serial_no = 5;
   */
  serialNo = "";

  /**
   * Base receiver description
   *
   * @generated from field: optional string description = 6;
   */
  description?: string;

  /**
   * Base receiver is active
   *
   * @generated from field: bool is_active = 7;
   */
  isActive = false;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 8;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 9;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 10;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 11;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 12;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<BaseReceiver>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.BaseReceiver";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_receiver_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "serial_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "created_on", kind: "message", T: Timestamp },
    { no: 9, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "modified_on", kind: "message", T: Timestamp },
    { no: 11, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BaseReceiver {
    return new BaseReceiver().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BaseReceiver {
    return new BaseReceiver().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BaseReceiver {
    return new BaseReceiver().fromJsonString(jsonString, options);
  }

  static equals(a: BaseReceiver | PlainMessage<BaseReceiver> | undefined, b: BaseReceiver | PlainMessage<BaseReceiver> | undefined): boolean {
    return proto3.util.equals(BaseReceiver, a, b);
  }
}

/**
 * Message for clicker table (Clicker)
 *
 * @generated from message geneo.school.db.Clicker
 */
export class Clicker extends Message<Clicker> {
  /**
   * Primary key for clicker
   *
   * @generated from field: int64 clicker_id = 1;
   */
  clickerId = protoInt64.zero;

  /**
   * Clicker type
   *
   * @generated from field: string type = 2;
   */
  type = "";

  /**
   * Clicker model
   *
   * @generated from field: string model = 3;
   */
  model = "";

  /**
   * Clicker version
   *
   * @generated from field: string version = 4;
   */
  version = "";

  /**
   * Clicker serial number
   *
   * @generated from field: string serial_no = 5;
   */
  serialNo = "";

  /**
   * Clicker description
   *
   * @generated from field: optional string description = 6;
   */
  description?: string;

  /**
   * Clicker is active
   *
   * @generated from field: bool is_active = 7;
   */
  isActive = false;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 8;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 9;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 10;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 11;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 12;
   */
  deletedAt?: Timestamp;

  /**
   * referencing base receiver id
   *
   * @generated from field: optional int32 base_receiver_id = 13;
   */
  baseReceiverId?: number;

  constructor(data?: PartialMessage<Clicker>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.Clicker";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "clicker_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "serial_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "created_on", kind: "message", T: Timestamp },
    { no: 9, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "modified_on", kind: "message", T: Timestamp },
    { no: 11, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 13, name: "base_receiver_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Clicker {
    return new Clicker().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Clicker {
    return new Clicker().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Clicker {
    return new Clicker().fromJsonString(jsonString, options);
  }

  static equals(a: Clicker | PlainMessage<Clicker> | undefined, b: Clicker | PlainMessage<Clicker> | undefined): boolean {
    return proto3.util.equals(Clicker, a, b);
  }
}

/**
 * Message for class_receiver table (Class - Base device receiver mapping)
 *
 * @generated from message geneo.school.db.ClassReceiverConfig
 */
export class ClassReceiverConfig extends Message<ClassReceiverConfig> {
  /**
   * Primary key for class_receiver
   *
   * @generated from field: int32 class_receiver_config_id = 1;
   */
  classReceiverConfigId = 0;

  /**
   * referencing school id
   *
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * referencing class
   *
   * @generated from field: geneo.common.db.Class.ClassEnum class = 3;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * referencing section
   *
   * @generated from field: int32 sectionId = 4;
   */
  sectionId = 0;

  /**
   * referencing base receiver id
   *
   * @generated from field: int32 base_receiver_id = 5;
   */
  baseReceiverId = 0;

  /**
   * referencing teacher profile id
   *
   * @generated from field: optional int64 teacher_id = 6;
   */
  teacherId?: bigint;

  /**
   * Class receiver is active
   *
   * @generated from field: bool is_active = 7;
   */
  isActive = false;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 8;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 9;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 10;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 11;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 12;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<ClassReceiverConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.ClassReceiverConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_receiver_config_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 4, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "base_receiver_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "created_on", kind: "message", T: Timestamp },
    { no: 9, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "modified_on", kind: "message", T: Timestamp },
    { no: 11, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassReceiverConfig {
    return new ClassReceiverConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassReceiverConfig {
    return new ClassReceiverConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassReceiverConfig {
    return new ClassReceiverConfig().fromJsonString(jsonString, options);
  }

  static equals(a: ClassReceiverConfig | PlainMessage<ClassReceiverConfig> | undefined, b: ClassReceiverConfig | PlainMessage<ClassReceiverConfig> | undefined): boolean {
    return proto3.util.equals(ClassReceiverConfig, a, b);
  }
}

/**
 * Message for student_clicker table (Student - clicker mapping)
 *
 * @generated from message geneo.school.db.StudentClickerAssignment
 */
export class StudentClickerAssignment extends Message<StudentClickerAssignment> {
  /**
   * Primary key for student_clicker
   *
   * @generated from field: int64 student_clicker_assignment_id = 1;
   */
  studentClickerAssignmentId = protoInt64.zero;

  /**
   * referencing student profile id
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * clicker id
   *
   * @generated from field: int64 clicker_id = 3;
   */
  clickerId = protoInt64.zero;

  /**
   * is student clicker active
   *
   * @generated from field: bool is_active = 4;
   */
  isActive = false;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 5;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 6;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 7;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 8;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 9;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<StudentClickerAssignment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.StudentClickerAssignment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_clicker_assignment_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "clicker_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "created_on", kind: "message", T: Timestamp },
    { no: 6, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "modified_on", kind: "message", T: Timestamp },
    { no: 8, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentClickerAssignment {
    return new StudentClickerAssignment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentClickerAssignment {
    return new StudentClickerAssignment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentClickerAssignment {
    return new StudentClickerAssignment().fromJsonString(jsonString, options);
  }

  static equals(a: StudentClickerAssignment | PlainMessage<StudentClickerAssignment> | undefined, b: StudentClickerAssignment | PlainMessage<StudentClickerAssignment> | undefined): boolean {
    return proto3.util.equals(StudentClickerAssignment, a, b);
  }
}

/**
 * @generated from message geneo.school.db.SchoolConnectedClassConfig
 */
export class SchoolConnectedClassConfig extends Message<SchoolConnectedClassConfig> {
  /**
   * @generated from field: int32 school_connected_class_config_id = 1;
   */
  schoolConnectedClassConfigId = 0;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: geneo.school.db.ClickerTextConfig clicker_text_config = 3;
   */
  clickerTextConfig?: ClickerTextConfig;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 4;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 5;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 6;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 7;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 8;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<SchoolConnectedClassConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolConnectedClassConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_connected_class_config_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "clicker_text_config", kind: "message", T: ClickerTextConfig },
    { no: 4, name: "created_on", kind: "message", T: Timestamp },
    { no: 5, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "modified_on", kind: "message", T: Timestamp },
    { no: 7, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolConnectedClassConfig {
    return new SchoolConnectedClassConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolConnectedClassConfig {
    return new SchoolConnectedClassConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolConnectedClassConfig {
    return new SchoolConnectedClassConfig().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolConnectedClassConfig | PlainMessage<SchoolConnectedClassConfig> | undefined, b: SchoolConnectedClassConfig | PlainMessage<SchoolConnectedClassConfig> | undefined): boolean {
    return proto3.util.equals(SchoolConnectedClassConfig, a, b);
  }
}

/**
 * @generated from message geneo.school.db.ClickerTextConfig
 */
export class ClickerTextConfig extends Message<ClickerTextConfig> {
  /**
   * Custom Text fields
   *
   * @generated from field: repeated string data_fields = 1;
   */
  dataFields: string[] = [];

  /**
   * @generated from field: string data_separator = 2;
   */
  dataSeparator = "";

  constructor(data?: PartialMessage<ClickerTextConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.ClickerTextConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "data_fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "data_separator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClickerTextConfig {
    return new ClickerTextConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClickerTextConfig {
    return new ClickerTextConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClickerTextConfig {
    return new ClickerTextConfig().fromJsonString(jsonString, options);
  }

  static equals(a: ClickerTextConfig | PlainMessage<ClickerTextConfig> | undefined, b: ClickerTextConfig | PlainMessage<ClickerTextConfig> | undefined): boolean {
    return proto3.util.equals(ClickerTextConfig, a, b);
  }
}

/**
 * @generated from message geneo.school.db.SchoolContentModuleNodeLock
 */
export class SchoolContentModuleNodeLock extends Message<SchoolContentModuleNodeLock> {
  /**
   * @generated from field: int32 module_id = 1;
   */
  moduleId = 0;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 2;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: repeated geneo.school.db.LessonNodeLock lessons_lock_info = 4;
   */
  lessonsLockInfo: LessonNodeLock[] = [];

  /**
   * @generated from field: repeated string locked_resource_ids = 5;
   */
  lockedResourceIds: string[] = [];

  /**
   * @generated from field: repeated int32 locked_homework_ids = 6;
   */
  lockedHomeworkIds: number[] = [];

  /**
   * @generated from field: repeated geneo.school.db.SchoolContentModuleNodeLock children = 7;
   */
  children: SchoolContentModuleNodeLock[] = [];

  /**
   * @generated from field: geneo.school.db.ContentLockStatusType lock_status = 8;
   */
  lockStatus = ContentLockStatusType.CONTENT_LOCK_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<SchoolContentModuleNodeLock>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolContentModuleNodeLock";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lessons_lock_info", kind: "message", T: LessonNodeLock, repeated: true },
    { no: 5, name: "locked_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "locked_homework_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 7, name: "children", kind: "message", T: SchoolContentModuleNodeLock, repeated: true },
    { no: 8, name: "lock_status", kind: "enum", T: proto3.getEnumType(ContentLockStatusType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolContentModuleNodeLock {
    return new SchoolContentModuleNodeLock().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolContentModuleNodeLock {
    return new SchoolContentModuleNodeLock().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolContentModuleNodeLock {
    return new SchoolContentModuleNodeLock().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolContentModuleNodeLock | PlainMessage<SchoolContentModuleNodeLock> | undefined, b: SchoolContentModuleNodeLock | PlainMessage<SchoolContentModuleNodeLock> | undefined): boolean {
    return proto3.util.equals(SchoolContentModuleNodeLock, a, b);
  }
}

/**
 * @generated from message geneo.school.db.LessonNodeLock
 */
export class LessonNodeLock extends Message<LessonNodeLock> {
  /**
   * @generated from field: string lesson_id = 1;
   */
  lessonId = "";

  /**
   * @generated from field: repeated string locked_resource_ids = 2;
   */
  lockedResourceIds: string[] = [];

  /**
   * @generated from field: geneo.school.db.ContentLockStatusType lock_status = 3;
   */
  lockStatus = ContentLockStatusType.CONTENT_LOCK_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<LessonNodeLock>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.LessonNodeLock";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "locked_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "lock_status", kind: "enum", T: proto3.getEnumType(ContentLockStatusType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonNodeLock {
    return new LessonNodeLock().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonNodeLock {
    return new LessonNodeLock().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonNodeLock {
    return new LessonNodeLock().fromJsonString(jsonString, options);
  }

  static equals(a: LessonNodeLock | PlainMessage<LessonNodeLock> | undefined, b: LessonNodeLock | PlainMessage<LessonNodeLock> | undefined): boolean {
    return proto3.util.equals(LessonNodeLock, a, b);
  }
}

/**
 * Tables
 *
 * @generated from message geneo.school.db.SchoolCourseContentLock
 */
export class SchoolCourseContentLock extends Message<SchoolCourseContentLock> {
  /**
   * @generated from field: int32 school_course_content_lock_id = 1;
   */
  schoolCourseContentLockId = 0;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 course_id = 3;
   */
  courseId = 0;

  /**
   * @generated from field: int32 school_class_section_id = 4;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: geneo.school.db.SchoolContentModuleNodeLock school_course_lock_node = 5;
   */
  schoolCourseLockNode?: SchoolContentModuleNodeLock;

  /**
   * - indicates student clicker activity
   *
   * @generated from field: optional bool is_active = 6;
   */
  isActive?: boolean;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 7;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 8;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 9;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 10;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 11;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: int32 version = 12;
   */
  version = 0;

  constructor(data?: PartialMessage<SchoolCourseContentLock>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.school.db.SchoolCourseContentLock";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_course_content_lock_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "school_course_lock_node", kind: "message", T: SchoolContentModuleNodeLock },
    { no: 6, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "created_on", kind: "message", T: Timestamp },
    { no: 8, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "modified_on", kind: "message", T: Timestamp },
    { no: 10, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolCourseContentLock {
    return new SchoolCourseContentLock().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolCourseContentLock {
    return new SchoolCourseContentLock().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolCourseContentLock {
    return new SchoolCourseContentLock().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolCourseContentLock | PlainMessage<SchoolCourseContentLock> | undefined, b: SchoolCourseContentLock | PlainMessage<SchoolCourseContentLock> | undefined): boolean {
    return proto3.util.equals(SchoolCourseContentLock, a, b);
  }
}

