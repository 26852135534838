import { Timestamp } from '@bufbuild/protobuf';
import { useEffect } from 'react';
import { useCommonServiceClientContext } from './CommonServiceClientProvider';
import { getLocalStorage, setLocalStorage } from './utilFunctions';

interface ManageSessionProps {
  role: number; // Define the type of role parameter
}
const ManageSession: React.FC<ManageSessionProps> = ({ role }) => {
  const { UmsUtilityAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const checkAndUpdateTime = async () => {
    const getCurrentTime = () => new Date().getTime();
    const getLastUpdatedTime = () => {
      const lastUpdatedTime = getLocalStorage('lastUpdatedTime');
      return lastUpdatedTime ? parseInt(lastUpdatedTime) : null;
    };

    let auth = getLocalStorage('auth');
    if (auth === 'true' || auth === 'false') {
      auth = JSON.parse(auth);
    }

    const updateTimeInLocalStorage = () => {
      const currentTime = getCurrentTime();
      setLocalStorage('lastUpdatedTime', String(currentTime));
      console.log('Time updated in localStorage:', new Date(currentTime));
    };

    const endSession = async () => {
      console.log('Calling API...');
      // Call your API here
      const activeSessionId = getLocalStorage('activeSession');
      try {
        const response =
          await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.upsertActiveSession(
            {
              activeSessionId: parseInt(activeSessionId || ''),
              endTime: Timestamp.fromDate(new Date()),
              lastUsageTime: Timestamp.fromDate(new Date()),
              role: role,
            }
          );
        console.log('API response:', response); // Log the response
      } catch (error) {
        console.error('Fetch error:', error);
      }
      updateTimeInLocalStorage();
    };

    const startSession = async () => {
      const userID = getLocalStorage('userId');
      // Call your API here
      try {
        const response =
          await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.upsertActiveSession(
            {
              profileId: userID,
              startTime: Timestamp.fromDate(new Date()),
              role: role,
            }
          );
        console.log('API response:', response); // Log the response
        if (
          response &&
          response.data &&
          response?.data?.case === 'activeSessionData'
        ) {
          setLocalStorage(
            'activeSession',
            response?.data?.value?.activeSessionId?.toString()
          );
          console.log(
            'Active session ID:',
            response?.data?.value?.activeSessionId
          );
        } else {
          console.error('Invalid response format:', response);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      }
      updateTimeInLocalStorage();
    };

    if (auth) {
      const lastUpdatedTime = getLastUpdatedTime();
      if (lastUpdatedTime !== null) {
        const currentTime = getCurrentTime();
        const lastActiveSession = getLocalStorage('activeSession');
        console.log(currentTime, lastUpdatedTime);
        console.log(currentTime - lastUpdatedTime);
        console.log('Last active session:', lastActiveSession);
        if (currentTime - lastUpdatedTime > 3600000) {
          // 3600000 milliseconds = 1 hour
          console.log('Difference more than one hour. Calling API...');
          console.log('Last active session:', lastActiveSession); // Get last active session from localStorage
          await endSession();
          await startSession(); // Call API only if time difference condition is met
        } else {
          console.log(
            'Difference less than or equal to one hour. Not calling API.'
          );
        }
      } else {
        console.log('Last updated time not found');
      }
    } else {
      console.log('Auth is false. Not calling API.');
    }

    if (auth && !document.hidden) {
      console.log('Updating every minute and authenticated');
      updateTimeInLocalStorage(); // Update time in localStorage if authenticated and not hidden
    } else if (!auth && !document.hidden) {
      console.log(
        'Updating every minute and not authenticated, clearing localStorage'
      );
      updateTimeInLocalStorage(); // Clear localStorage if not authenticated and not hidden
    }
  };

  useEffect(() => {
    const auth = getLocalStorage('auth');
    if (auth)
      // Add type declaration for 'auth'
      checkAndUpdateTime();

    // Update time every minute
    const intervalId = setInterval(() => {
      checkAndUpdateTime();
      console.log('Time updated at interval:', new Date());
    }, 60000); // 60000 milliseconds = 1 minute

    return () => {
      clearInterval(intervalId); // Clear the interval on component unmount
      console.log('Interval cleared and cleanup performed');
    };
  }, []);

  return null;
};

export default ManageSession;
