// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.connected.apis.proto (package geneo.lms.connected.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Class_ClassEnum, Subject_SubjectEnum } from "@protos/common/common.db_pb";
import { SessionStatusEnum } from "@protos/learning_management/lms.db_pb";

/**
 * @generated from message geneo.lms.connected.apis.RegisterBaseReceiverRequest
 */
export class RegisterBaseReceiverRequest extends Message<RegisterBaseReceiverRequest> {
  /**
   * @generated from field: string type = 1;
   */
  type = "";

  /**
   * @generated from field: string model = 2;
   */
  model = "";

  /**
   * @generated from field: string version = 3;
   */
  version = "";

  /**
   * @generated from field: string serial_no = 4;
   */
  serialNo = "";

  /**
   * @generated from field: optional string description = 5;
   */
  description?: string;

  /**
   * @generated from field: bool is_active = 6;
   */
  isActive = false;

  constructor(data?: PartialMessage<RegisterBaseReceiverRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.RegisterBaseReceiverRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "serial_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterBaseReceiverRequest {
    return new RegisterBaseReceiverRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterBaseReceiverRequest {
    return new RegisterBaseReceiverRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterBaseReceiverRequest {
    return new RegisterBaseReceiverRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterBaseReceiverRequest | PlainMessage<RegisterBaseReceiverRequest> | undefined, b: RegisterBaseReceiverRequest | PlainMessage<RegisterBaseReceiverRequest> | undefined): boolean {
    return proto3.util.equals(RegisterBaseReceiverRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.RegisterBaseReceiverResponse
 */
export class RegisterBaseReceiverResponse extends Message<RegisterBaseReceiverResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int32 base_receiver_id = 4;
   */
  baseReceiverId = 0;

  constructor(data?: PartialMessage<RegisterBaseReceiverResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.RegisterBaseReceiverResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "base_receiver_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterBaseReceiverResponse {
    return new RegisterBaseReceiverResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterBaseReceiverResponse {
    return new RegisterBaseReceiverResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterBaseReceiverResponse {
    return new RegisterBaseReceiverResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterBaseReceiverResponse | PlainMessage<RegisterBaseReceiverResponse> | undefined, b: RegisterBaseReceiverResponse | PlainMessage<RegisterBaseReceiverResponse> | undefined): boolean {
    return proto3.util.equals(RegisterBaseReceiverResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.RegisterClickerRequest
 */
export class RegisterClickerRequest extends Message<RegisterClickerRequest> {
  /**
   * @generated from field: string type = 1;
   */
  type = "";

  /**
   * @generated from field: string model = 2;
   */
  model = "";

  /**
   * @generated from field: string version = 3;
   */
  version = "";

  /**
   * @generated from field: string serial_no = 4;
   */
  serialNo = "";

  /**
   * @generated from field: optional string description = 5;
   */
  description?: string;

  /**
   * @generated from field: bool is_active = 6;
   */
  isActive = false;

  constructor(data?: PartialMessage<RegisterClickerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.RegisterClickerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "serial_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterClickerRequest {
    return new RegisterClickerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterClickerRequest {
    return new RegisterClickerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterClickerRequest {
    return new RegisterClickerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterClickerRequest | PlainMessage<RegisterClickerRequest> | undefined, b: RegisterClickerRequest | PlainMessage<RegisterClickerRequest> | undefined): boolean {
    return proto3.util.equals(RegisterClickerRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.RegisterClickerResponse
 */
export class RegisterClickerResponse extends Message<RegisterClickerResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int64 clicker_id = 4;
   */
  clickerId = protoInt64.zero;

  constructor(data?: PartialMessage<RegisterClickerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.RegisterClickerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "clicker_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterClickerResponse {
    return new RegisterClickerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterClickerResponse {
    return new RegisterClickerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterClickerResponse {
    return new RegisterClickerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterClickerResponse | PlainMessage<RegisterClickerResponse> | undefined, b: RegisterClickerResponse | PlainMessage<RegisterClickerResponse> | undefined): boolean {
    return proto3.util.equals(RegisterClickerResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertClassReceiverConfigRequest
 */
export class UpsertClassReceiverConfigRequest extends Message<UpsertClassReceiverConfigRequest> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum class = 2;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: int32 sectionId = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: int32 base_receiver_id = 4;
   */
  baseReceiverId = 0;

  /**
   * @generated from field: optional int64 teacher_id = 5;
   */
  teacherId?: bigint;

  /**
   * @generated from field: bool is_active = 6;
   */
  isActive = false;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_on = 7;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 8;
   */
  createdBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 9;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 10;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 11;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<UpsertClassReceiverConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertClassReceiverConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 3, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "base_receiver_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertClassReceiverConfigRequest {
    return new UpsertClassReceiverConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertClassReceiverConfigRequest {
    return new UpsertClassReceiverConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertClassReceiverConfigRequest {
    return new UpsertClassReceiverConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertClassReceiverConfigRequest | PlainMessage<UpsertClassReceiverConfigRequest> | undefined, b: UpsertClassReceiverConfigRequest | PlainMessage<UpsertClassReceiverConfigRequest> | undefined): boolean {
    return proto3.util.equals(UpsertClassReceiverConfigRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertClassReceiverConfigResponse
 */
export class UpsertClassReceiverConfigResponse extends Message<UpsertClassReceiverConfigResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int64 class_receiver_id = 4;
   */
  classReceiverId = protoInt64.zero;

  constructor(data?: PartialMessage<UpsertClassReceiverConfigResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertClassReceiverConfigResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "class_receiver_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertClassReceiverConfigResponse {
    return new UpsertClassReceiverConfigResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertClassReceiverConfigResponse {
    return new UpsertClassReceiverConfigResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertClassReceiverConfigResponse {
    return new UpsertClassReceiverConfigResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertClassReceiverConfigResponse | PlainMessage<UpsertClassReceiverConfigResponse> | undefined, b: UpsertClassReceiverConfigResponse | PlainMessage<UpsertClassReceiverConfigResponse> | undefined): boolean {
    return proto3.util.equals(UpsertClassReceiverConfigResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertStudentClickerConfigRequest
 */
export class UpsertStudentClickerConfigRequest extends Message<UpsertStudentClickerConfigRequest> {
  /**
   * referencing student profile id
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * clicker id
   *
   * @generated from field: int64 clicker_id = 2;
   */
  clickerId = protoInt64.zero;

  /**
   * is student clicker active
   *
   * @generated from field: bool is_active = 3;
   */
  isActive = false;

  /**
   * Creation timestamp
   *
   * @generated from field: google.protobuf.Timestamp created_on = 4;
   */
  createdOn?: Timestamp;

  /**
   * User who created the entry
   *
   * @generated from field: optional int64 created_by = 5;
   */
  createdBy?: bigint;

  /**
   * Last modification timestamp
   *
   * @generated from field: google.protobuf.Timestamp modified_on = 6;
   */
  modifiedOn?: Timestamp;

  /**
   * User who last modified the entry
   *
   * @generated from field: optional int64 modified_by = 7;
   */
  modifiedBy?: bigint;

  /**
   * represents the deleted timestamp
   *
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 8;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<UpsertStudentClickerConfigRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertStudentClickerConfigRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "clicker_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "created_on", kind: "message", T: Timestamp },
    { no: 5, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "modified_on", kind: "message", T: Timestamp },
    { no: 7, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertStudentClickerConfigRequest {
    return new UpsertStudentClickerConfigRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertStudentClickerConfigRequest {
    return new UpsertStudentClickerConfigRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertStudentClickerConfigRequest {
    return new UpsertStudentClickerConfigRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertStudentClickerConfigRequest | PlainMessage<UpsertStudentClickerConfigRequest> | undefined, b: UpsertStudentClickerConfigRequest | PlainMessage<UpsertStudentClickerConfigRequest> | undefined): boolean {
    return proto3.util.equals(UpsertStudentClickerConfigRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertStudentClickerConfigResponse
 */
export class UpsertStudentClickerConfigResponse extends Message<UpsertStudentClickerConfigResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int64 student_clicker_id = 4;
   */
  studentClickerId = protoInt64.zero;

  constructor(data?: PartialMessage<UpsertStudentClickerConfigResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertStudentClickerConfigResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "student_clicker_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertStudentClickerConfigResponse {
    return new UpsertStudentClickerConfigResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertStudentClickerConfigResponse {
    return new UpsertStudentClickerConfigResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertStudentClickerConfigResponse {
    return new UpsertStudentClickerConfigResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertStudentClickerConfigResponse | PlainMessage<UpsertStudentClickerConfigResponse> | undefined, b: UpsertStudentClickerConfigResponse | PlainMessage<UpsertStudentClickerConfigResponse> | undefined): boolean {
    return proto3.util.equals(UpsertStudentClickerConfigResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetBaseStationConnectionRequest
 */
export class GetBaseStationConnectionRequest extends Message<GetBaseStationConnectionRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 class_id = 2;
   */
  classId = 0;

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  constructor(data?: PartialMessage<GetBaseStationConnectionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetBaseStationConnectionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBaseStationConnectionRequest {
    return new GetBaseStationConnectionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBaseStationConnectionRequest {
    return new GetBaseStationConnectionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBaseStationConnectionRequest {
    return new GetBaseStationConnectionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetBaseStationConnectionRequest | PlainMessage<GetBaseStationConnectionRequest> | undefined, b: GetBaseStationConnectionRequest | PlainMessage<GetBaseStationConnectionRequest> | undefined): boolean {
    return proto3.util.equals(GetBaseStationConnectionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.BaseStationDetails
 */
export class BaseStationDetails extends Message<BaseStationDetails> {
  /**
   * @generated from field: int32 base_receiver_id = 1;
   */
  baseReceiverId = 0;

  /**
   * @generated from field: string base_receiver_serial_no = 2;
   */
  baseReceiverSerialNo = "";

  /**
   * @generated from field: bool is_online = 3;
   */
  isOnline = false;

  constructor(data?: PartialMessage<BaseStationDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.BaseStationDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_receiver_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "base_receiver_serial_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_online", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BaseStationDetails {
    return new BaseStationDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BaseStationDetails {
    return new BaseStationDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BaseStationDetails {
    return new BaseStationDetails().fromJsonString(jsonString, options);
  }

  static equals(a: BaseStationDetails | PlainMessage<BaseStationDetails> | undefined, b: BaseStationDetails | PlainMessage<BaseStationDetails> | undefined): boolean {
    return proto3.util.equals(BaseStationDetails, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetBaseStationConnectionResponse
 */
export class GetBaseStationConnectionResponse extends Message<GetBaseStationConnectionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.connected.apis.BaseStationDetails base_station_details = 4;
   */
  baseStationDetails?: BaseStationDetails;

  constructor(data?: PartialMessage<GetBaseStationConnectionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetBaseStationConnectionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "base_station_details", kind: "message", T: BaseStationDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBaseStationConnectionResponse {
    return new GetBaseStationConnectionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBaseStationConnectionResponse {
    return new GetBaseStationConnectionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBaseStationConnectionResponse {
    return new GetBaseStationConnectionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetBaseStationConnectionResponse | PlainMessage<GetBaseStationConnectionResponse> | undefined, b: GetBaseStationConnectionResponse | PlainMessage<GetBaseStationConnectionResponse> | undefined): boolean {
    return proto3.util.equals(GetBaseStationConnectionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertConnectedClassSessionRequest
 */
export class UpsertConnectedClassSessionRequest extends Message<UpsertConnectedClassSessionRequest> {
  /**
   * @generated from field: optional int32 classId = 1;
   */
  classId?: number;

  /**
   * @generated from field: optional int32 sectionId = 2;
   */
  sectionId?: number;

  /**
   * @generated from field: optional geneo.common.db.Subject.SubjectEnum subject = 3;
   */
  subject?: Subject_SubjectEnum;

  /**
   * @generated from field: optional int32 base_receiver_id = 4;
   */
  baseReceiverId?: number;

  /**
   * @generated from field: optional int64 teacher_id = 5;
   */
  teacherId?: bigint;

  /**
   * @generated from field: optional string lesson_id = 6;
   */
  lessonId?: string;

  /**
   * @generated from field: optional int32 school_id = 15;
   */
  schoolId?: number;

  /**
   * @generated from field: int32 teacher_lesson_session_id = 7;
   */
  teacherLessonSessionId = 0;

  /**
   * @generated from field: optional google.protobuf.Timestamp session_start_time = 8;
   */
  sessionStartTime?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp session_end_time = 9;
   */
  sessionEndTime?: Timestamp;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 10;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_on = 11;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 12;
   */
  createdBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 13;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 14;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional int64 connected_class_session_id = 16;
   */
  connectedClassSessionId?: bigint;

  constructor(data?: PartialMessage<UpsertConnectedClassSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertConnectedClassSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "classId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum), opt: true },
    { no: 4, name: "base_receiver_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 6, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "session_start_time", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "session_end_time", kind: "message", T: Timestamp, opt: true },
    { no: 10, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 11, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 16, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertConnectedClassSessionRequest {
    return new UpsertConnectedClassSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertConnectedClassSessionRequest {
    return new UpsertConnectedClassSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertConnectedClassSessionRequest {
    return new UpsertConnectedClassSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertConnectedClassSessionRequest | PlainMessage<UpsertConnectedClassSessionRequest> | undefined, b: UpsertConnectedClassSessionRequest | PlainMessage<UpsertConnectedClassSessionRequest> | undefined): boolean {
    return proto3.util.equals(UpsertConnectedClassSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertConnectedClassSessionResponse
 */
export class UpsertConnectedClassSessionResponse extends Message<UpsertConnectedClassSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int64 connected_class_session_id = 4;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 5;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<UpsertConnectedClassSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertConnectedClassSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertConnectedClassSessionResponse {
    return new UpsertConnectedClassSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertConnectedClassSessionResponse {
    return new UpsertConnectedClassSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertConnectedClassSessionResponse {
    return new UpsertConnectedClassSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertConnectedClassSessionResponse | PlainMessage<UpsertConnectedClassSessionResponse> | undefined, b: UpsertConnectedClassSessionResponse | PlainMessage<UpsertConnectedClassSessionResponse> | undefined): boolean {
    return proto3.util.equals(UpsertConnectedClassSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertStudentClickerSessionRequest
 */
export class UpsertStudentClickerSessionRequest extends Message<UpsertStudentClickerSessionRequest> {
  /**
   * @generated from field: int64 connected_class_session_id = 1;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * @generated from field: optional int64 connected_class_student_session_id = 2;
   */
  connectedClassStudentSessionId?: bigint;

  /**
   * @generated from field: int64 student_id = 3;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string clicker_id = 4;
   */
  clickerId = "";

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 5;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<UpsertStudentClickerSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertStudentClickerSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "connected_class_student_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "clicker_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertStudentClickerSessionRequest {
    return new UpsertStudentClickerSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertStudentClickerSessionRequest {
    return new UpsertStudentClickerSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertStudentClickerSessionRequest {
    return new UpsertStudentClickerSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertStudentClickerSessionRequest | PlainMessage<UpsertStudentClickerSessionRequest> | undefined, b: UpsertStudentClickerSessionRequest | PlainMessage<UpsertStudentClickerSessionRequest> | undefined): boolean {
    return proto3.util.equals(UpsertStudentClickerSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertStudentClickerSessionResponse
 */
export class UpsertStudentClickerSessionResponse extends Message<UpsertStudentClickerSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int64 connected_class_student_session_id = 4;
   */
  connectedClassStudentSessionId = protoInt64.zero;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 5;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<UpsertStudentClickerSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertStudentClickerSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "connected_class_student_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertStudentClickerSessionResponse {
    return new UpsertStudentClickerSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertStudentClickerSessionResponse {
    return new UpsertStudentClickerSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertStudentClickerSessionResponse {
    return new UpsertStudentClickerSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertStudentClickerSessionResponse | PlainMessage<UpsertStudentClickerSessionResponse> | undefined, b: UpsertStudentClickerSessionResponse | PlainMessage<UpsertStudentClickerSessionResponse> | undefined): boolean {
    return proto3.util.equals(UpsertStudentClickerSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertConnectedClassResourceSessionRequest
 */
export class UpsertConnectedClassResourceSessionRequest extends Message<UpsertConnectedClassResourceSessionRequest> {
  /**
   * @generated from field: int64 connected_class_session_id = 1;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * @generated from field: string resource_id = 2;
   */
  resourceId = "";

  /**
   * @generated from field: optional google.protobuf.Timestamp session_start_time = 3;
   */
  sessionStartTime?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp session_end_time = 4;
   */
  sessionEndTime?: Timestamp;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 5;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_on = 6;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 7;
   */
  createdBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 8;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 9;
   */
  modifiedBy?: bigint;

  /**
   * @generated from field: optional int64 connected_class_resource_session_id = 10;
   */
  connectedClassResourceSessionId?: bigint;

  constructor(data?: PartialMessage<UpsertConnectedClassResourceSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertConnectedClassResourceSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "session_start_time", kind: "message", T: Timestamp, opt: true },
    { no: 4, name: "session_end_time", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 6, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 8, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "connected_class_resource_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertConnectedClassResourceSessionRequest {
    return new UpsertConnectedClassResourceSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertConnectedClassResourceSessionRequest {
    return new UpsertConnectedClassResourceSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertConnectedClassResourceSessionRequest {
    return new UpsertConnectedClassResourceSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertConnectedClassResourceSessionRequest | PlainMessage<UpsertConnectedClassResourceSessionRequest> | undefined, b: UpsertConnectedClassResourceSessionRequest | PlainMessage<UpsertConnectedClassResourceSessionRequest> | undefined): boolean {
    return proto3.util.equals(UpsertConnectedClassResourceSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertConnectedClassResourceSessionResponse
 */
export class UpsertConnectedClassResourceSessionResponse extends Message<UpsertConnectedClassResourceSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int64 connected_class_resource_session_id = 4;
   */
  connectedClassResourceSessionId = protoInt64.zero;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 5;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<UpsertConnectedClassResourceSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertConnectedClassResourceSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "connected_class_resource_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertConnectedClassResourceSessionResponse {
    return new UpsertConnectedClassResourceSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertConnectedClassResourceSessionResponse {
    return new UpsertConnectedClassResourceSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertConnectedClassResourceSessionResponse {
    return new UpsertConnectedClassResourceSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertConnectedClassResourceSessionResponse | PlainMessage<UpsertConnectedClassResourceSessionResponse> | undefined, b: UpsertConnectedClassResourceSessionResponse | PlainMessage<UpsertConnectedClassResourceSessionResponse> | undefined): boolean {
    return proto3.util.equals(UpsertConnectedClassResourceSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertConnectedClassQuestionSessionRequest
 */
export class UpsertConnectedClassQuestionSessionRequest extends Message<UpsertConnectedClassQuestionSessionRequest> {
  /**
   * @generated from field: optional int64 connected_class_question_session_id = 1;
   */
  connectedClassQuestionSessionId?: bigint;

  /**
   * @generated from field: int64 connected_class_resource_session_id = 2;
   */
  connectedClassResourceSessionId = protoInt64.zero;

  /**
   * @generated from field: string question_id = 3;
   */
  questionId = "";

  /**
   * @generated from field: int32 sequence = 4;
   */
  sequence = 0;

  /**
   * @generated from field: optional google.protobuf.Timestamp start_time = 5;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp end_time = 6;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 7;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<UpsertConnectedClassQuestionSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertConnectedClassQuestionSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_question_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "connected_class_resource_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sequence", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start_time", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "end_time", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertConnectedClassQuestionSessionRequest {
    return new UpsertConnectedClassQuestionSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertConnectedClassQuestionSessionRequest {
    return new UpsertConnectedClassQuestionSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertConnectedClassQuestionSessionRequest {
    return new UpsertConnectedClassQuestionSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertConnectedClassQuestionSessionRequest | PlainMessage<UpsertConnectedClassQuestionSessionRequest> | undefined, b: UpsertConnectedClassQuestionSessionRequest | PlainMessage<UpsertConnectedClassQuestionSessionRequest> | undefined): boolean {
    return proto3.util.equals(UpsertConnectedClassQuestionSessionRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpsertConnectedClassQuestionSessionResponse
 */
export class UpsertConnectedClassQuestionSessionResponse extends Message<UpsertConnectedClassQuestionSessionResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int64 connected_class_question_session_id = 4;
   */
  connectedClassQuestionSessionId = protoInt64.zero;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 5;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<UpsertConnectedClassQuestionSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpsertConnectedClassQuestionSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "connected_class_question_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpsertConnectedClassQuestionSessionResponse {
    return new UpsertConnectedClassQuestionSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpsertConnectedClassQuestionSessionResponse {
    return new UpsertConnectedClassQuestionSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpsertConnectedClassQuestionSessionResponse {
    return new UpsertConnectedClassQuestionSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpsertConnectedClassQuestionSessionResponse | PlainMessage<UpsertConnectedClassQuestionSessionResponse> | undefined, b: UpsertConnectedClassQuestionSessionResponse | PlainMessage<UpsertConnectedClassQuestionSessionResponse> | undefined): boolean {
    return proto3.util.equals(UpsertConnectedClassQuestionSessionResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.SubmitConnectedClassStudentResponseRequest
 */
export class SubmitConnectedClassStudentResponseRequest extends Message<SubmitConnectedClassStudentResponseRequest> {
  /**
   * @generated from field: int64 connected_class_session_id = 1;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * @generated from field: int64 connected_class_resource_session_id = 2;
   */
  connectedClassResourceSessionId = protoInt64.zero;

  /**
   * @generated from field: int64 connected_class_question_session_id = 3;
   */
  connectedClassQuestionSessionId = protoInt64.zero;

  /**
   * @generated from field: int64 connected_class_student_session_id = 4;
   */
  connectedClassStudentSessionId = protoInt64.zero;

  /**
   * @generated from field: string question_id = 5;
   */
  questionId = "";

  /**
   * @generated from field: int64 student_id = 6;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string clicker_id = 7;
   */
  clickerId = "";

  /**
   * @generated from field: string response = 8;
   */
  response = "";

  /**
   * @generated from field: float time_spent = 9;
   */
  timeSpent = 0;

  constructor(data?: PartialMessage<SubmitConnectedClassStudentResponseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.SubmitConnectedClassStudentResponseRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "connected_class_resource_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "connected_class_question_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "connected_class_student_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "clicker_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "time_spent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitConnectedClassStudentResponseRequest {
    return new SubmitConnectedClassStudentResponseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitConnectedClassStudentResponseRequest {
    return new SubmitConnectedClassStudentResponseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitConnectedClassStudentResponseRequest {
    return new SubmitConnectedClassStudentResponseRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitConnectedClassStudentResponseRequest | PlainMessage<SubmitConnectedClassStudentResponseRequest> | undefined, b: SubmitConnectedClassStudentResponseRequest | PlainMessage<SubmitConnectedClassStudentResponseRequest> | undefined): boolean {
    return proto3.util.equals(SubmitConnectedClassStudentResponseRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.SubmitConnectedClassStudentResponse
 */
export class SubmitConnectedClassStudentResponse extends Message<SubmitConnectedClassStudentResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<SubmitConnectedClassStudentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.SubmitConnectedClassStudentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitConnectedClassStudentResponse {
    return new SubmitConnectedClassStudentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitConnectedClassStudentResponse {
    return new SubmitConnectedClassStudentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitConnectedClassStudentResponse {
    return new SubmitConnectedClassStudentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitConnectedClassStudentResponse | PlainMessage<SubmitConnectedClassStudentResponse> | undefined, b: SubmitConnectedClassStudentResponse | PlainMessage<SubmitConnectedClassStudentResponse> | undefined): boolean {
    return proto3.util.equals(SubmitConnectedClassStudentResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetConnectedClassSessionAnalyticsRequest
 */
export class GetConnectedClassSessionAnalyticsRequest extends Message<GetConnectedClassSessionAnalyticsRequest> {
  /**
   * @generated from field: int32 teacher_lesson_session_id = 1;
   */
  teacherLessonSessionId = 0;

  /**
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  constructor(data?: PartialMessage<GetConnectedClassSessionAnalyticsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetConnectedClassSessionAnalyticsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectedClassSessionAnalyticsRequest {
    return new GetConnectedClassSessionAnalyticsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectedClassSessionAnalyticsRequest {
    return new GetConnectedClassSessionAnalyticsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectedClassSessionAnalyticsRequest {
    return new GetConnectedClassSessionAnalyticsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectedClassSessionAnalyticsRequest | PlainMessage<GetConnectedClassSessionAnalyticsRequest> | undefined, b: GetConnectedClassSessionAnalyticsRequest | PlainMessage<GetConnectedClassSessionAnalyticsRequest> | undefined): boolean {
    return proto3.util.equals(GetConnectedClassSessionAnalyticsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetConnectedClassSessionAnalyticsResponse
 */
export class GetConnectedClassSessionAnalyticsResponse extends Message<GetConnectedClassSessionAnalyticsResponse> {
  /**
   * @generated from field: int64 teacher_lesson_session_id = 1;
   */
  teacherLessonSessionId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 classId = 3;
   */
  classId = 0;

  /**
   * @generated from field: int32 sectionId = 4;
   */
  sectionId = 0;

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 5;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: string base_receiver_id = 6;
   */
  baseReceiverId = "";

  /**
   * @generated from field: int64 teacher_id = 7;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string lesson_id = 8;
   */
  lessonId = "";

  /**
   * @generated from field: repeated int64 connected_class_session_ids = 9;
   */
  connectedClassSessionIds: bigint[] = [];

  /**
   * @generated from field: repeated string resource_ids = 10;
   */
  resourceIds: string[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp session_start_time = 11;
   */
  sessionStartTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp session_end_time = 12;
   */
  sessionEndTime?: Timestamp;

  /**
   * @generated from field: int32 no_of_students = 13;
   */
  noOfStudents = 0;

  /**
   * @generated from field: int32 no_of_questions = 14;
   */
  noOfQuestions = 0;

  /**
   * @generated from field: int32 no_of_correct_answers = 15;
   */
  noOfCorrectAnswers = 0;

  /**
   * @generated from field: int32 no_of_incorrect_answers = 16;
   */
  noOfIncorrectAnswers = 0;

  /**
   * @generated from field: int32 no_of_unanswered_questions = 17;
   */
  noOfUnansweredQuestions = 0;

  /**
   * @generated from field: float total_score = 18;
   */
  totalScore = 0;

  /**
   * @generated from field: int32 total_possible_score = 19;
   */
  totalPossibleScore = 0;

  /**
   * @generated from field: float time_spent_in_mins = 20;
   */
  timeSpentInMins = 0;

  /**
   * @generated from field: repeated geneo.lms.connected.apis.ConnectedClassStudentAnalytics student_analytics = 21;
   */
  studentAnalytics: ConnectedClassStudentAnalytics[] = [];

  /**
   * @generated from field: repeated string strengths = 22;
   */
  strengths: string[] = [];

  /**
   * @generated from field: repeated string weakness = 23;
   */
  weakness: string[] = [];

  constructor(data?: PartialMessage<GetConnectedClassSessionAnalyticsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetConnectedClassSessionAnalyticsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_lesson_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "classId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 6, name: "base_receiver_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "connected_class_session_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 10, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "session_start_time", kind: "message", T: Timestamp },
    { no: 12, name: "session_end_time", kind: "message", T: Timestamp },
    { no: 13, name: "no_of_students", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "no_of_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "no_of_correct_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "no_of_incorrect_answers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "no_of_unanswered_questions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "total_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 19, name: "total_possible_score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 20, name: "time_spent_in_mins", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 21, name: "student_analytics", kind: "message", T: ConnectedClassStudentAnalytics, repeated: true },
    { no: 22, name: "strengths", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 23, name: "weakness", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectedClassSessionAnalyticsResponse {
    return new GetConnectedClassSessionAnalyticsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectedClassSessionAnalyticsResponse {
    return new GetConnectedClassSessionAnalyticsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectedClassSessionAnalyticsResponse {
    return new GetConnectedClassSessionAnalyticsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectedClassSessionAnalyticsResponse | PlainMessage<GetConnectedClassSessionAnalyticsResponse> | undefined, b: GetConnectedClassSessionAnalyticsResponse | PlainMessage<GetConnectedClassSessionAnalyticsResponse> | undefined): boolean {
    return proto3.util.equals(GetConnectedClassSessionAnalyticsResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.ConnectedClassStudentAnalytics
 */
export class ConnectedClassStudentAnalytics extends Message<ConnectedClassStudentAnalytics> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string student_name = 2;
   */
  studentName = "";

  /**
   * @generated from field: string clicker_id = 3;
   */
  clickerId = "";

  /**
   * @generated from field: float score = 4;
   */
  score = 0;

  constructor(data?: PartialMessage<ConnectedClassStudentAnalytics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.ConnectedClassStudentAnalytics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "student_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "clicker_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassStudentAnalytics {
    return new ConnectedClassStudentAnalytics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassStudentAnalytics {
    return new ConnectedClassStudentAnalytics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassStudentAnalytics {
    return new ConnectedClassStudentAnalytics().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassStudentAnalytics | PlainMessage<ConnectedClassStudentAnalytics> | undefined, b: ConnectedClassStudentAnalytics | PlainMessage<ConnectedClassStudentAnalytics> | undefined): boolean {
    return proto3.util.equals(ConnectedClassStudentAnalytics, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.ConnectedClassQuestionAnalytics
 */
export class ConnectedClassQuestionAnalytics extends Message<ConnectedClassQuestionAnalytics> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  /**
   * @generated from field: bool is_correct = 2;
   */
  isCorrect = false;

  /**
   * @generated from field: int32 score = 3;
   */
  score = 0;

  constructor(data?: PartialMessage<ConnectedClassQuestionAnalytics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.ConnectedClassQuestionAnalytics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_correct", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "score", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassQuestionAnalytics {
    return new ConnectedClassQuestionAnalytics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassQuestionAnalytics {
    return new ConnectedClassQuestionAnalytics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassQuestionAnalytics {
    return new ConnectedClassQuestionAnalytics().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassQuestionAnalytics | PlainMessage<ConnectedClassQuestionAnalytics> | undefined, b: ConnectedClassQuestionAnalytics | PlainMessage<ConnectedClassQuestionAnalytics> | undefined): boolean {
    return proto3.util.equals(ConnectedClassQuestionAnalytics, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetConnectedClassSessionDetailsRequest
 */
export class GetConnectedClassSessionDetailsRequest extends Message<GetConnectedClassSessionDetailsRequest> {
  /**
   * @generated from field: int64 teacher_lesson_session_id = 1;
   */
  teacherLessonSessionId = protoInt64.zero;

  /**
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  constructor(data?: PartialMessage<GetConnectedClassSessionDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetConnectedClassSessionDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_lesson_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectedClassSessionDetailsRequest {
    return new GetConnectedClassSessionDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectedClassSessionDetailsRequest {
    return new GetConnectedClassSessionDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectedClassSessionDetailsRequest {
    return new GetConnectedClassSessionDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectedClassSessionDetailsRequest | PlainMessage<GetConnectedClassSessionDetailsRequest> | undefined, b: GetConnectedClassSessionDetailsRequest | PlainMessage<GetConnectedClassSessionDetailsRequest> | undefined): boolean {
    return proto3.util.equals(GetConnectedClassSessionDetailsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetConnectedClassSessionDetailsResponse
 */
export class GetConnectedClassSessionDetailsResponse extends Message<GetConnectedClassSessionDetailsResponse> {
  /**
   * @generated from field: int64 teacher_lesson_session_id = 1;
   */
  teacherLessonSessionId = protoInt64.zero;

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: geneo.common.db.Class.ClassEnum class = 3;
   */
  class = Class_ClassEnum.UNDEFINED;

  /**
   * @generated from field: int32 sectionId = 4;
   */
  sectionId = 0;

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 5;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * @generated from field: string base_receiver_id = 6;
   */
  baseReceiverId = "";

  /**
   * @generated from field: int64 teacher_id = 7;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string lesson_id = 8;
   */
  lessonId = "";

  /**
   * @generated from field: repeated geneo.lms.connected.apis.ConnectedClassSessionData connected_class_session_data = 9;
   */
  connectedClassSessionData: ConnectedClassSessionData[] = [];

  constructor(data?: PartialMessage<GetConnectedClassSessionDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetConnectedClassSessionDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_lesson_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "class", kind: "enum", T: proto3.getEnumType(Class_ClassEnum) },
    { no: 4, name: "sectionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 6, name: "base_receiver_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "connected_class_session_data", kind: "message", T: ConnectedClassSessionData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectedClassSessionDetailsResponse {
    return new GetConnectedClassSessionDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectedClassSessionDetailsResponse {
    return new GetConnectedClassSessionDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectedClassSessionDetailsResponse {
    return new GetConnectedClassSessionDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectedClassSessionDetailsResponse | PlainMessage<GetConnectedClassSessionDetailsResponse> | undefined, b: GetConnectedClassSessionDetailsResponse | PlainMessage<GetConnectedClassSessionDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetConnectedClassSessionDetailsResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.ConnectedClassSessionData
 */
export class ConnectedClassSessionData extends Message<ConnectedClassSessionData> {
  /**
   * @generated from field: int64 connected_class_session_id = 1;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * @generated from field: repeated string resource_ids = 2;
   */
  resourceIds: string[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp session_start_time = 3;
   */
  sessionStartTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp session_end_time = 4;
   */
  sessionEndTime?: Timestamp;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 5;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * @generated from field: repeated geneo.lms.connected.apis.SessionQuestionData question_data = 6;
   */
  questionData: SessionQuestionData[] = [];

  constructor(data?: PartialMessage<ConnectedClassSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.ConnectedClassSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "session_start_time", kind: "message", T: Timestamp },
    { no: 4, name: "session_end_time", kind: "message", T: Timestamp },
    { no: 5, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 6, name: "question_data", kind: "message", T: SessionQuestionData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedClassSessionData {
    return new ConnectedClassSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedClassSessionData {
    return new ConnectedClassSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedClassSessionData {
    return new ConnectedClassSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedClassSessionData | PlainMessage<ConnectedClassSessionData> | undefined, b: ConnectedClassSessionData | PlainMessage<ConnectedClassSessionData> | undefined): boolean {
    return proto3.util.equals(ConnectedClassSessionData, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.SessionQuestionData
 */
export class SessionQuestionData extends Message<SessionQuestionData> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = "";

  /**
   * @generated from field: string question_text = 2;
   */
  questionText = "";

  /**
   * @generated from field: string question_type = 3;
   */
  questionType = "";

  /**
   * @generated from field: repeated geneo.lms.connected.apis.SessionStudentData student_data = 4;
   */
  studentData: SessionStudentData[] = [];

  constructor(data?: PartialMessage<SessionQuestionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.SessionQuestionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "question_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "student_data", kind: "message", T: SessionStudentData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SessionQuestionData {
    return new SessionQuestionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SessionQuestionData {
    return new SessionQuestionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SessionQuestionData {
    return new SessionQuestionData().fromJsonString(jsonString, options);
  }

  static equals(a: SessionQuestionData | PlainMessage<SessionQuestionData> | undefined, b: SessionQuestionData | PlainMessage<SessionQuestionData> | undefined): boolean {
    return proto3.util.equals(SessionQuestionData, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.SessionStudentData
 */
export class SessionStudentData extends Message<SessionStudentData> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * string student_name = 2;
   *
   * @generated from field: string clicker_id = 2;
   */
  clickerId = "";

  /**
   * @generated from field: string response = 3;
   */
  response = "";

  /**
   * @generated from field: bool is_correct = 4;
   */
  isCorrect = false;

  /**
   * @generated from field: float score = 5;
   */
  score = 0;

  constructor(data?: PartialMessage<SessionStudentData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.SessionStudentData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "clicker_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_correct", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SessionStudentData {
    return new SessionStudentData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SessionStudentData {
    return new SessionStudentData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SessionStudentData {
    return new SessionStudentData().fromJsonString(jsonString, options);
  }

  static equals(a: SessionStudentData | PlainMessage<SessionStudentData> | undefined, b: SessionStudentData | PlainMessage<SessionStudentData> | undefined): boolean {
    return proto3.util.equals(SessionStudentData, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetClassStudentListRequest
 */
export class GetClassStudentListRequest extends Message<GetClassStudentListRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 class_id = 2;
   */
  classId = 0;

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  constructor(data?: PartialMessage<GetClassStudentListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetClassStudentListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetClassStudentListRequest {
    return new GetClassStudentListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetClassStudentListRequest {
    return new GetClassStudentListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetClassStudentListRequest {
    return new GetClassStudentListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetClassStudentListRequest | PlainMessage<GetClassStudentListRequest> | undefined, b: GetClassStudentListRequest | PlainMessage<GetClassStudentListRequest> | undefined): boolean {
    return proto3.util.equals(GetClassStudentListRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetClassStudentListResponse
 */
export class GetClassStudentListResponse extends Message<GetClassStudentListResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.connected.apis.StudentList student_list_data = 4;
   */
  studentListData?: StudentList;

  constructor(data?: PartialMessage<GetClassStudentListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetClassStudentListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "student_list_data", kind: "message", T: StudentList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetClassStudentListResponse {
    return new GetClassStudentListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetClassStudentListResponse {
    return new GetClassStudentListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetClassStudentListResponse {
    return new GetClassStudentListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetClassStudentListResponse | PlainMessage<GetClassStudentListResponse> | undefined, b: GetClassStudentListResponse | PlainMessage<GetClassStudentListResponse> | undefined): boolean {
    return proto3.util.equals(GetClassStudentListResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.StudentList
 */
export class StudentList extends Message<StudentList> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 class_id = 2;
   */
  classId = 0;

  /**
   * @generated from field: string class = 3;
   */
  class = "";

  /**
   * @generated from field: int32 section_id = 4;
   */
  sectionId = 0;

  /**
   * @generated from field: string section = 5;
   */
  section = "";

  /**
   * @generated from field: repeated geneo.lms.connected.apis.StudentClickerData student_clicker_data = 6;
   */
  studentClickerData: StudentClickerData[] = [];

  constructor(data?: PartialMessage<StudentList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.StudentList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "class", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "student_clicker_data", kind: "message", T: StudentClickerData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentList {
    return new StudentList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentList {
    return new StudentList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentList {
    return new StudentList().fromJsonString(jsonString, options);
  }

  static equals(a: StudentList | PlainMessage<StudentList> | undefined, b: StudentList | PlainMessage<StudentList> | undefined): boolean {
    return proto3.util.equals(StudentList, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.StudentClickerData
 */
export class StudentClickerData extends Message<StudentClickerData> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: string student_name = 2;
   */
  studentName = "";

  /**
   * @generated from field: optional int32 roll_number = 3;
   */
  rollNumber?: number;

  /**
   * @generated from field: int64 clicker_id = 4;
   */
  clickerId = protoInt64.zero;

  /**
   * @generated from field: string clicker_serial_no = 5;
   */
  clickerSerialNo = "";

  /**
   * @generated from field: bool is_active = 6;
   */
  isActive = false;

  /**
   * @generated from field: optional string clicker_display_text = 7;
   */
  clickerDisplayText?: string;

  constructor(data?: PartialMessage<StudentClickerData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.StudentClickerData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "student_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "roll_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "clicker_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "clicker_serial_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "clicker_display_text", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentClickerData {
    return new StudentClickerData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentClickerData {
    return new StudentClickerData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentClickerData {
    return new StudentClickerData().fromJsonString(jsonString, options);
  }

  static equals(a: StudentClickerData | PlainMessage<StudentClickerData> | undefined, b: StudentClickerData | PlainMessage<StudentClickerData> | undefined): boolean {
    return proto3.util.equals(StudentClickerData, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpdateBaseStationStatusRequest
 */
export class UpdateBaseStationStatusRequest extends Message<UpdateBaseStationStatusRequest> {
  /**
   * @generated from field: string base_receiver_doc_id = 1;
   */
  baseReceiverDocId = "";

  /**
   * @generated from field: bool is_online = 2;
   */
  isOnline = false;

  constructor(data?: PartialMessage<UpdateBaseStationStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpdateBaseStationStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_receiver_doc_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_online", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBaseStationStatusRequest {
    return new UpdateBaseStationStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBaseStationStatusRequest {
    return new UpdateBaseStationStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBaseStationStatusRequest {
    return new UpdateBaseStationStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBaseStationStatusRequest | PlainMessage<UpdateBaseStationStatusRequest> | undefined, b: UpdateBaseStationStatusRequest | PlainMessage<UpdateBaseStationStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateBaseStationStatusRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpdateBaseStationStatusResponse
 */
export class UpdateBaseStationStatusResponse extends Message<UpdateBaseStationStatusResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<UpdateBaseStationStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpdateBaseStationStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBaseStationStatusResponse {
    return new UpdateBaseStationStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBaseStationStatusResponse {
    return new UpdateBaseStationStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBaseStationStatusResponse {
    return new UpdateBaseStationStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBaseStationStatusResponse | PlainMessage<UpdateBaseStationStatusResponse> | undefined, b: UpdateBaseStationStatusResponse | PlainMessage<UpdateBaseStationStatusResponse> | undefined): boolean {
    return proto3.util.equals(UpdateBaseStationStatusResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpdateStudentKeypadStatusRequest
 */
export class UpdateStudentKeypadStatusRequest extends Message<UpdateStudentKeypadStatusRequest> {
  /**
   * @generated from field: string base_receiver_doc_id = 1;
   */
  baseReceiverDocId = "";

  /**
   * @generated from field: string keypad_sn = 2;
   */
  keypadSn = "";

  /**
   * @generated from field: bool is_online = 3;
   */
  isOnline = false;

  constructor(data?: PartialMessage<UpdateStudentKeypadStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpdateStudentKeypadStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_receiver_doc_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "keypad_sn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_online", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentKeypadStatusRequest {
    return new UpdateStudentKeypadStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentKeypadStatusRequest {
    return new UpdateStudentKeypadStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentKeypadStatusRequest {
    return new UpdateStudentKeypadStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentKeypadStatusRequest | PlainMessage<UpdateStudentKeypadStatusRequest> | undefined, b: UpdateStudentKeypadStatusRequest | PlainMessage<UpdateStudentKeypadStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateStudentKeypadStatusRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpdateStudentKeypadStatusResponse
 */
export class UpdateStudentKeypadStatusResponse extends Message<UpdateStudentKeypadStatusResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<UpdateStudentKeypadStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpdateStudentKeypadStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentKeypadStatusResponse {
    return new UpdateStudentKeypadStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentKeypadStatusResponse {
    return new UpdateStudentKeypadStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentKeypadStatusResponse {
    return new UpdateStudentKeypadStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentKeypadStatusResponse | PlainMessage<UpdateStudentKeypadStatusResponse> | undefined, b: UpdateStudentKeypadStatusResponse | PlainMessage<UpdateStudentKeypadStatusResponse> | undefined): boolean {
    return proto3.util.equals(UpdateStudentKeypadStatusResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpdateStudentKeypadResponseRequest
 */
export class UpdateStudentKeypadResponseRequest extends Message<UpdateStudentKeypadResponseRequest> {
  /**
   * @generated from field: int64 connected_class_session_id = 1;
   */
  connectedClassSessionId = protoInt64.zero;

  /**
   * @generated from field: string base_receiver_doc_id = 2;
   */
  baseReceiverDocId = "";

  /**
   * @generated from field: string keypad_sn = 3;
   */
  keypadSn = "";

  /**
   * @generated from field: string response = 4;
   */
  response = "";

  /**
   * @generated from field: float time_spent = 5;
   */
  timeSpent = 0;

  /**
   * @generated from field: repeated geneo.lms.connected.apis.KeypadRes keypad_responses = 6;
   */
  keypadResponses: KeypadRes[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp last_update_request_time = 7;
   */
  lastUpdateRequestTime?: Timestamp;

  constructor(data?: PartialMessage<UpdateStudentKeypadResponseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpdateStudentKeypadResponseRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_class_session_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "base_receiver_doc_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "keypad_sn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "time_spent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "keypad_responses", kind: "message", T: KeypadRes, repeated: true },
    { no: 7, name: "last_update_request_time", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentKeypadResponseRequest {
    return new UpdateStudentKeypadResponseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentKeypadResponseRequest {
    return new UpdateStudentKeypadResponseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentKeypadResponseRequest {
    return new UpdateStudentKeypadResponseRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentKeypadResponseRequest | PlainMessage<UpdateStudentKeypadResponseRequest> | undefined, b: UpdateStudentKeypadResponseRequest | PlainMessage<UpdateStudentKeypadResponseRequest> | undefined): boolean {
    return proto3.util.equals(UpdateStudentKeypadResponseRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.KeypadRes
 */
export class KeypadRes extends Message<KeypadRes> {
  /**
   * @generated from field: string keypad_sn = 3;
   */
  keypadSn = "";

  /**
   * @generated from field: string response = 4;
   */
  response = "";

  /**
   * @generated from field: float time_spent = 5;
   */
  timeSpent = 0;

  constructor(data?: PartialMessage<KeypadRes>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.KeypadRes";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "keypad_sn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "time_spent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeypadRes {
    return new KeypadRes().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeypadRes {
    return new KeypadRes().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeypadRes {
    return new KeypadRes().fromJsonString(jsonString, options);
  }

  static equals(a: KeypadRes | PlainMessage<KeypadRes> | undefined, b: KeypadRes | PlainMessage<KeypadRes> | undefined): boolean {
    return proto3.util.equals(KeypadRes, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpdateStudentKeypadResponseResponse
 */
export class UpdateStudentKeypadResponseResponse extends Message<UpdateStudentKeypadResponseResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<UpdateStudentKeypadResponseResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpdateStudentKeypadResponseResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentKeypadResponseResponse {
    return new UpdateStudentKeypadResponseResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentKeypadResponseResponse {
    return new UpdateStudentKeypadResponseResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentKeypadResponseResponse {
    return new UpdateStudentKeypadResponseResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentKeypadResponseResponse | PlainMessage<UpdateStudentKeypadResponseResponse> | undefined, b: UpdateStudentKeypadResponseResponse | PlainMessage<UpdateStudentKeypadResponseResponse> | undefined): boolean {
    return proto3.util.equals(UpdateStudentKeypadResponseResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetBaseStationMappingDataRequest
 */
export class GetBaseStationMappingDataRequest extends Message<GetBaseStationMappingDataRequest> {
  /**
   * @generated from field: string base_station_serial_no = 1;
   */
  baseStationSerialNo = "";

  constructor(data?: PartialMessage<GetBaseStationMappingDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetBaseStationMappingDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_station_serial_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBaseStationMappingDataRequest {
    return new GetBaseStationMappingDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBaseStationMappingDataRequest {
    return new GetBaseStationMappingDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBaseStationMappingDataRequest {
    return new GetBaseStationMappingDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetBaseStationMappingDataRequest | PlainMessage<GetBaseStationMappingDataRequest> | undefined, b: GetBaseStationMappingDataRequest | PlainMessage<GetBaseStationMappingDataRequest> | undefined): boolean {
    return proto3.util.equals(GetBaseStationMappingDataRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.GetBaseStationMappingDataResponse
 */
export class GetBaseStationMappingDataResponse extends Message<GetBaseStationMappingDataResponse> {
  /**
   * @generated from field: string base_station_serial_no = 1;
   */
  baseStationSerialNo = "";

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: string school_name = 3;
   */
  schoolName = "";

  /**
   * @generated from field: repeated geneo.lms.connected.apis.ClassSectionInfo class_section_info = 4;
   */
  classSectionInfo: ClassSectionInfo[] = [];

  constructor(data?: PartialMessage<GetBaseStationMappingDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.GetBaseStationMappingDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_station_serial_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "class_section_info", kind: "message", T: ClassSectionInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBaseStationMappingDataResponse {
    return new GetBaseStationMappingDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBaseStationMappingDataResponse {
    return new GetBaseStationMappingDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBaseStationMappingDataResponse {
    return new GetBaseStationMappingDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetBaseStationMappingDataResponse | PlainMessage<GetBaseStationMappingDataResponse> | undefined, b: GetBaseStationMappingDataResponse | PlainMessage<GetBaseStationMappingDataResponse> | undefined): boolean {
    return proto3.util.equals(GetBaseStationMappingDataResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.ClassSectionInfo
 */
export class ClassSectionInfo extends Message<ClassSectionInfo> {
  /**
   * @generated from field: int32 class_id = 1;
   */
  classId = 0;

  /**
   * @generated from field: string class_name = 2;
   */
  className = "";

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: string section_name = 4;
   */
  sectionName = "";

  constructor(data?: PartialMessage<ClassSectionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.ClassSectionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "class_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSectionInfo {
    return new ClassSectionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSectionInfo {
    return new ClassSectionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSectionInfo {
    return new ClassSectionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSectionInfo | PlainMessage<ClassSectionInfo> | undefined, b: ClassSectionInfo | PlainMessage<ClassSectionInfo> | undefined): boolean {
    return proto3.util.equals(ClassSectionInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpdateBaseStationMappingRequest
 */
export class UpdateBaseStationMappingRequest extends Message<UpdateBaseStationMappingRequest> {
  /**
   * @generated from field: string base_station_serial_no = 1;
   */
  baseStationSerialNo = "";

  /**
   * @generated from field: int32 school_id = 2;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 class_id = 3;
   */
  classId = 0;

  /**
   * @generated from field: int32 section_id = 4;
   */
  sectionId = 0;

  constructor(data?: PartialMessage<UpdateBaseStationMappingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpdateBaseStationMappingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_station_serial_no", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBaseStationMappingRequest {
    return new UpdateBaseStationMappingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBaseStationMappingRequest {
    return new UpdateBaseStationMappingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBaseStationMappingRequest {
    return new UpdateBaseStationMappingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBaseStationMappingRequest | PlainMessage<UpdateBaseStationMappingRequest> | undefined, b: UpdateBaseStationMappingRequest | PlainMessage<UpdateBaseStationMappingRequest> | undefined): boolean {
    return proto3.util.equals(UpdateBaseStationMappingRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.connected.apis.UpdateBaseStationMappingResponse
 */
export class UpdateBaseStationMappingResponse extends Message<UpdateBaseStationMappingResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.lms.connected.apis.StudentClickerData student_data = 4;
   */
  studentData: StudentClickerData[] = [];

  constructor(data?: PartialMessage<UpdateBaseStationMappingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.connected.apis.UpdateBaseStationMappingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "student_data", kind: "message", T: StudentClickerData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateBaseStationMappingResponse {
    return new UpdateBaseStationMappingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateBaseStationMappingResponse {
    return new UpdateBaseStationMappingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateBaseStationMappingResponse {
    return new UpdateBaseStationMappingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateBaseStationMappingResponse | PlainMessage<UpdateBaseStationMappingResponse> | undefined, b: UpdateBaseStationMappingResponse | PlainMessage<UpdateBaseStationMappingResponse> | undefined): boolean {
    return proto3.util.equals(UpdateBaseStationMappingResponse, a, b);
  }
}

