import { checkAuthAndFetch } from '@geneo2-web/services-clients';
import { AudioType, ContentVideoContentModel, ExternalResourceType, ImageType, ResourceContent, VideoType } from '@protos/content_management/content.db_pb';
import { Buffer } from 'buffer';
import { MD5 } from 'crypto-js';
import { checkM3u8Type, findClosestStream } from './m3u8utils';
export function appendAndSortQueryParams(url: string, params: Record<string, string | number>): string {
  // Attempt to split the URL into path and query components
  let [path, queryString] = url.split('?');

  // Parse existing query parameters into a key-value map
  const queryParams: Record<string, string> = {};
  if (queryString) {
    queryString.split('&').forEach(param => {
      const [key, value] = param.split('=');
      queryParams[key] = value;
    });
  }

  // Add new parameters to the map
  Object.keys(params).forEach(key => {
    queryParams[key] = params[key].toString();
  });

  // Convert the map back into a sorted query string
  queryString = Object.keys(queryParams)
    .sort()
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');

  // Reconstruct the URL with the new sorted query string
  return queryString ? `${path}?${queryString}` : path;
}

export const cacheFetch = async (
  input: RequestInfo | URL,
  init?: RequestInit,
  resolution?: number
): Promise<Response> => {
  const cacheName = 'post-request-cache';
  const cache = await caches.open(cacheName);
  const request = new Request(input, init);
  const cacheKey = await generateCacheKey(request);
  try {
    const res = await checkAuthAndFetch()(input, init);
    if (res.ok) {
      // Clone the response immediately after fetching, before any other operation
      const clonedRes = res.clone();
      const clonedRes2 = res.clone();

      const expirationTime = Date.now() + (24 * 60 * 60 * 1000); // 24 hours in milliseconds
      let responseWithExpiration = new Response(clonedRes.body, {
        headers: {
          ...clonedRes.headers,
          'X-Cache-Expiration': expirationTime.toString(),
        },
      });

      if (request.url.includes('m3u8') && resolution) {
        const text = await clonedRes2.text(); // Use cloned response here
        const m3u8Type = checkM3u8Type(text);
        const closestStream = findClosestStream(text, resolution);
        if (m3u8Type === 'Multiple' && closestStream) {
          const filteredM3U8Content = filterM3U8Content(text, closestStream.videoFile);
          responseWithExpiration = new Response(filteredM3U8Content, {
            headers: {
              ...responseWithExpiration.headers,
              'X-Cache-Expiration': expirationTime.toString(),
            },
          });
        }
      }
      // It's safe to clone responseWithExpiration since its body hasn't been used yet
      const arrayBuffer = await responseWithExpiration.arrayBuffer();
      const { encryptedData, iv } = await encryptData(arrayBuffer);
      // const base64String = Buffer.from(arrayBuffer).toString('base64');
      // const base64Response = new Response(base64String, {
      //   headers: {
      //     ...responseWithExpiration.headers,
      //     // Ensure to set the Content-Type to 'application/octet-stream' for binary data
      //     'X-Content-Encoding': 'base64',
      //   },
      // });
      const encryptedResponse = new Response(encryptedData, {
        headers: {
          'X-Cache-IV': iv,
          'X-Content-Encoding': 'base64',
          'Content-Type': 'application/octet-stream',
        },
      });
      await cache.put(cacheKey, encryptedResponse);
    }
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export async function getKeyFromRawKey(rawKey: string) {
  const encodedKey = new TextEncoder().encode(rawKey);
  const hashBuffer = await crypto.subtle.digest('SHA-256', encodedKey);
  return hashBuffer.slice(0, 32); // Ensure the key is 32 bytes (256 bits)
}

async function encryptData(data: ArrayBuffer) {
  const password = (await getOfflineAccessKeyFromCache()) || 'geneo';
  const keyBuffer = await getKeyFromRawKey(password);
  // Import the key to be used with AES-CBC
  const key = await window.crypto.subtle.importKey(
    'raw', // raw format of the key - as a sequence of bytes
    keyBuffer,
    { name: 'AES-CBC' }, // Algorithm details
    false, // whether the key is extractable (i.e., can be exported)
    ['encrypt', 'decrypt'] // can be used to encrypt and decrypt
  );

  const iv = window.crypto.getRandomValues(new Uint8Array(16));
  const algorithm = { name: 'AES-CBC', iv };
  const encryptedData = await window.crypto.subtle.encrypt(algorithm, key, data);

  // Using Buffer to convert encryptedData and iv to base64 strings
  const encryptedDataBase64 = Buffer.from(encryptedData).toString('base64');
  const ivBase64 = Buffer.from(iv).toString('base64');

  return {
    encryptedData: encryptedDataBase64,
    iv: ivBase64
  };
}

function filterM3U8Content(m3u8Content: string, desiredFileName: string) {
  const lines = m3u8Content.split(/\r?\n/);
  const filteredLines = [];
  let keepNextLine = false;

  for (const line of lines) {
    if (line.startsWith('#EXT-X-MEDIA:') || line.startsWith('#EXTM3U')) {
      // Always keep these lines as they are essential for the M3U8 file structure
      filteredLines.push(line);
    } else if (line.startsWith('#EXT-X-STREAM-INF:')) {
      // The next line should be checked if it is the desired file name
      keepNextLine = true;
    } else if (keepNextLine) {
      if (line.endsWith(desiredFileName.toString())) {
        // Add the last #EXT-X-STREAM-INF line and the matching file name
        filteredLines.push(lines[filteredLines.length]); // Add the STREAM-INF line before the filename
        filteredLines.push(line); // Add the filename itself
      }
      keepNextLine = false; // Reset for the next iteration
    }
  }

  return filteredLines.join('\n');
}

export const useCacheFetch = () => cacheFetch

export const generateCacheKey = async (request: Request) => {
  const body = await request.clone().text();
  const accessKey = await getOfflineAccessKeyFromCache()
  // Hash the request body to use it as a part of the cache key
  const hash = await MD5(body).toString();
  const cacheUrl = new URL(request.url);
  cacheUrl.pathname = '/posts' + (accessKey ? '/' + accessKey : '') + cacheUrl.pathname + '/' + hash;
  // You can generate a unique cache key based on request data
  // For example, you can use the request URL or certain parts of the request body
  // Be careful to ensure the cache key is unique for different POST requests
  const cacheKey = new Request(cacheUrl.toString(), {
    headers: request.headers,
    method: "GET",
  });
  return cacheKey;
}



interface MediaObjects {
  images: ImageType[];
  videos: VideoType[];
  audios: AudioType[];
  contentVideos: string[];
  externalResources: ExternalResourceType[]
}

export function extractMediaObjects(data: ResourceContent): MediaObjects {
  const mediaObjects: MediaObjects = { images: [], videos: [], audios: [], contentVideos: [], externalResources: [] };
  function traverse(obj: any) {
    if (obj !== null && typeof obj === 'object') {
      Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (value instanceof ContentVideoContentModel && value.primaryVideoUrl) {
          mediaObjects.contentVideos.push(value.primaryVideoUrl);
        }
        if (value instanceof ImageType && value.imageUrl) {
          mediaObjects.images.push(value);
        }
        if (value instanceof VideoType && (value.videoUrl || value.thumbnailImageUrl)) {
          mediaObjects.videos.push(value);
        }
        if (value instanceof AudioType && (value.audioUrl || value.thumbnailImageUrl)) {
          mediaObjects.audios.push(value);
        }
        if (value instanceof ExternalResourceType && (value.fileUrl)) {
          mediaObjects.externalResources.push(value);
        }
        traverse(value);
      });
    }
  }
  traverse(data);
  return mediaObjects;
}


export async function storeOfflineAccessKeyInCache(key: string) {
  const cacheName = 'user-info';
  const cache = await caches.open(cacheName);

  // Create a synthetic request and response pair
  const request = new Request('/offline-access-key');
  const response = new Response(key, {
    headers: {
      'Content-Type': 'text/plain'
    }
  });

  await cache.put(request, response);
  console.log('key stored in cache:', key);
}

export async function getOfflineAccessKeyFromCache() {
  const cacheName = 'user-info';
  const cache = await caches.open(cacheName);
  const cachedResponse = await cache.match('/offline-access-key');

  if (cachedResponse) {
    const accessKey = await cachedResponse.text();
    return accessKey;
  } else {
    console.log('No access key found in cache.');
    return null;
  }
}

export async function deleteOfflineAccessKeyFromCache() {
  const cacheName = 'user-info';
  const cache = await caches.open(cacheName);
  const success = await cache.delete('/offline-access-key');

  if (success) {
    console.log('Access key deleted from cache.');
  } else {
    console.log('Access key not found in cache.');
  }
}
