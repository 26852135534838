import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { FileEnum } from '@protos/content_management/content.db_pb';
import React, { ReactNode } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import ImageWrapper from '../ImageWrapper';
interface FilePreviewPopupProps {
  children: ReactNode;
  onClose?: () => void;
  onDownload?: () => void;
  open: boolean; // Renamed prop 'open' to 'dialogOpen'
  fileName: string;
  fileType?: FileEnum;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FilePreviewPopup: React.FC<FilePreviewPopupProps> = ({
  children,
  onClose,
  onDownload,
  open, // Renamed prop 'open' to 'dialogOpen'
  fileName,
  fileType,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDownload = () => {
    onDownload && onDownload();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-paper': {
          width:
            fileType === FileEnum.FILE_TYPE_IMAGE
              ? 'max-content'
              : { xs: '90vw', md: '75vw' },
          maxWidth: 'none',
          maxHeight: 'auto',
          height: fileType === FileEnum.FILE_TYPE_IMAGE ? 'auto' : '80%',
          borderRadius: '10px',
          [theme.breakpoints.down('sm')]: {
            // Apply styles only for devices larger than mobile
            display: 'none', // Hide the dialog on mobile devices
          },
          position: 'initial',
          overflow: 'visible',
        },
        '& .MuiDialog-container': {
          position: 'relative',
        },
      }}
    >
      <IconButton
        style={{
          alignSelf: 'flex-start',
          position: 'absolute',
          top: '0',
          left: pxToRem(7),
          color: 'white',
        }}
        edge="start"
        onClick={onClose}
        aria-label="close"
      >
        <ArrowBackIcon />
        <Typography
          variant="h3"
          style={{
            marginLeft: pxTovW(8),
            display: 'inline-block',
            color: 'white',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '80vw',
            overflow: 'hidden',
          }}
        >
          {fileName}
        </Typography>
      </IconButton>
      {onDownload && (
        <IconButton
          style={{
            position: 'absolute',
            top: '0',
            right: '1rem',
            color: 'white',
          }}
          edge="end"
          color="inherit"
          onClick={handleDownload}
          aria-label="close"
        >
          <ImageWrapper
            name="downloaded-blue"
            type="png"
            parentFolder="icons"
            styles={{
              width: pxToRem(30),
              height: pxToRem(30),
              display: 'inline-block',
              marginRight: '8px',
            }}
          />
          {/* <img src=".././assets/icons/downloaded-blue.png" /> */}
        </IconButton>
      )}
      <DialogContent
        sx={{
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-moz-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          padding: '0',
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewPopup;
