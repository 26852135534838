// @generated by protoc-gen-connect-es v0.10.0 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.common.apis.proto (package geneo.lms.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateStudentResourceSessionRequest, CreateStudentResourceSessionResponse, CreateTeacherResourceSessionRequest, CreateTeacherResourceSessionResponse, fixSubjectiveQuestionTimeRequest, fixSubjectiveQuestionTimeResponse, GetLockStatusByIdsRequest, GetLockStatusByIdsResponse, GetResponseMarksFromEvaluatorRequest, GetResponseMarksFromEvaluatorResponse, ModuleLockInfoFetchRequest, ModuleLockInfoFetchResponse, RunLLMQuestionAdditionCronRequest, RunLLMQuestionAdditionCronResponse, RunLLMResponseEvaluationCronRequest, RunLLMResponseEvaluationCronResponse, RunLMSScriptRequest, RunLMSScriptResponse, SignedUrlForUploadRequest, SignedUrlReponse, SignedUrlRequest, StudentModuleLockInfoFetchRequest, TeacherContentLockUpdateRequest, TeacherContentLockUpdateResponse, UpdateStudentResourceSessionRequest, UpdateStudentResourceSessionResponse, UpdateTeacherResourceSessionRequest, UpdateTeacherResourceSessionResponse } from "./lms.common.apis_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service geneo.lms.common.apis.LmsCommonAPIServiceV1
 */
export const LmsCommonAPIServiceV1 = {
  typeName: "geneo.lms.common.apis.LmsCommonAPIServiceV1",
  methods: {
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.createTeacherResourceSession
     */
    createTeacherResourceSession: {
      name: "createTeacherResourceSession",
      I: CreateTeacherResourceSessionRequest,
      O: CreateTeacherResourceSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.updateTeacherResourceSession
     */
    updateTeacherResourceSession: {
      name: "updateTeacherResourceSession",
      I: UpdateTeacherResourceSessionRequest,
      O: UpdateTeacherResourceSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.createStudentResourceSession
     */
    createStudentResourceSession: {
      name: "createStudentResourceSession",
      I: CreateStudentResourceSessionRequest,
      O: CreateStudentResourceSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.updateStudentResourceSession
     */
    updateStudentResourceSession: {
      name: "updateStudentResourceSession",
      I: UpdateStudentResourceSessionRequest,
      O: UpdateStudentResourceSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.runLMSScript
     */
    runLMSScript: {
      name: "runLMSScript",
      I: RunLMSScriptRequest,
      O: RunLMSScriptResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.fixSubjectiveQuestionTime
     */
    fixSubjectiveQuestionTime: {
      name: "fixSubjectiveQuestionTime",
      I: fixSubjectiveQuestionTimeRequest,
      O: fixSubjectiveQuestionTimeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.getResponseMarksFromEvaluator
     */
    getResponseMarksFromEvaluator: {
      name: "getResponseMarksFromEvaluator",
      I: GetResponseMarksFromEvaluatorRequest,
      O: GetResponseMarksFromEvaluatorResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.getSignedUrlForFileUpload
     */
    getSignedUrlForFileUpload: {
      name: "getSignedUrlForFileUpload",
      I: SignedUrlRequest,
      O: SignedUrlReponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.runLLMResponseEvaluationCron
     */
    runLLMResponseEvaluationCron: {
      name: "runLLMResponseEvaluationCron",
      I: RunLLMResponseEvaluationCronRequest,
      O: RunLLMResponseEvaluationCronResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.runLLMQuestionAdditionCron
     */
    runLLMQuestionAdditionCron: {
      name: "runLLMQuestionAdditionCron",
      I: RunLLMQuestionAdditionCronRequest,
      O: RunLLMQuestionAdditionCronResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.teacherContentLockUpdate
     */
    teacherContentLockUpdate: {
      name: "teacherContentLockUpdate",
      I: TeacherContentLockUpdateRequest,
      O: TeacherContentLockUpdateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.moduleLockInfoFetch
     */
    moduleLockInfoFetch: {
      name: "moduleLockInfoFetch",
      I: ModuleLockInfoFetchRequest,
      O: ModuleLockInfoFetchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.studentModuleLockInfoFetch
     */
    studentModuleLockInfoFetch: {
      name: "studentModuleLockInfoFetch",
      I: StudentModuleLockInfoFetchRequest,
      O: ModuleLockInfoFetchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV1.getLockStatusByIds
     */
    getLockStatusByIds: {
      name: "getLockStatusByIds",
      I: GetLockStatusByIdsRequest,
      O: GetLockStatusByIdsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service geneo.lms.common.apis.LmsCommonAPIServiceV2
 */
export const LmsCommonAPIServiceV2 = {
  typeName: "geneo.lms.common.apis.LmsCommonAPIServiceV2",
  methods: {
    /**
     * @generated from rpc geneo.lms.common.apis.LmsCommonAPIServiceV2.getSignedUrlForFileUpload
     */
    getSignedUrlForFileUpload: {
      name: "getSignedUrlForFileUpload",
      I: SignedUrlForUploadRequest,
      O: SignedUrlReponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

