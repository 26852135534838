// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/user_management/ums.login.apis.proto (package geneo.ums.login.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Subject_SubjectEnum } from "@protos/common/common.db_pb";
import { FeaturePlanInfo } from "@protos/school_management/school.db_pb";
import { LoginTypeEnum, OTPEnum, ProfileCreationModeEnum, ProfileRolesEnum, ProfileVerificationStatusEnum, Remark } from "@protos/user_management/ums.db_pb";

/**
 * @generated from enum geneo.ums.login.apis.PasswordTypeEnum
 */
export enum PasswordTypeEnum {
  /**
   * @generated from enum value: PASSWORD_TYPE_UNDEFINED = 0;
   */
  PASSWORD_TYPE_UNDEFINED = 0,

  /**
   * @generated from enum value: PASSWORD_TYPE_SET = 1;
   */
  PASSWORD_TYPE_SET = 1,

  /**
   * @generated from enum value: PASSWORD_TYPE_UPDATE = 2;
   */
  PASSWORD_TYPE_UPDATE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PasswordTypeEnum)
proto3.util.setEnumType(PasswordTypeEnum, "geneo.ums.login.apis.PasswordTypeEnum", [
  { no: 0, name: "PASSWORD_TYPE_UNDEFINED" },
  { no: 1, name: "PASSWORD_TYPE_SET" },
  { no: 2, name: "PASSWORD_TYPE_UPDATE" },
]);

/**
 * @generated from message geneo.ums.login.apis.ProfilePicInfo
 */
export class ProfilePicInfo extends Message<ProfilePicInfo> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int32 size = 2;
   */
  size = 0;

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: string mime_type = 4;
   */
  mimeType = "";

  constructor(data?: PartialMessage<ProfilePicInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.ProfilePicInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfilePicInfo {
    return new ProfilePicInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfilePicInfo {
    return new ProfilePicInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfilePicInfo {
    return new ProfilePicInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ProfilePicInfo | PlainMessage<ProfilePicInfo> | undefined, b: ProfilePicInfo | PlainMessage<ProfilePicInfo> | undefined): boolean {
    return proto3.util.equals(ProfilePicInfo, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.CreateStudentProfileRequest
 */
export class CreateStudentProfileRequest extends Message<CreateStudentProfileRequest> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: optional string phone_number = 2;
   */
  phoneNumber?: string;

  /**
   * @generated from field: optional string phone_country = 3;
   */
  phoneCountry?: string;

  /**
   * @generated from field: optional string email = 4;
   */
  email?: string;

  /**
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: optional string last_name = 6;
   */
  lastName?: string;

  /**
   * @generated from field: optional string middle_name = 7;
   */
  middleName?: string;

  /**
   * Flag indicating if the profile is verified
   *
   * @generated from field: optional bool is_profile_verified = 30;
   */
  isProfileVerified?: boolean;

  /**
   * Flag indicating if the password is updated
   *
   * @generated from field: optional bool is_password_updated = 31;
   */
  isPasswordUpdated?: boolean;

  /**
   * @generated from field: optional int32 email_verify = 8;
   */
  emailVerify?: number;

  /**
   * @generated from field: optional int32 phone_verify = 9;
   */
  phoneVerify?: number;

  /**
   * @generated from field: string password = 29;
   */
  password = "";

  /**
   * @generated from field: optional int32 is_active = 10;
   */
  isActive?: number;

  /**
   * @generated from field: optional int32 is_delete = 11;
   */
  isDelete?: number;

  /**
   * @generated from field: int32 class_id = 12;
   */
  classId = 0;

  /**
   * @generated from field: int32 section_id = 13;
   */
  sectionId = 0;

  /**
   * @generated from field: int32 school_id = 14;
   */
  schoolId = 0;

  /**
   * @generated from field: int32 board_id = 15;
   */
  boardId = 0;

  /**
   * @generated from field: int32 region_id = 16;
   */
  regionId = 0;

  /**
   * @generated from field: int32 medium_id = 17;
   */
  mediumId = 0;

  /**
   * @generated from field: repeated geneo.ums.login.apis.ProfilePicInfo profile_pics = 18;
   */
  profilePics: ProfilePicInfo[] = [];

  /**
   * @generated from field: optional string address = 19;
   */
  address?: string;

  /**
   * @generated from field: optional string address_lat_long = 20;
   */
  addressLatLong?: string;

  /**
   * @generated from field: optional string address_city = 21;
   */
  addressCity?: string;

  /**
   * @generated from field: optional string address_state = 22;
   */
  addressState?: string;

  /**
   * @generated from field: optional string address_country = 23;
   */
  addressCountry?: string;

  /**
   * @generated from field: optional string address_zip_code = 24;
   */
  addressZipCode?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_on = 25;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 26;
   */
  createdBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 27;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 28;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<CreateStudentProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.CreateStudentProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "phone_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 31, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 29, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "is_delete", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "board_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "region_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "medium_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "profile_pics", kind: "message", T: ProfilePicInfo, repeated: true },
    { no: 19, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 26, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 27, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 28, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStudentProfileRequest {
    return new CreateStudentProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStudentProfileRequest {
    return new CreateStudentProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStudentProfileRequest {
    return new CreateStudentProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStudentProfileRequest | PlainMessage<CreateStudentProfileRequest> | undefined, b: CreateStudentProfileRequest | PlainMessage<CreateStudentProfileRequest> | undefined): boolean {
    return proto3.util.equals(CreateStudentProfileRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.StudentProfileFetchRequest
 */
export class StudentProfileFetchRequest extends Message<StudentProfileFetchRequest> {
  /**
   * @generated from field: int64 student_profile_id = 1;
   */
  studentProfileId = protoInt64.zero;

  constructor(data?: PartialMessage<StudentProfileFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.StudentProfileFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentProfileFetchRequest {
    return new StudentProfileFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentProfileFetchRequest {
    return new StudentProfileFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentProfileFetchRequest {
    return new StudentProfileFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StudentProfileFetchRequest | PlainMessage<StudentProfileFetchRequest> | undefined, b: StudentProfileFetchRequest | PlainMessage<StudentProfileFetchRequest> | undefined): boolean {
    return proto3.util.equals(StudentProfileFetchRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.UpdateStudentProfileRequest
 */
export class UpdateStudentProfileRequest extends Message<UpdateStudentProfileRequest> {
  /**
   * @generated from field: int64 student_profile_id = 29;
   */
  studentProfileId = protoInt64.zero;

  /**
   * @generated from field: optional string user_name = 1;
   */
  userName?: string;

  /**
   * @generated from field: optional string phone_number = 2;
   */
  phoneNumber?: string;

  /**
   * @generated from field: optional string phone_country = 3;
   */
  phoneCountry?: string;

  /**
   * @generated from field: optional string email = 4;
   */
  email?: string;

  /**
   * @generated from field: optional string first_name = 5;
   */
  firstName?: string;

  /**
   * @generated from field: optional string last_name = 6;
   */
  lastName?: string;

  /**
   * @generated from field: optional string middle_name = 7;
   */
  middleName?: string;

  /**
   * Flag indicating if the profile is verified
   *
   * @generated from field: optional bool is_profile_verified = 30;
   */
  isProfileVerified?: boolean;

  /**
   * Flag indicating if the password is updated
   *
   * @generated from field: optional bool is_password_updated = 31;
   */
  isPasswordUpdated?: boolean;

  /**
   * @generated from field: optional int32 email_verify = 8;
   */
  emailVerify?: number;

  /**
   * @generated from field: optional int32 phone_verify = 9;
   */
  phoneVerify?: number;

  /**
   * @generated from field: optional int32 is_active = 10;
   */
  isActive?: number;

  /**
   * @generated from field: optional int32 is_delete = 11;
   */
  isDelete?: number;

  /**
   * @generated from field: optional int32 class_id = 12;
   */
  classId?: number;

  /**
   * @generated from field: optional int32 section_id = 13;
   */
  sectionId?: number;

  /**
   * @generated from field: optional int32 school_id = 14;
   */
  schoolId?: number;

  /**
   * @generated from field: optional int32 board_id = 15;
   */
  boardId?: number;

  /**
   * @generated from field: optional int32 region_id = 16;
   */
  regionId?: number;

  /**
   * @generated from field: optional int32 medium_id = 17;
   */
  mediumId?: number;

  /**
   * @generated from field: repeated geneo.ums.login.apis.ProfilePicInfo profile_pics = 18;
   */
  profilePics: ProfilePicInfo[] = [];

  /**
   * @generated from field: optional string address = 19;
   */
  address?: string;

  /**
   * @generated from field: optional string address_lat_long = 20;
   */
  addressLatLong?: string;

  /**
   * @generated from field: optional string address_city = 21;
   */
  addressCity?: string;

  /**
   * @generated from field: optional string address_state = 22;
   */
  addressState?: string;

  /**
   * @generated from field: optional string address_country = 23;
   */
  addressCountry?: string;

  /**
   * @generated from field: optional string address_zip_code = 24;
   */
  addressZipCode?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_on = 25;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 26;
   */
  createdBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 27;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 28;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<UpdateStudentProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.UpdateStudentProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 29, name: "student_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "phone_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 31, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "is_delete", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 15, name: "board_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 16, name: "region_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 17, name: "medium_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 18, name: "profile_pics", kind: "message", T: ProfilePicInfo, repeated: true },
    { no: 19, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 24, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 25, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 26, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 27, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 28, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentProfileRequest {
    return new UpdateStudentProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentProfileRequest {
    return new UpdateStudentProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentProfileRequest {
    return new UpdateStudentProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentProfileRequest | PlainMessage<UpdateStudentProfileRequest> | undefined, b: UpdateStudentProfileRequest | PlainMessage<UpdateStudentProfileRequest> | undefined): boolean {
    return proto3.util.equals(UpdateStudentProfileRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.StudentProfileResponse
 */
export class StudentProfileResponse extends Message<StudentProfileResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.login.apis.CreateStudentProfileRequest data = 4;
   */
  data?: CreateStudentProfileRequest;

  constructor(data?: PartialMessage<StudentProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.StudentProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: CreateStudentProfileRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentProfileResponse {
    return new StudentProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentProfileResponse {
    return new StudentProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentProfileResponse {
    return new StudentProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentProfileResponse | PlainMessage<StudentProfileResponse> | undefined, b: StudentProfileResponse | PlainMessage<StudentProfileResponse> | undefined): boolean {
    return proto3.util.equals(StudentProfileResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.CreateTeacherProfileRequest
 */
export class CreateTeacherProfileRequest extends Message<CreateTeacherProfileRequest> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: optional string phone_number = 2;
   */
  phoneNumber?: string;

  /**
   * @generated from field: optional string phone_country = 3;
   */
  phoneCountry?: string;

  /**
   * @generated from field: optional string email = 4;
   */
  email?: string;

  /**
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: optional string last_name = 6;
   */
  lastName?: string;

  /**
   * @generated from field: optional string middle_name = 7;
   */
  middleName?: string;

  /**
   * Flag indicating if the profile is verified
   *
   * @generated from field: optional bool is_profile_verified = 29;
   */
  isProfileVerified?: boolean;

  /**
   * Flag indicating if the password is updated
   *
   * @generated from field: optional bool is_password_updated = 30;
   */
  isPasswordUpdated?: boolean;

  /**
   * @generated from field: optional int32 email_verify = 8;
   */
  emailVerify?: number;

  /**
   * @generated from field: optional int32 phone_verify = 9;
   */
  phoneVerify?: number;

  /**
   * @generated from field: string password = 28;
   */
  password = "";

  /**
   * @generated from field: optional int32 is_active = 10;
   */
  isActive?: number;

  /**
   * @generated from field: optional int32 is_delete = 11;
   */
  isDelete?: number;

  /**
   * @generated from field: int32 school_id = 12;
   */
  schoolId = 0;

  /**
   * @generated from field: repeated string qualification = 27;
   */
  qualification: string[] = [];

  /**
   * @generated from field: optional int32 experience = 13;
   */
  experience?: number;

  /**
   * @generated from field: repeated string teach_mediums = 14;
   */
  teachMediums: string[] = [];

  /**
   * @generated from field: repeated string languages = 15;
   */
  languages: string[] = [];

  /**
   * @generated from field: repeated geneo.ums.login.apis.ProfilePicInfo profile_pics = 16;
   */
  profilePics: ProfilePicInfo[] = [];

  /**
   * @generated from field: optional string address = 17;
   */
  address?: string;

  /**
   * @generated from field: optional string address_lat_long = 18;
   */
  addressLatLong?: string;

  /**
   * @generated from field: optional string address_city = 19;
   */
  addressCity?: string;

  /**
   * @generated from field: optional string address_state = 20;
   */
  addressState?: string;

  /**
   * @generated from field: optional string address_country = 21;
   */
  addressCountry?: string;

  /**
   * @generated from field: optional string address_zip_code = 22;
   */
  addressZipCode?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_on = 23;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 24;
   */
  createdBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 25;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 26;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<CreateTeacherProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.CreateTeacherProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "phone_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 30, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 28, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "is_delete", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 27, name: "qualification", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "experience", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "teach_mediums", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "languages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "profile_pics", kind: "message", T: ProfilePicInfo, repeated: true },
    { no: 17, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 24, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 25, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 26, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTeacherProfileRequest {
    return new CreateTeacherProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTeacherProfileRequest {
    return new CreateTeacherProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTeacherProfileRequest {
    return new CreateTeacherProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTeacherProfileRequest | PlainMessage<CreateTeacherProfileRequest> | undefined, b: CreateTeacherProfileRequest | PlainMessage<CreateTeacherProfileRequest> | undefined): boolean {
    return proto3.util.equals(CreateTeacherProfileRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.TeacherProfileResponse
 */
export class TeacherProfileResponse extends Message<TeacherProfileResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.login.apis.CreateTeacherProfileRequest data = 4;
   */
  data?: CreateTeacherProfileRequest;

  constructor(data?: PartialMessage<TeacherProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.TeacherProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: CreateTeacherProfileRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherProfileResponse {
    return new TeacherProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherProfileResponse {
    return new TeacherProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherProfileResponse {
    return new TeacherProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherProfileResponse | PlainMessage<TeacherProfileResponse> | undefined, b: TeacherProfileResponse | PlainMessage<TeacherProfileResponse> | undefined): boolean {
    return proto3.util.equals(TeacherProfileResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.FetchTeacherProfileRequest
 */
export class FetchTeacherProfileRequest extends Message<FetchTeacherProfileRequest> {
  /**
   * @generated from field: int64 teacher_profile_id = 1;
   */
  teacherProfileId = protoInt64.zero;

  constructor(data?: PartialMessage<FetchTeacherProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.FetchTeacherProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchTeacherProfileRequest {
    return new FetchTeacherProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchTeacherProfileRequest {
    return new FetchTeacherProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchTeacherProfileRequest {
    return new FetchTeacherProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchTeacherProfileRequest | PlainMessage<FetchTeacherProfileRequest> | undefined, b: FetchTeacherProfileRequest | PlainMessage<FetchTeacherProfileRequest> | undefined): boolean {
    return proto3.util.equals(FetchTeacherProfileRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.UpdateTeacherProfileRequest
 */
export class UpdateTeacherProfileRequest extends Message<UpdateTeacherProfileRequest> {
  /**
   * @generated from field: int64 teacher_profile_id = 28;
   */
  teacherProfileId = protoInt64.zero;

  /**
   * @generated from field: optional string user_name = 1;
   */
  userName?: string;

  /**
   * @generated from field: optional string phone_number = 2;
   */
  phoneNumber?: string;

  /**
   * @generated from field: optional string phone_country = 3;
   */
  phoneCountry?: string;

  /**
   * @generated from field: optional string email = 4;
   */
  email?: string;

  /**
   * @generated from field: optional string first_name = 5;
   */
  firstName?: string;

  /**
   * @generated from field: optional string last_name = 6;
   */
  lastName?: string;

  /**
   * @generated from field: optional string middle_name = 7;
   */
  middleName?: string;

  /**
   * Flag indicating if the profile is verified
   *
   * @generated from field: optional bool is_profile_verified = 29;
   */
  isProfileVerified?: boolean;

  /**
   * Flag indicating if the password is updated
   *
   * @generated from field: optional bool is_password_updated = 30;
   */
  isPasswordUpdated?: boolean;

  /**
   * @generated from field: optional int32 email_verify = 8;
   */
  emailVerify?: number;

  /**
   * @generated from field: optional int32 phone_verify = 9;
   */
  phoneVerify?: number;

  /**
   * @generated from field: optional int32 is_active = 10;
   */
  isActive?: number;

  /**
   * @generated from field: optional int32 is_delete = 11;
   */
  isDelete?: number;

  /**
   * @generated from field: optional int32 school_id = 12;
   */
  schoolId?: number;

  /**
   * @generated from field: repeated string qualification = 27;
   */
  qualification: string[] = [];

  /**
   * @generated from field: optional int32 experience = 13;
   */
  experience?: number;

  /**
   * @generated from field: repeated string teach_mediums = 14;
   */
  teachMediums: string[] = [];

  /**
   * @generated from field: repeated string languages = 15;
   */
  languages: string[] = [];

  /**
   * @generated from field: repeated geneo.ums.login.apis.ProfilePicInfo profile_pics = 16;
   */
  profilePics: ProfilePicInfo[] = [];

  /**
   * @generated from field: optional string address = 17;
   */
  address?: string;

  /**
   * @generated from field: optional string address_lat_long = 18;
   */
  addressLatLong?: string;

  /**
   * @generated from field: optional string address_city = 19;
   */
  addressCity?: string;

  /**
   * @generated from field: optional string address_state = 20;
   */
  addressState?: string;

  /**
   * @generated from field: optional string address_country = 21;
   */
  addressCountry?: string;

  /**
   * @generated from field: optional string address_zip_code = 22;
   */
  addressZipCode?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_on = 23;
   */
  createdOn?: Timestamp;

  /**
   * @generated from field: optional int64 created_by = 24;
   */
  createdBy?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp modified_on = 25;
   */
  modifiedOn?: Timestamp;

  /**
   * @generated from field: optional int64 modified_by = 26;
   */
  modifiedBy?: bigint;

  constructor(data?: PartialMessage<UpdateTeacherProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.UpdateTeacherProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 28, name: "teacher_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "phone_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 30, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "is_delete", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 27, name: "qualification", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "experience", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "teach_mediums", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "languages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "profile_pics", kind: "message", T: ProfilePicInfo, repeated: true },
    { no: 17, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 20, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 23, name: "created_on", kind: "message", T: Timestamp, opt: true },
    { no: 24, name: "created_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 25, name: "modified_on", kind: "message", T: Timestamp, opt: true },
    { no: 26, name: "modified_by", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTeacherProfileRequest {
    return new UpdateTeacherProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTeacherProfileRequest {
    return new UpdateTeacherProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTeacherProfileRequest {
    return new UpdateTeacherProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTeacherProfileRequest | PlainMessage<UpdateTeacherProfileRequest> | undefined, b: UpdateTeacherProfileRequest | PlainMessage<UpdateTeacherProfileRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTeacherProfileRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.UserLoginRequest
 */
export class UserLoginRequest extends Message<UserLoginRequest> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * string type = 3;
   *
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<UserLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.UserLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserLoginRequest {
    return new UserLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserLoginRequest {
    return new UserLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserLoginRequest {
    return new UserLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UserLoginRequest | PlainMessage<UserLoginRequest> | undefined, b: UserLoginRequest | PlainMessage<UserLoginRequest> | undefined): boolean {
    return proto3.util.equals(UserLoginRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.LoginWithPhonePasswordRequest
 */
export class LoginWithPhonePasswordRequest extends Message<LoginWithPhonePasswordRequest> {
  /**
   * @generated from field: string phone_number = 1;
   */
  phoneNumber = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<LoginWithPhonePasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.LoginWithPhonePasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginWithPhonePasswordRequest {
    return new LoginWithPhonePasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginWithPhonePasswordRequest {
    return new LoginWithPhonePasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginWithPhonePasswordRequest {
    return new LoginWithPhonePasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LoginWithPhonePasswordRequest | PlainMessage<LoginWithPhonePasswordRequest> | undefined, b: LoginWithPhonePasswordRequest | PlainMessage<LoginWithPhonePasswordRequest> | undefined): boolean {
    return proto3.util.equals(LoginWithPhonePasswordRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.LoginWithEmailPasswordRequest
 */
export class LoginWithEmailPasswordRequest extends Message<LoginWithEmailPasswordRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<LoginWithEmailPasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.LoginWithEmailPasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginWithEmailPasswordRequest {
    return new LoginWithEmailPasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginWithEmailPasswordRequest {
    return new LoginWithEmailPasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginWithEmailPasswordRequest {
    return new LoginWithEmailPasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LoginWithEmailPasswordRequest | PlainMessage<LoginWithEmailPasswordRequest> | undefined, b: LoginWithEmailPasswordRequest | PlainMessage<LoginWithEmailPasswordRequest> | undefined): boolean {
    return proto3.util.equals(LoginWithEmailPasswordRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.StudentLoginWithPhoneOrEmailPasswordResponse
 */
export class StudentLoginWithPhoneOrEmailPasswordResponse extends Message<StudentLoginWithPhoneOrEmailPasswordResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.ums.login.apis.StudentLoginResponseType data = 4;
   */
  data: StudentLoginResponseType[] = [];

  /**
   * @generated from field: int32 active_session_id = 5;
   */
  activeSessionId = 0;

  constructor(data?: PartialMessage<StudentLoginWithPhoneOrEmailPasswordResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.StudentLoginWithPhoneOrEmailPasswordResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: StudentLoginResponseType, repeated: true },
    { no: 5, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentLoginWithPhoneOrEmailPasswordResponse {
    return new StudentLoginWithPhoneOrEmailPasswordResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentLoginWithPhoneOrEmailPasswordResponse {
    return new StudentLoginWithPhoneOrEmailPasswordResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentLoginWithPhoneOrEmailPasswordResponse {
    return new StudentLoginWithPhoneOrEmailPasswordResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentLoginWithPhoneOrEmailPasswordResponse | PlainMessage<StudentLoginWithPhoneOrEmailPasswordResponse> | undefined, b: StudentLoginWithPhoneOrEmailPasswordResponse | PlainMessage<StudentLoginWithPhoneOrEmailPasswordResponse> | undefined): boolean {
    return proto3.util.equals(StudentLoginWithPhoneOrEmailPasswordResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.TeacherLoginWithPhoneOrEmailPasswordResponse
 */
export class TeacherLoginWithPhoneOrEmailPasswordResponse extends Message<TeacherLoginWithPhoneOrEmailPasswordResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.ums.login.apis.TeacherLoginResponseType data = 4;
   */
  data: TeacherLoginResponseType[] = [];

  /**
   * @generated from field: int32 active_session_id = 5;
   */
  activeSessionId = 0;

  constructor(data?: PartialMessage<TeacherLoginWithPhoneOrEmailPasswordResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.TeacherLoginWithPhoneOrEmailPasswordResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherLoginResponseType, repeated: true },
    { no: 5, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherLoginWithPhoneOrEmailPasswordResponse {
    return new TeacherLoginWithPhoneOrEmailPasswordResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherLoginWithPhoneOrEmailPasswordResponse {
    return new TeacherLoginWithPhoneOrEmailPasswordResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherLoginWithPhoneOrEmailPasswordResponse {
    return new TeacherLoginWithPhoneOrEmailPasswordResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherLoginWithPhoneOrEmailPasswordResponse | PlainMessage<TeacherLoginWithPhoneOrEmailPasswordResponse> | undefined, b: TeacherLoginWithPhoneOrEmailPasswordResponse | PlainMessage<TeacherLoginWithPhoneOrEmailPasswordResponse> | undefined): boolean {
    return proto3.util.equals(TeacherLoginWithPhoneOrEmailPasswordResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.SubjectList
 */
export class SubjectList extends Message<SubjectList> {
  /**
   * @generated from field: int32 subject_id = 1;
   */
  subjectId = 0;

  /**
   * @generated from field: string subject_name = 2;
   */
  subjectName = "";

  /**
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject_enum = 3;
   */
  subjectEnum = Subject_SubjectEnum.UNDEFINED;

  /**
   * string bg_color = 3 [(google.api.field_behavior) = REQUIRED];
   * string border_color = 4 [(google.api.field_behavior) = REQUIRED];
   *
   * @generated from field: string text_color = 4;
   */
  textColor = "";

  /**
   * @generated from field: string icon_url = 5;
   */
  iconUrl = "";

  /**
   * @generated from field: int32 bookId = 6;
   */
  bookId = 0;

  /**
   * bgColor: "#FFF3C3" , borderColor: "#F8C807"  ,  textColor: "#DCB000", subjectName:"english", subjectId: 1, iconUrl: ""}
   *
   * @generated from field: int32 school_course_id = 7;
   */
  schoolCourseId = 0;

  constructor(data?: PartialMessage<SubjectList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.SubjectList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "subject_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "subject_enum", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 4, name: "text_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "bookId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "school_course_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubjectList {
    return new SubjectList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubjectList {
    return new SubjectList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubjectList {
    return new SubjectList().fromJsonString(jsonString, options);
  }

  static equals(a: SubjectList | PlainMessage<SubjectList> | undefined, b: SubjectList | PlainMessage<SubjectList> | undefined): boolean {
    return proto3.util.equals(SubjectList, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.TeachClassSubjects
 */
export class TeachClassSubjects extends Message<TeachClassSubjects> {
  /**
   * @generated from field: int32 class_id = 1;
   */
  classId = 0;

  /**
   * @generated from field: string class_name = 2;
   */
  className = "";

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: string section_name = 4;
   */
  sectionName = "";

  /**
   * @generated from field: repeated geneo.ums.login.apis.SubjectList subjects = 5;
   */
  subjects: SubjectList[] = [];

  constructor(data?: PartialMessage<TeachClassSubjects>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.TeachClassSubjects";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "class_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subjects", kind: "message", T: SubjectList, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeachClassSubjects {
    return new TeachClassSubjects().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeachClassSubjects {
    return new TeachClassSubjects().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeachClassSubjects {
    return new TeachClassSubjects().fromJsonString(jsonString, options);
  }

  static equals(a: TeachClassSubjects | PlainMessage<TeachClassSubjects> | undefined, b: TeachClassSubjects | PlainMessage<TeachClassSubjects> | undefined): boolean {
    return proto3.util.equals(TeachClassSubjects, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.SchoolBoardMediumInfo
 */
export class SchoolBoardMediumInfo extends Message<SchoolBoardMediumInfo> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: string school_name = 2;
   */
  schoolName = "";

  /**
   * @generated from field: optional string school_code = 3;
   */
  schoolCode?: string;

  /**
   * @generated from field: repeated geneo.ums.login.apis.BoardMediumInfo board_medium_info = 4;
   */
  boardMediumInfo: BoardMediumInfo[] = [];

  /**
   * @generated from field: geneo.school.db.FeaturePlanInfo features_plan_info = 5;
   */
  featuresPlanInfo?: FeaturePlanInfo;

  constructor(data?: PartialMessage<SchoolBoardMediumInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.SchoolBoardMediumInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "board_medium_info", kind: "message", T: BoardMediumInfo, repeated: true },
    { no: 5, name: "features_plan_info", kind: "message", T: FeaturePlanInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolBoardMediumInfo {
    return new SchoolBoardMediumInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolBoardMediumInfo {
    return new SchoolBoardMediumInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolBoardMediumInfo {
    return new SchoolBoardMediumInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolBoardMediumInfo | PlainMessage<SchoolBoardMediumInfo> | undefined, b: SchoolBoardMediumInfo | PlainMessage<SchoolBoardMediumInfo> | undefined): boolean {
    return proto3.util.equals(SchoolBoardMediumInfo, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.TeacherLoginResponseType
 */
export class TeacherLoginResponseType extends Message<TeacherLoginResponseType> {
  /**
   * @generated from field: int64 teacher_profile_id = 30;
   */
  teacherProfileId = protoInt64.zero;

  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: string phone_number = 2;
   */
  phoneNumber = "";

  /**
   * @generated from field: string phone_country = 3;
   */
  phoneCountry = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 6;
   */
  lastName = "";

  /**
   * @generated from field: string middle_name = 7;
   */
  middleName = "";

  /**
   * Flag indicating if the profile is verified
   *
   * @generated from field: bool is_profile_verified = 31;
   */
  isProfileVerified = false;

  /**
   * Flag indicating if the password is updated
   *
   * @generated from field: bool is_password_updated = 32;
   */
  isPasswordUpdated = false;

  /**
   * @generated from field: int32 email_verify = 8;
   */
  emailVerify = 0;

  /**
   * @generated from field: int32 phone_verify = 9;
   */
  phoneVerify = 0;

  /**
   * @generated from field: int32 is_active = 10;
   */
  isActive = 0;

  /**
   * @generated from field: int32 is_delete = 11;
   */
  isDelete = 0;

  /**
   * @generated from field: repeated geneo.ums.login.apis.SchoolBoardMediumInfo school_details = 12;
   */
  schoolDetails: SchoolBoardMediumInfo[] = [];

  /**
   * @generated from field: repeated string qualification = 27;
   */
  qualification: string[] = [];

  /**
   * @generated from field: int32 experience = 13;
   */
  experience = 0;

  /**
   * @generated from field: repeated string teach_mediums = 14;
   */
  teachMediums: string[] = [];

  /**
   * @generated from field: repeated string languages = 15;
   */
  languages: string[] = [];

  /**
   * @generated from field: repeated geneo.ums.login.apis.ProfilePicInfo profile_pics = 16;
   */
  profilePics: ProfilePicInfo[] = [];

  /**
   * @generated from field: string address = 17;
   */
  address = "";

  /**
   * @generated from field: string address_lat_long = 18;
   */
  addressLatLong = "";

  /**
   * @generated from field: string address_city = 19;
   */
  addressCity = "";

  /**
   * @generated from field: string address_state = 20;
   */
  addressState = "";

  /**
   * @generated from field: string address_country = 21;
   */
  addressCountry = "";

  /**
   * @generated from field: string address_zip_code = 22;
   */
  addressZipCode = "";

  /**
   * google.protobuf.Timestamp created_on = 23;
   * int64 created_by = 24;
   * google.protobuf.Timestamp modified_on = 25;
   * int64 modified_by = 26;
   *
   * @generated from field: repeated geneo.ums.login.apis.TeachClassSubjects teach_class_subjects = 28;
   */
  teachClassSubjects: TeachClassSubjects[] = [];

  /**
   * @generated from field: string token = 29;
   */
  token = "";

  /**
   * @generated from field: bool is_sso_user = 33;
   */
  isSsoUser = false;

  constructor(data?: PartialMessage<TeacherLoginResponseType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.TeacherLoginResponseType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 30, name: "teacher_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "is_delete", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "school_details", kind: "message", T: SchoolBoardMediumInfo, repeated: true },
    { no: 27, name: "qualification", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "experience", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "teach_mediums", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "languages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "profile_pics", kind: "message", T: ProfilePicInfo, repeated: true },
    { no: 17, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "teach_class_subjects", kind: "message", T: TeachClassSubjects, repeated: true },
    { no: 29, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "is_sso_user", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherLoginResponseType {
    return new TeacherLoginResponseType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherLoginResponseType {
    return new TeacherLoginResponseType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherLoginResponseType {
    return new TeacherLoginResponseType().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherLoginResponseType | PlainMessage<TeacherLoginResponseType> | undefined, b: TeacherLoginResponseType | PlainMessage<TeacherLoginResponseType> | undefined): boolean {
    return proto3.util.equals(TeacherLoginResponseType, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.TeacherLoginResponse
 */
export class TeacherLoginResponse extends Message<TeacherLoginResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.login.apis.TeacherLoginResponseType data = 4;
   */
  data?: TeacherLoginResponseType;

  /**
   * @generated from field: int32 active_session_id = 5;
   */
  activeSessionId = 0;

  constructor(data?: PartialMessage<TeacherLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.TeacherLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: TeacherLoginResponseType },
    { no: 5, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherLoginResponse {
    return new TeacherLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherLoginResponse {
    return new TeacherLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherLoginResponse {
    return new TeacherLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherLoginResponse | PlainMessage<TeacherLoginResponse> | undefined, b: TeacherLoginResponse | PlainMessage<TeacherLoginResponse> | undefined): boolean {
    return proto3.util.equals(TeacherLoginResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.SchoolInfo
 */
export class SchoolInfo extends Message<SchoolInfo> {
  /**
   * @generated from field: int32 school_id = 1;
   */
  schoolId = 0;

  /**
   * @generated from field: string school_name = 2;
   */
  schoolName = "";

  /**
   * @generated from field: geneo.school.db.FeaturePlanInfo features_plan_info = 3;
   */
  featuresPlanInfo?: FeaturePlanInfo;

  constructor(data?: PartialMessage<SchoolInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.SchoolInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "school_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "features_plan_info", kind: "message", T: FeaturePlanInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SchoolInfo {
    return new SchoolInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SchoolInfo {
    return new SchoolInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SchoolInfo {
    return new SchoolInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SchoolInfo | PlainMessage<SchoolInfo> | undefined, b: SchoolInfo | PlainMessage<SchoolInfo> | undefined): boolean {
    return proto3.util.equals(SchoolInfo, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.ClassSectionInfo
 */
export class ClassSectionInfo extends Message<ClassSectionInfo> {
  /**
   * @generated from field: int32 class_id = 1;
   */
  classId = 0;

  /**
   * @generated from field: string class_name = 2;
   */
  className = "";

  /**
   * @generated from field: int32 section_id = 3;
   */
  sectionId = 0;

  /**
   * @generated from field: string section_name = 4;
   */
  sectionName = "";

  constructor(data?: PartialMessage<ClassSectionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.ClassSectionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "class_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "section_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClassSectionInfo {
    return new ClassSectionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClassSectionInfo {
    return new ClassSectionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClassSectionInfo {
    return new ClassSectionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ClassSectionInfo | PlainMessage<ClassSectionInfo> | undefined, b: ClassSectionInfo | PlainMessage<ClassSectionInfo> | undefined): boolean {
    return proto3.util.equals(ClassSectionInfo, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.BoardMediumInfo
 */
export class BoardMediumInfo extends Message<BoardMediumInfo> {
  /**
   * @generated from field: int32 board_id = 1;
   */
  boardId = 0;

  /**
   * @generated from field: string board_name = 2;
   */
  boardName = "";

  /**
   * @generated from field: int32 medium_id = 3;
   */
  mediumId = 0;

  /**
   * @generated from field: string medium_name = 4;
   */
  mediumName = "";

  constructor(data?: PartialMessage<BoardMediumInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.BoardMediumInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "board_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "board_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "medium_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "medium_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BoardMediumInfo {
    return new BoardMediumInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BoardMediumInfo {
    return new BoardMediumInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BoardMediumInfo {
    return new BoardMediumInfo().fromJsonString(jsonString, options);
  }

  static equals(a: BoardMediumInfo | PlainMessage<BoardMediumInfo> | undefined, b: BoardMediumInfo | PlainMessage<BoardMediumInfo> | undefined): boolean {
    return proto3.util.equals(BoardMediumInfo, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.StudentLoginResponseType
 */
export class StudentLoginResponseType extends Message<StudentLoginResponseType> {
  /**
   * @generated from field: int64 student_profile_id = 31;
   */
  studentProfileId = protoInt64.zero;

  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: string phone_number = 2;
   */
  phoneNumber = "";

  /**
   * @generated from field: string phone_country = 3;
   */
  phoneCountry = "";

  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 6;
   */
  lastName = "";

  /**
   * @generated from field: string middle_name = 7;
   */
  middleName = "";

  /**
   * Flag indicating if the profile is verified
   *
   * @generated from field: bool is_profile_verified = 32;
   */
  isProfileVerified = false;

  /**
   * Flag indicating if the password is updated
   *
   * @generated from field: bool is_password_updated = 33;
   */
  isPasswordUpdated = false;

  /**
   * @generated from field: int32 email_verify = 8;
   */
  emailVerify = 0;

  /**
   * @generated from field: int32 phone_verify = 9;
   */
  phoneVerify = 0;

  /**
   * @generated from field: int32 is_active = 10;
   */
  isActive = 0;

  /**
   * @generated from field: int32 is_delete = 11;
   */
  isDelete = 0;

  /**
   * @generated from field: geneo.ums.login.apis.SchoolInfo school_details = 12;
   */
  schoolDetails?: SchoolInfo;

  /**
   * int64 section_id = 13 [(google.api.field_behavior) = REQUIRED];
   *
   * @generated from field: geneo.ums.login.apis.ClassSectionInfo class_section_details = 14;
   */
  classSectionDetails?: ClassSectionInfo;

  /**
   * @generated from field: geneo.ums.login.apis.BoardMediumInfo board_medium_details = 15;
   */
  boardMediumDetails?: BoardMediumInfo;

  /**
   * @generated from field: int32 region_id = 16;
   */
  regionId = 0;

  /**
   * int64 medium_id = 17 [(google.api.field_behavior) = REQUIRED];
   *
   * @generated from field: repeated geneo.ums.login.apis.ProfilePicInfo profile_pics = 18;
   */
  profilePics: ProfilePicInfo[] = [];

  /**
   * @generated from field: string address = 19;
   */
  address = "";

  /**
   * @generated from field: string address_lat_long = 20;
   */
  addressLatLong = "";

  /**
   * @generated from field: string address_city = 21;
   */
  addressCity = "";

  /**
   * @generated from field: string address_state = 22;
   */
  addressState = "";

  /**
   * @generated from field: string address_country = 23;
   */
  addressCountry = "";

  /**
   * @generated from field: string address_zip_code = 24;
   */
  addressZipCode = "";

  /**
   * google.protobuf.Timestamp created_on = 25;
   * int64 created_by = 26;
   * google.protobuf.Timestamp modified_on = 27;
   * int64 modified_by = 28;
   *
   * @generated from field: repeated geneo.ums.login.apis.SubjectList learn_subjects = 29;
   */
  learnSubjects: SubjectList[] = [];

  /**
   * @generated from field: string token = 30;
   */
  token = "";

  /**
   * @generated from field: bool is_sso_user = 34;
   */
  isSsoUser = false;

  constructor(data?: PartialMessage<StudentLoginResponseType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.StudentLoginResponseType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 31, name: "student_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 33, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "is_delete", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "school_details", kind: "message", T: SchoolInfo },
    { no: 14, name: "class_section_details", kind: "message", T: ClassSectionInfo },
    { no: 15, name: "board_medium_details", kind: "message", T: BoardMediumInfo },
    { no: 16, name: "region_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "profile_pics", kind: "message", T: ProfilePicInfo, repeated: true },
    { no: 19, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "address_lat_long", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "address_city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "address_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "address_country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "address_zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "learn_subjects", kind: "message", T: SubjectList, repeated: true },
    { no: 30, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "is_sso_user", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentLoginResponseType {
    return new StudentLoginResponseType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentLoginResponseType {
    return new StudentLoginResponseType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentLoginResponseType {
    return new StudentLoginResponseType().fromJsonString(jsonString, options);
  }

  static equals(a: StudentLoginResponseType | PlainMessage<StudentLoginResponseType> | undefined, b: StudentLoginResponseType | PlainMessage<StudentLoginResponseType> | undefined): boolean {
    return proto3.util.equals(StudentLoginResponseType, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.StudentLoginResponse
 */
export class StudentLoginResponse extends Message<StudentLoginResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.login.apis.StudentLoginResponseType data = 4;
   */
  data?: StudentLoginResponseType;

  /**
   * @generated from field: int32 active_session_id = 5;
   */
  activeSessionId = 0;

  constructor(data?: PartialMessage<StudentLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.StudentLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: StudentLoginResponseType },
    { no: 5, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentLoginResponse {
    return new StudentLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentLoginResponse {
    return new StudentLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentLoginResponse {
    return new StudentLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StudentLoginResponse | PlainMessage<StudentLoginResponse> | undefined, b: StudentLoginResponse | PlainMessage<StudentLoginResponse> | undefined): boolean {
    return proto3.util.equals(StudentLoginResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.GetPhoneOTPRequest
 */
export class GetPhoneOTPRequest extends Message<GetPhoneOTPRequest> {
  /**
   * @generated from field: string phone_number = 1;
   */
  phoneNumber = "";

  /**
   * @generated from field: geneo.ums.db.OTPEnum otp_type = 2;
   */
  otpType = OTPEnum.OTP_TYPE_UNDEFINED;

  /**
   * @generated from field: optional string user_name = 3;
   */
  userName?: string;

  constructor(data?: PartialMessage<GetPhoneOTPRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.GetPhoneOTPRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "otp_type", kind: "enum", T: proto3.getEnumType(OTPEnum) },
    { no: 3, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPhoneOTPRequest {
    return new GetPhoneOTPRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPhoneOTPRequest {
    return new GetPhoneOTPRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPhoneOTPRequest {
    return new GetPhoneOTPRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPhoneOTPRequest | PlainMessage<GetPhoneOTPRequest> | undefined, b: GetPhoneOTPRequest | PlainMessage<GetPhoneOTPRequest> | undefined): boolean {
    return proto3.util.equals(GetPhoneOTPRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.GetEmailOTPRequest
 */
export class GetEmailOTPRequest extends Message<GetEmailOTPRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: geneo.ums.db.OTPEnum otp_type = 2;
   */
  otpType = OTPEnum.OTP_TYPE_UNDEFINED;

  /**
   * @generated from field: optional string user_name = 3;
   */
  userName?: string;

  constructor(data?: PartialMessage<GetEmailOTPRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.GetEmailOTPRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "otp_type", kind: "enum", T: proto3.getEnumType(OTPEnum) },
    { no: 3, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEmailOTPRequest {
    return new GetEmailOTPRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEmailOTPRequest {
    return new GetEmailOTPRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetEmailOTPRequest {
    return new GetEmailOTPRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetEmailOTPRequest | PlainMessage<GetEmailOTPRequest> | undefined, b: GetEmailOTPRequest | PlainMessage<GetEmailOTPRequest> | undefined): boolean {
    return proto3.util.equals(GetEmailOTPRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.GetOTPResponse
 */
export class GetOTPResponse extends Message<GetOTPResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: string verification_code = 4;
   */
  verificationCode = "";

  constructor(data?: PartialMessage<GetOTPResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.GetOTPResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "verification_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOTPResponse {
    return new GetOTPResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOTPResponse {
    return new GetOTPResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOTPResponse {
    return new GetOTPResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOTPResponse | PlainMessage<GetOTPResponse> | undefined, b: GetOTPResponse | PlainMessage<GetOTPResponse> | undefined): boolean {
    return proto3.util.equals(GetOTPResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.VerifyOTPRequest
 */
export class VerifyOTPRequest extends Message<VerifyOTPRequest> {
  /**
   * @generated from field: string otp = 1;
   */
  otp = "";

  /**
   * @generated from field: string verification_code = 2;
   */
  verificationCode = "";

  /**
   * It can be mobile number or email
   *
   * @generated from field: string check = 3;
   */
  check = "";

  constructor(data?: PartialMessage<VerifyOTPRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.VerifyOTPRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "otp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "verification_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "check", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyOTPRequest {
    return new VerifyOTPRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyOTPRequest {
    return new VerifyOTPRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyOTPRequest {
    return new VerifyOTPRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyOTPRequest | PlainMessage<VerifyOTPRequest> | undefined, b: VerifyOTPRequest | PlainMessage<VerifyOTPRequest> | undefined): boolean {
    return proto3.util.equals(VerifyOTPRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.OTPProfileResponse
 */
export class OTPProfileResponse extends Message<OTPProfileResponse> {
  /**
   * @generated from field: repeated geneo.ums.login.apis.StudentLoginResponseType student_profiles = 1;
   */
  studentProfiles: StudentLoginResponseType[] = [];

  /**
   * @generated from field: repeated geneo.ums.login.apis.TeacherLoginResponseType teacher_profiles = 2;
   */
  teacherProfiles: TeacherLoginResponseType[] = [];

  /**
   * @generated from field: string token = 3;
   */
  token = "";

  constructor(data?: PartialMessage<OTPProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.OTPProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_profiles", kind: "message", T: StudentLoginResponseType, repeated: true },
    { no: 2, name: "teacher_profiles", kind: "message", T: TeacherLoginResponseType, repeated: true },
    { no: 3, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OTPProfileResponse {
    return new OTPProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OTPProfileResponse {
    return new OTPProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OTPProfileResponse {
    return new OTPProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OTPProfileResponse | PlainMessage<OTPProfileResponse> | undefined, b: OTPProfileResponse | PlainMessage<OTPProfileResponse> | undefined): boolean {
    return proto3.util.equals(OTPProfileResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.VerifyOTPResponse
 */
export class VerifyOTPResponse extends Message<VerifyOTPResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * oneof data {
   *   StudentProfileInfo student_profile_info = 4;
   *   TeacherProfileInfo teacher_profile_info = 5;
   * };
   *
   * @generated from field: geneo.ums.login.apis.OTPProfileResponse data = 4;
   */
  data?: OTPProfileResponse;

  /**
   * @generated from field: optional int32 active_session_id = 5;
   */
  activeSessionId?: number;

  constructor(data?: PartialMessage<VerifyOTPResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.VerifyOTPResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: OTPProfileResponse },
    { no: 5, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyOTPResponse {
    return new VerifyOTPResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyOTPResponse {
    return new VerifyOTPResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyOTPResponse {
    return new VerifyOTPResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyOTPResponse | PlainMessage<VerifyOTPResponse> | undefined, b: VerifyOTPResponse | PlainMessage<VerifyOTPResponse> | undefined): boolean {
    return proto3.util.equals(VerifyOTPResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.ResetPasswordInitRequest
 */
export class ResetPasswordInitRequest extends Message<ResetPasswordInitRequest> {
  /**
   * @generated from field: string user_login_input = 1;
   */
  userLoginInput = "";

  /**
   * @generated from field: geneo.ums.db.LoginTypeEnum login_type = 2;
   */
  loginType = LoginTypeEnum.LOGIN_TYPE_UNDEFINED;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 3;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: geneo.ums.db.OTPEnum otp_type = 4;
   */
  otpType = OTPEnum.OTP_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<ResetPasswordInitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.ResetPasswordInitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_login_input", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "login_type", kind: "enum", T: proto3.getEnumType(LoginTypeEnum) },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 4, name: "otp_type", kind: "enum", T: proto3.getEnumType(OTPEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordInitRequest {
    return new ResetPasswordInitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordInitRequest {
    return new ResetPasswordInitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordInitRequest {
    return new ResetPasswordInitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordInitRequest | PlainMessage<ResetPasswordInitRequest> | undefined, b: ResetPasswordInitRequest | PlainMessage<ResetPasswordInitRequest> | undefined): boolean {
    return proto3.util.equals(ResetPasswordInitRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.ResetPasswordFinalRequest
 */
export class ResetPasswordFinalRequest extends Message<ResetPasswordFinalRequest> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * string otp = 1 [(google.api.field_behavior) = REQUIRED];
   *
   * @generated from field: string verification_code = 2;
   */
  verificationCode = "";

  /**
   * string check = 3 [(google.api.field_behavior) = REQUIRED]; //It can be mobile number or email
   *
   * @generated from field: string password = 3;
   */
  password = "";

  constructor(data?: PartialMessage<ResetPasswordFinalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.ResetPasswordFinalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "verification_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordFinalRequest {
    return new ResetPasswordFinalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordFinalRequest {
    return new ResetPasswordFinalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordFinalRequest {
    return new ResetPasswordFinalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordFinalRequest | PlainMessage<ResetPasswordFinalRequest> | undefined, b: ResetPasswordFinalRequest | PlainMessage<ResetPasswordFinalRequest> | undefined): boolean {
    return proto3.util.equals(ResetPasswordFinalRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.ResetPasswordFinalResponse
 */
export class ResetPasswordFinalResponse extends Message<ResetPasswordFinalResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: optional int32 active_session_id = 4;
   */
  activeSessionId?: number;

  constructor(data?: PartialMessage<ResetPasswordFinalResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.ResetPasswordFinalResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordFinalResponse {
    return new ResetPasswordFinalResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordFinalResponse {
    return new ResetPasswordFinalResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordFinalResponse {
    return new ResetPasswordFinalResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordFinalResponse | PlainMessage<ResetPasswordFinalResponse> | undefined, b: ResetPasswordFinalResponse | PlainMessage<ResetPasswordFinalResponse> | undefined): boolean {
    return proto3.util.equals(ResetPasswordFinalResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.UpdatePasswordRequest
 */
export class UpdatePasswordRequest extends Message<UpdatePasswordRequest> {
  /**
   * @generated from field: int64 profile_id = 1;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: optional string old_password = 2;
   */
  oldPassword?: string;

  /**
   * @generated from field: string new_password = 3;
   */
  newPassword = "";

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 4;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: geneo.ums.login.apis.PasswordTypeEnum password_type = 5;
   */
  passwordType = PasswordTypeEnum.PASSWORD_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<UpdatePasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.UpdatePasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "old_password", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "new_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 5, name: "password_type", kind: "enum", T: proto3.getEnumType(PasswordTypeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePasswordRequest {
    return new UpdatePasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePasswordRequest {
    return new UpdatePasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePasswordRequest {
    return new UpdatePasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePasswordRequest | PlainMessage<UpdatePasswordRequest> | undefined, b: UpdatePasswordRequest | PlainMessage<UpdatePasswordRequest> | undefined): boolean {
    return proto3.util.equals(UpdatePasswordRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.LogoutRequest
 */
export class LogoutRequest extends Message<LogoutRequest> {
  /**
   * @generated from field: int64 profile_id = 1;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 2;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<LogoutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.LogoutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutRequest {
    return new LogoutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutRequest | PlainMessage<LogoutRequest> | undefined, b: LogoutRequest | PlainMessage<LogoutRequest> | undefined): boolean {
    return proto3.util.equals(LogoutRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.ValidateUserRequest
 */
export class ValidateUserRequest extends Message<ValidateUserRequest> {
  /**
   * @generated from field: string user_login_input = 1;
   */
  userLoginInput = "";

  /**
   * @generated from field: geneo.ums.db.LoginTypeEnum login_type = 2;
   */
  loginType = LoginTypeEnum.LOGIN_TYPE_UNDEFINED;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 3;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<ValidateUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.ValidateUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_login_input", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "login_type", kind: "enum", T: proto3.getEnumType(LoginTypeEnum) },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateUserRequest {
    return new ValidateUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateUserRequest {
    return new ValidateUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateUserRequest {
    return new ValidateUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateUserRequest | PlainMessage<ValidateUserRequest> | undefined, b: ValidateUserRequest | PlainMessage<ValidateUserRequest> | undefined): boolean {
    return proto3.util.equals(ValidateUserRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.UserInfo
 */
export class UserInfo extends Message<UserInfo> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: string user_name = 3;
   */
  userName = "";

  /**
   * @generated from field: string phone_number = 4;
   */
  phoneNumber = "";

  /**
   * @generated from field: string email = 5;
   */
  email = "";

  /**
   * @generated from field: bool is_profile_verified = 6;
   */
  isProfileVerified = false;

  /**
   * @generated from field: bool is_password_updated = 7;
   */
  isPasswordUpdated = false;

  /**
   * @generated from field: int32 is_active = 8;
   */
  isActive = 0;

  /**
   * @generated from field: int32 email_verify = 9;
   */
  emailVerify = 0;

  /**
   * @generated from field: int32 phone_verify = 10;
   */
  phoneVerify = 0;

  /**
   * @generated from field: int64 profile_id = 11;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: optional google.protobuf.Timestamp last_reminder_time = 12;
   */
  lastReminderTime?: Timestamp;

  /**
   * @generated from field: geneo.ums.db.ProfileVerificationStatusEnum profile_verification_status = 13;
   */
  profileVerificationStatus = ProfileVerificationStatusEnum.PROFILE_VERIFICATION_UNDEFINED;

  /**
   * @generated from field: optional geneo.ums.db.Remark rejection_remarks = 14;
   */
  rejectionRemarks?: Remark;

  /**
   * @generated from field: geneo.ums.db.ProfileCreationModeEnum profile_creation_mode = 15;
   */
  profileCreationMode = ProfileCreationModeEnum.UNDEFINED;

  constructor(data?: PartialMessage<UserInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.UserInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_profile_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_password_updated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "email_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "phone_verify", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "last_reminder_time", kind: "message", T: Timestamp, opt: true },
    { no: 13, name: "profile_verification_status", kind: "enum", T: proto3.getEnumType(ProfileVerificationStatusEnum) },
    { no: 14, name: "rejection_remarks", kind: "message", T: Remark, opt: true },
    { no: 15, name: "profile_creation_mode", kind: "enum", T: proto3.getEnumType(ProfileCreationModeEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInfo {
    return new UserInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInfo {
    return new UserInfo().fromJsonString(jsonString, options);
  }

  static equals(a: UserInfo | PlainMessage<UserInfo> | undefined, b: UserInfo | PlainMessage<UserInfo> | undefined): boolean {
    return proto3.util.equals(UserInfo, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.ValidateUserResponse
 */
export class ValidateUserResponse extends Message<ValidateUserResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.ums.login.apis.UserInfo data = 4;
   */
  data: UserInfo[] = [];

  constructor(data?: PartialMessage<ValidateUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.ValidateUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: UserInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateUserResponse {
    return new ValidateUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateUserResponse {
    return new ValidateUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateUserResponse {
    return new ValidateUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateUserResponse | PlainMessage<ValidateUserResponse> | undefined, b: ValidateUserResponse | PlainMessage<ValidateUserResponse> | undefined): boolean {
    return proto3.util.equals(ValidateUserResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.UserProfileVerificationRequest
 */
export class UserProfileVerificationRequest extends Message<UserProfileVerificationRequest> {
  /**
   * @generated from field: int64 profile_id = 1;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 2;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<UserProfileVerificationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.UserProfileVerificationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProfileVerificationRequest {
    return new UserProfileVerificationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProfileVerificationRequest {
    return new UserProfileVerificationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProfileVerificationRequest {
    return new UserProfileVerificationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UserProfileVerificationRequest | PlainMessage<UserProfileVerificationRequest> | undefined, b: UserProfileVerificationRequest | PlainMessage<UserProfileVerificationRequest> | undefined): boolean {
    return proto3.util.equals(UserProfileVerificationRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.ProfileVerificationData
 */
export class ProfileVerificationData extends Message<ProfileVerificationData> {
  /**
   * @generated from field: optional geneo.ums.login.apis.StudentLoginResponseType student_profile = 1;
   */
  studentProfile?: StudentLoginResponseType;

  /**
   * @generated from field: optional geneo.ums.login.apis.TeacherLoginResponseType teacher_profile = 2;
   */
  teacherProfile?: TeacherLoginResponseType;

  constructor(data?: PartialMessage<ProfileVerificationData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.ProfileVerificationData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_profile", kind: "message", T: StudentLoginResponseType, opt: true },
    { no: 2, name: "teacher_profile", kind: "message", T: TeacherLoginResponseType, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileVerificationData {
    return new ProfileVerificationData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileVerificationData {
    return new ProfileVerificationData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileVerificationData {
    return new ProfileVerificationData().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileVerificationData | PlainMessage<ProfileVerificationData> | undefined, b: ProfileVerificationData | PlainMessage<ProfileVerificationData> | undefined): boolean {
    return proto3.util.equals(ProfileVerificationData, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.UserProfileVerificationResponse
 */
export class UserProfileVerificationResponse extends Message<UserProfileVerificationResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.login.apis.ProfileVerificationData data = 4;
   */
  data?: ProfileVerificationData;

  constructor(data?: PartialMessage<UserProfileVerificationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.UserProfileVerificationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ProfileVerificationData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProfileVerificationResponse {
    return new UserProfileVerificationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProfileVerificationResponse {
    return new UserProfileVerificationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProfileVerificationResponse {
    return new UserProfileVerificationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UserProfileVerificationResponse | PlainMessage<UserProfileVerificationResponse> | undefined, b: UserProfileVerificationResponse | PlainMessage<UserProfileVerificationResponse> | undefined): boolean {
    return proto3.util.equals(UserProfileVerificationResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.SwitchUserProfileRequest
 */
export class SwitchUserProfileRequest extends Message<SwitchUserProfileRequest> {
  /**
   * @generated from field: int64 profile_id = 1;
   */
  profileId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum role = 2;
   */
  role = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<SwitchUserProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.SwitchUserProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwitchUserProfileRequest {
    return new SwitchUserProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwitchUserProfileRequest {
    return new SwitchUserProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwitchUserProfileRequest {
    return new SwitchUserProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SwitchUserProfileRequest | PlainMessage<SwitchUserProfileRequest> | undefined, b: SwitchUserProfileRequest | PlainMessage<SwitchUserProfileRequest> | undefined): boolean {
    return proto3.util.equals(SwitchUserProfileRequest, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.SwitchUserProfileResponse
 */
export class SwitchUserProfileResponse extends Message<SwitchUserProfileResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: int32 active_session_id = 4;
   */
  activeSessionId = 0;

  /**
   * @generated from field: geneo.ums.login.apis.ProfileVerificationData data = 5;
   */
  data?: ProfileVerificationData;

  constructor(data?: PartialMessage<SwitchUserProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.SwitchUserProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "data", kind: "message", T: ProfileVerificationData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwitchUserProfileResponse {
    return new SwitchUserProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwitchUserProfileResponse {
    return new SwitchUserProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwitchUserProfileResponse {
    return new SwitchUserProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SwitchUserProfileResponse | PlainMessage<SwitchUserProfileResponse> | undefined, b: SwitchUserProfileResponse | PlainMessage<SwitchUserProfileResponse> | undefined): boolean {
    return proto3.util.equals(SwitchUserProfileResponse, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.AdminLoginResponseType
 */
export class AdminLoginResponseType extends Message<AdminLoginResponseType> {
  /**
   * @generated from field: int64 admin_profile_id = 1;
   */
  adminProfileId = protoInt64.zero;

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone_number = 4;
   */
  phoneNumber = "";

  /**
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 6;
   */
  lastName = "";

  /**
   * @generated from field: geneo.ums.login.apis.SchoolInfo school_details = 7;
   */
  schoolDetails?: SchoolInfo;

  /**
   * @generated from field: int32 is_active = 8;
   */
  isActive = 0;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum profile_role = 9;
   */
  profileRole = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  constructor(data?: PartialMessage<AdminLoginResponseType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.AdminLoginResponseType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "admin_profile_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "school_details", kind: "message", T: SchoolInfo },
    { no: 8, name: "is_active", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "profile_role", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminLoginResponseType {
    return new AdminLoginResponseType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminLoginResponseType {
    return new AdminLoginResponseType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminLoginResponseType {
    return new AdminLoginResponseType().fromJsonString(jsonString, options);
  }

  static equals(a: AdminLoginResponseType | PlainMessage<AdminLoginResponseType> | undefined, b: AdminLoginResponseType | PlainMessage<AdminLoginResponseType> | undefined): boolean {
    return proto3.util.equals(AdminLoginResponseType, a, b);
  }
}

/**
 * @generated from message geneo.ums.login.apis.AdminLoginResponse
 */
export class AdminLoginResponse extends Message<AdminLoginResponse> {
  /**
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.ums.login.apis.AdminLoginResponseType admin_info = 4;
   */
  adminInfo?: AdminLoginResponseType;

  /**
   * @generated from field: int32 active_session_id = 5;
   */
  activeSessionId = 0;

  constructor(data?: PartialMessage<AdminLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.ums.login.apis.AdminLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "admin_info", kind: "message", T: AdminLoginResponseType },
    { no: 5, name: "active_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminLoginResponse {
    return new AdminLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminLoginResponse {
    return new AdminLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdminLoginResponse {
    return new AdminLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdminLoginResponse | PlainMessage<AdminLoginResponse> | undefined, b: AdminLoginResponse | PlainMessage<AdminLoginResponse> | undefined): boolean {
    return proto3.util.equals(AdminLoginResponse, a, b);
  }
}

