import {
  ActionPopUpSingleButton,
  ChapterCard,
  ChipBadge,
  DashboardGrid,
  IStyles,
  Loader,
  LockToggleButton,
  NoContentCard,
  SubjectCard,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  useDownloadedSubjectRequests,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  HOME,
  LEARN_TOPIC_SELECTION,
} from '../../../routeHandling/RoutesNomenclature';
import { getSubjectsMap } from '../../../utils/icons';
import { setSubjectChapterInfo } from '../reducer/learn.slice';
import OngoingChapterCard from './components/OngoingChapterCard';

import {
  ChapterMetaInfo,
  DownloadedSubject,
} from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import {
  ContentLockStatusType,
  Feature,
} from '@protos/school_management/school.db_pb';
import { StudentLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { DownloadButtonWrapper } from '../../../components/DownloadButtonWrapper';
import { interactionEvent } from '../../Auth/Login/login_logout.events';

interface IPopupProps {
  iconName: string;
  popupText: string;
  splitText?: string;
  buttontext: string;
  ctaHandler: () => void;
}

const styles: IStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: {
      md: `${pxTovW(50)} ${pxTovW(40)}`,
      lg: `${pxTovW(50)} ${pxTovW(240)}`,
    },
  },
  subjectBox: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    padding: { xs: pxToRem(20), md: 0 },
  },
};
export default function LearnDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subjectChapterInfo = deserify(
    useAppSelector((state) => state.learn.subject_chapter_info)
  );
  const { selected_book_id } = deserify(useAppSelector((state) => state.home));
  const { userInfo } = deserify(useAppSelector((state) => state.auth));
  const [popupDetails, setPopupDetails] = useState<IPopupProps>({
    iconName: '',
    buttontext: 'ok',
    popupText: '',
    ctaHandler: () => setActionPopupState(false),
  });
  const [actionPopupState, setActionPopupState] = useState(false);
  const [status, setStatus] = useState<ContentLockModuleData | undefined>();
  const [lockedData, setLockedData] = useState<ContentLockModuleData>();
  const { subject_id } = useParams();
  const subjectId = Number(subject_id);
  const learnSubjects =
    deserify(useAppSelector((state) => state.auth.userInfo?.learnSubjects)) ||
    [];
  const subMap = getSubjectsMap(learnSubjects);
  const user = deserify(useAppSelector((state) => state.auth.userInfo));
  // const { userInfo } = deserify(useAppSelector((state) => state.auth));
  const featureInfo = user?.schoolDetails?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const { setSelectedFunction, setShowAppLoader } = useGlobalContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;
  const [fetchingState, setFetchingState] = useState(false);
  const downloadedSubject = useDownloadedSubjectRequests({
    subjectId: Number(subject_id),
  });
  // downloadedSubject - offline repsonse from indexed db
  const [downloadedMetaData, setDownloadedMetaData] = useState<
    Record<number, ChapterMetaInfo | undefined>
  >({});

  const backButtonClick = async () => {
    // interactionEvent({
    //   url: 'Student_Learn_dashboard',
    //   context: 'left_arrow',
    //   name: 'BACK',
    // });
    navigate(HOME);
  };

  useEffect(() => {
    if (subject_id && !isNaN(Number(subject_id))) {
      fetchSubjectChapterInfo();
      (async () => {
        setFetchingState(true);
        const indexDbData = await findDownloadedSubjectByParams({
          subjectId: Number(subject_id),
        });
        if (indexDbData.length > 0) {
          const data = indexDbData[0];
          const chapters = data.chapters || {};
          if (Object.keys(chapters).length > 0) {
            const downloadedChapIds = Object.keys(chapters);
            const meta = deepClone(downloadedMetaData);
            for (let i = 0; i < downloadedChapIds.length; i++) {
              const chapId = Number(downloadedChapIds[i]);
              const offlineData =
                await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
                  {
                    chapterId: Number(chapId),
                  }
                );
              meta[chapId] = offlineData.data;
            }
            setDownloadedMetaData(meta);
          }
        }
        setFetchingState(false);
      })();
    }
    setSelectedFunction(() => backButtonClick);

    // (async () => {
    //   const searchedBookId = getBookId(userInfo, subjectChapterInfo?.subjectId);
    //   if (!selected_book_id) dispatch(setHomeSelectedBookId(searchedBookId));
    //   await aiBookOpenEvent(
    //     selected_book_id ? selected_book_id : searchedBookId
    //   );
    // })();

    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const fetchSubjectChapterInfo = async () => {
    try {
      setLoading('loading');
      const response =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchSubjectChapterInfo(
          {
            personId: user?.studentProfileId,
            personType: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
            subjectId: subjectId,
            bookId: (subjectId && subMap[subjectId]?.bookId) || 0,
            sectionId: user?.classSectionDetails?.sectionId,
          }
        );
      if (response) {
        if (isContentLockFeature) {
          await fetchLockContent();
        }

        setLoading('completed');
        if (response?.data) {
          dispatch(setSubjectChapterInfo(response.data));
          return;
        }
      }
      dispatch(setSubjectChapterInfo(undefined));
    } catch (err) {
      setLoading('error');
      console.log(err);
      dispatch(setSubjectChapterInfo(undefined));
    }
  };

  const fetchLockContent = async () => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.studentModuleLockInfoFetch(
          {
            subjectId: subjectId,
            studentId: user?.studentProfileId,
          }
        );
      if (response?.data) {
        const data = response.data;
        setLockedData(data);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const onChapterSelect = (chapterId: number) => {
    // dispatch(setActiveChapterId(chapterId));
    if (typeof subjectId != 'number') {
      return;
    }
    const lockedStatusData =
      lockedData &&
      lockedData?.moduleUpdatedLockInfo?.children.find(
        (val) => Number(val.moduleId) === Number(chapterId)
      );
    if (isContentLockFeature) {
      lockedStatusData &&
      lockedStatusData?.lockStatus ===
        ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
        ? popup()
        : navigate(`${LEARN_TOPIC_SELECTION}/${subjectId}/${chapterId}`);
    } else {
      navigate(`${LEARN_TOPIC_SELECTION}/${subjectId}/${chapterId}`);
    }
  };

  const popup = () => {
    setPopupDetails({
      iconName: 'popuplock',
      buttontext: 'ok',
      popupText:
        'Your school has locked this resource. You will be able to access it once they unlock it',
      ctaHandler: () => setActionPopupState(false),
    });
    setActionPopupState(true);
  };

  const items = subjectChapterInfo?.response.map((elem, index) => {
    return (
      <ChapterCard
        image={getMediaBasePath(elem.posterImagesUrl, 'processedMediaBucket')}
        variant="normal"
        mainHeading={`${elem.chapterNo}. ${elem.chapterTitle}`}
        blueSubText={`${elem.noOfTopics} Topics`}
        rootStyle={{ width: '100%' }}
        cardClickHandler={async () => {
          await interactionEvent({
            url: 'Student_Learn_dashboard',
            context: 'All_Chapters',
            name: 'CHAPTER_OPEN',
          });
          onChapterSelect(elem.chapterId);
        }}
        withArrow={!isMobile}
        isLocked={
          isContentLockFeature &&
          lockedData &&
          lockedData?.moduleUpdatedLockInfo?.children?.find(
            (val) => Number(val.moduleId) === Number(elem.chapterId)
          )?.lockStatus === ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
            ? true
            : false
        }
      >
        {!fetchingState && (
          <Box
            sx={{
              position: 'absolute',
              top: { xs: '50%', md: 0 },
              right: { xs: pxToRem(12), md: 0 },
              transform: {
                xs: 'translateY(-50%)',
                md: 'translateY(-25%)',
                lg: 'initial',
              },
              display: 'flex',
              gap: { xs: pxToRem(10), md: pxTovW(10) },
            }}
          >
            {isContentLockFeature &&
              lockedData &&
              lockedData?.moduleUpdatedLockInfo?.children
                ?.filter(
                  (val) => Number(val.moduleId) === Number(elem.chapterId)
                )
                .map((vals, childIndex) => (
                  <LockToggleButton
                    key={childIndex}
                    isLocked={vals.lockStatus}
                  />
                ))}
            <DownloadButtonWrapper
              user={new StudentLoginResponseType(user)}
              downloadedSubject={
                downloadedSubject
                  ? new DownloadedSubject(downloadedSubject)
                  : undefined
              }
              downloadedMetaData={downloadedMetaData}
              setDownloadedMetaData={setDownloadedMetaData}
              subjectId={subjectId}
              classId={user?.classSectionDetails?.classId}
              sectionId={user?.classSectionDetails?.sectionId}
              chapterId={elem.chapterId}
              title={`Chapter - ${elem.chapterTitle}`}
              interactionEventUrl={'Student_learn_dashboard'}
              interactionEventContext={'chapters'}
              lockedData={lockedData}
            />
          </Box>
        )}
      </ChapterCard>
    );
  });

  const ongoingChapterId = subjectChapterInfo?.ongoingChapterId;
  const ongoingChapter = subjectChapterInfo?.response.find(
    (val) => val.chapterId === ongoingChapterId
  );
  // const ongoingChapter =
  //   subjectChapterInfo?.response.find((val) => !!val.noOfTopics) ||
  //   subjectChapterInfo?.response[0];

  const getSubjectName = () => {
    if (typeof subjectId == 'number') {
      return subMap[subjectId]?.subjectName || '';
    } else {
      return 'Invalid Subject';
    }
  };

  const getClassDetails = () => {
    if (user?.classSectionDetails?.className) {
      return (
        'Class ' +
        user?.classSectionDetails?.className +
        (user?.classSectionDetails?.sectionName || '')
      );
    }
    return '';
  };

  const chapters =
    subjectChapterInfo?.response.sort((a, b) => a.chapterNo - b.chapterNo) ||
    [];

  const ongoingLockIconDetails =
    ongoingChapter &&
    lockedData?.moduleUpdatedLockInfo?.children?.find(
      (val) => Number(val.moduleId) === Number(ongoingChapter.chapterId)
    );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.subjectBox}>
        <SubjectCard
          image={subMap[subjectId]?.iconUrl}
          title={getSubjectName()}
          subTitle={getClassDetails()}
        />
        {ongoingChapter && (
          <OngoingChapterCard
            chapter={ongoingChapter}
            onChapterCardClick={async () => {
              await interactionEvent({
                url: 'Student_Learn_dashboard',
                context: 'Ongoing_Chapter',
                name: 'CHAPTER_OPEN',
              });
              onChapterSelect(ongoingChapter.chapterId);
            }}
            isContentLockFeature={isContentLockFeature}
            lockStatus={ongoingLockIconDetails?.lockStatus}
          />
        )}
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5vw',
            padding: { xs: pxToRem(20), md: pxTovW(20) },
          }}
        >
          <Typography variant="h2">All Chapters</Typography>
          <Typography variant="h4">
            <ChipBadge
              label={items?.length || 0}
              color="primary"
              size="small"
            />
          </Typography>
          {!fetchingState && (
            <Box sx={{ marginLeft: { xs: 'auto', md: '10px' } }}>
              <DownloadButtonWrapper
                user={new StudentLoginResponseType(user)}
                downloadedSubject={
                  downloadedSubject
                    ? new DownloadedSubject(downloadedSubject)
                    : undefined
                }
                setDownloadedMetaData={setDownloadedMetaData}
                downloadedMetaData={downloadedMetaData}
                subjectId={subjectId}
                classId={user?.classSectionDetails?.classId}
                sectionId={user?.classSectionDetails?.sectionId}
                chapterIds={chapters?.map((elem) => elem.chapterId)}
                title={`Subject - ${getSubjectName()}`}
                interactionEventUrl={'Student_learn_dashboard'}
                interactionEventContext={'subject'}
                lockedData={lockedData}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            marginBottom: { xs: pxToRem(20), md: 0 },
          }}
        >
          {loading === 'loading' ? (
            // <Typography variant="h2">Loading ...</Typography>
            <Loader />
          ) : loading === 'error' ? (
            <NoContentCard variant="error" icon="error" text="Error Occured" />
          ) : (
            loading === 'completed' &&
            (items ? (
              <DashboardGrid items={items} WebNoOfCols={3} mobileNoOfCols={1} />
            ) : (
              <NoContentCard
                variant="info"
                icon="cards"
                text="No cards to show"
              />
            ))
          )}
        </Box>
      </Box>
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName={popupDetails.iconName}
        popupText={popupDetails.popupText}
        handleClose={() => setActionPopupState(false)}
        splitText={popupDetails.splitText}
        ctaHandler={popupDetails.ctaHandler}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />
    </Box>
  );
}
