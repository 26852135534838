import { Timestamp } from '@bufbuild/protobuf';
import {
  EarlyHomeworkPopup,
  IStyles,
  InfoDisplayCard,
  NewSectionList,
  deserify,
  formatDateAsDayMonth,
  pxToRem,
  pxTovW,
  remainingTimeInHoursAndMins,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { TaskStudentAttemptStatusEnum } from '@protos/learning_management/lms.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { HOMEWORK_STUDENT_DASHBOARD } from '../../../../routeHandling/RoutesNomenclature';
import { onHomeworkCardClick } from '../../../../utils/homework';
import { interactionEvent } from '../../../Auth/Login/login_logout.events';
import { setHomeHomeworkList } from '../../reducer/homeDashboard.slice';
import SectionListSckeleton, { ShimmerActiveHwCard } from '../../shimmer';
import { useMediaQuery } from '@mui/material';

const styles: IStyles = {
  idcRootStyle: {
    backgroundColor: 'common.white',
    minWidth: { xs: pxToRem(313), md: pxToRem(270), lg: pxTovW(476) },
    width: { xs: pxToRem(313), md: pxToRem(270), lg: pxTovW(476) },
    maxWidth: { xs: pxToRem(313), md: pxToRem(270), lg: pxTovW(476) },
    height: { xs: pxToRem(157), md: pxToRem(120), lg: pxTovW(196) },
  },
};

export default function ActiveHomeworkList() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [error, setError] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const selectedSubjectId = deserify(
    useAppSelector((state) => state.home.selected_subject_id)
  );
  const width = window.innerWidth;
  console.log('width', width);
  const home_homework_list =
    deserify(useAppSelector((state) => state.home.home_homework_list)) || [];

  const dispatch = useDispatch();
  const studentId = deserify(
    useAppSelector((state) => state.auth.userInfo?.studentProfileId)
  );
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState<Timestamp | undefined>(undefined);
  const { LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  useEffect(() => {
    getHomeworkList();
  }, [selectedSubjectId]);
  const onHomeworkClick = async () => {
    // console.log('Homework Clicked');
    navigate(HOMEWORK_STUDENT_DASHBOARD);
    await interactionEvent({
      url: 'Student_Home',
      context: 'active_homework',
      name: 'SEE_ALL',
    });
  };
  const getHomeworkList = async () => {
    try {
      if (!studentId) {
        return;
      }
      setLoading(true);
      const response =
        await LmsHomewokStudentAPIServiceV1ClientWithStatusCodeHandler.getStudentHomeworkList(
          {
            studentId: studentId,
            subjectId: selectedSubjectId,
          }
        );
      if (response) {
        setLoading(false);
        if (response.data) {
          const list = [
            ...(response.data?.allHomeworks?.active || []),
            ...(response.data?.allHomeworks?.ended || []),
          ];
          dispatch(setHomeHomeworkList(list));
          return;
        }
      }
      dispatch(setHomeHomeworkList([]));
    } catch (err) {
      dispatch(setHomeHomeworkList([]));
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  function hasTimePassed(milliseconds: string) {
    const currentTime = new Date().getTime();
    const targetTime = Number(milliseconds);
    return targetTime <= currentTime;
  }

  const getCardItems = () => {
    if (!home_homework_list) {
      return [];
    }
    return home_homework_list.slice(0, 20).map((val, index) => {
      const currentTabValue =
        val.endDate &&
          hasTimePassed(new Timestamp(val.endDate).toDate().getTime().toString())
          ? 'Ended'
          : 'Active';
      return (
        <InfoDisplayCard
          onCardClick={async () => {
            await interactionEvent({
              url: 'Student_Home',
              context: 'Active_homework',
              name: 'HOMEWORK_OPEN',
            });

            if (!studentId) {
              return;
            }
            if (val.startDate) {
              if (
                new Date(new Timestamp(val.startDate).toDate()) > new Date()
              ) {
                setStartTime(val.startDate);
                return;
              }
            }
            onHomeworkCardClick(
              dispatch,
              navigate,
              val.homeworkId,
              studentId,
              currentTabValue === 'Active' ? 'active' : 'ended',
              location.pathname
            );
          }}
          key={`home_homework_${index}`}
          variant="large"
          image={val.homeworkPosterImgUrl}
          currentTabValue={currentTabValue}
          submissionType={val.submissionType}
          homeworkItem={{
            subject: val.subject,
            chapter: val.moduleName,
            maxMarks: val.maxMarks,
            hwName: val.homeworkTitle,
            teacherName: val.teacherName,
            teacherProfileImageUrl: val.teacherProfileImageUrl,
            completed:
              val.studentAttemptStatus ===
                TaskStudentAttemptStatusEnum.TASK_STUDENT_STATUS_COMPLETED
                ? val.scorePercent
                  ? val.scorePercent.toString()
                  : '0'
                : undefined,
            taskType: val.taskType,
          }}
          iconDetails={[
            {
              iconName: 'questions',
              text: val.noOfQuestions.toString(),
              label: 'Questions',
            },
            {
              iconName: currentTabValue === 'Active' ? 'clock' : 'calender',
              text:
                currentTabValue === 'Active'
                  ? remainingTimeInHoursAndMins(val.endDate)
                  : formatDateAsDayMonth(val.endDate),
              label: currentTabValue === 'Active' ? 'Remaining' : 'Deadline',
            },
          ]}
          rootStyle={styles.idcRootStyle}
        />
      );
    });
  };

  return loading === true ? (
    <SectionListSckeleton children={ActiveHwShimmerArray} />
  ) : error === true || getCardItems().length == 0 ? (
    <></>
  ) : (
    <>
      <NewSectionList
        noContentMessage="No Homeworks for Today"
        itemsPerPage={isIpadOnly && width > 975 ? 3 : 2}
        isError={error}
        sectionTitle="Homeworks"
        background="#FCF1C7"
        items={getCardItems()}
        handleSeeAll={onHomeworkClick}
      //   items={[tempIDC(), tempIDC(), tempIDC()]}
      />
      <EarlyHomeworkPopup
        open={!!startTime}
        okHandler={() => setStartTime(undefined)}
        startTime={startTime}
      />
    </>
    // <SectionListSckeleton children={ActiveHwShimmerArray} />
  );
}

const ActiveHwShimmerArray = [
  <ShimmerActiveHwCard key={1} variant="large" />,
  <ShimmerActiveHwCard key={2} variant="large" />,
];
