import {
  HeaderIconProps,
  HeaderIconWrapper,
  IStyles,
  SecondaryButton,
  StudentSubjectPopup,
  deserify,
  findOfflineUserRequestByParamsDexie,
  getDeviceType,
  pxToRem,
  pxTovW,
  setLocalStorage,
  storeOfflineAccessKeyInCache,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../reduxStore/reduxHooks';
import {
  HOME,
  HOMEWORK_STUDENT_DASHBOARD,
  LEARN_DASHBOARD,
  PERFORMANCE_STUDENT_DASHBOARD,
} from '../../routeHandling/RoutesNomenclature';
import { getSubjectsMap } from '../../utils/icons';
import { setIsLoggedInOffline, setUserInfo } from '../Auth/reducer/auth.slice';
import {
  setResourceRedirectionPath,
  setSelectedResourceSessionId,
} from '../Learn/reducer/learn.slice';
import ActiveHomeworkList from './components/ActiveHomeworkList';
import OngoingLessonList from './components/OngoingLessonList';
import RecommendedResourcesList from './components/RecommendedResourcesList';
import {
  setHomeSelectedBookId,
  setHomeSelectedSubjectId,
} from './reducer/homeDashboard.slice';

import { useDownloadContext } from '../../app/Context/DownloadContextProviderV2';
import { interactionEvent } from '../Auth/Login/login_logout.events';
import { setActiveHomeworSessionId } from '../Homework/reducer/homework.slice';
import {
  aiHomeworkCloseEvent,
  aiHomeworkReviewCloseEvent,
} from '../Homework/utils_homework/homework.events';
import {
  aiBookCloseEvent,
  aiBookOpenEvent,
  aiChapterCloseEvent,
  aiContentCloseEvent,
  aiLessonCloseEvent,
  aiResourceCloseEvent,
  aiResourcePageCloseEvent,
  aiTopicCloseEvent,
} from '../Learn/utils_learn/learn.events';
import { setPerformanceId } from '../Performance/reducer/performance.slice';
import { aiPerformanceCloseEvent } from '../Performance/utils_performance/performance.events';
// import getDeviceType from 'libs/shared-ui/src/commonUtils/getDeviceType';
// import {} from './home.events';
import { SelectChangeEvent } from '@mui/material/Select';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import ConfigSelector from '../OfflineScreens/Learn/components/ConfigSelector';

export const Home = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const [selectedValue, setSelectedValue] = useState('');
  const { is_logged_in_offline, userInfo } = deserify(
    useAppSelector((state) => state.auth)
  );
  const { token, studentProfileId } = userInfo || {};
  const selectedSubjectId = deserify(
    useAppSelector((state) => state.home.selected_subject_id)
  );
  const { selected_book_id } = deserify(useAppSelector((state) => state.home));
  const {
    selected_chapter_resources,
    selected_module_lessons,
    selected_lesson_content,
    selected_resource_content,
    selected_resource_info,
  } = deserify(useAppSelector((state) => state.learn));

  const learnSubjects =
    deserify(useAppSelector((state) => state.auth.userInfo?.learnSubjects)) ||
    [];

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedValue(event.target.value);
    // handleDownload();
  };
  const subMap = getSubjectsMap(learnSubjects);
  const styles: IStyles = {
    mainContainer: {
      padding: {
        md: `${pxTovW(100)} ${pxTovW(10)}`,
        lg: `${pxTovW(200)} ${pxTovW(240)}`,
      },
      paddingTop: { md: pxTovW(284), lg: pxTovW(184) },
      // border: '2px solid red',
    },
  };

  const { isOffline } = useDownloadContext();

  // if we access the page /home?offline=true we are forcing the frontend to open the homepage in offline mode
  const forcedOffline =
    new URLSearchParams(location.search).get('offline') === 'true';

  const isOfflineFrontend = isOffline || forcedOffline || is_logged_in_offline;

  const {
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  const dispatch = useDispatch();

  const { setSelectedFunction } = useGlobalContext();
  const backButtonClick = async () => {
    navigate(HOME);
  };

  const { performance_id } = deserify(
    useAppSelector((state) => state.performance)
  );
  const { selected_homework_data } = deserify(
    useAppSelector((state) => state.homework)
  );
  const commonAiCloseEvents = async () => {
    await aiContentCloseEvent({
      resourceId: selected_resource_content?.parsedData.resourceId,
      pageNumber: selected_resource_info?.pageNumber,
      pageContent: selected_resource_info?.pageContent,
    });
    await aiResourcePageCloseEvent(
      selected_resource_content?.parsedData.resourceId,
      selected_resource_info?.pageNumber
    );
    await aiResourceCloseEvent(
      selected_resource_content?.parsedData.resourceId
    );
    await aiLessonCloseEvent(selected_lesson_content?.lessonId);
    await aiTopicCloseEvent(selected_module_lessons?.moduleId);
    await aiChapterCloseEvent(selected_chapter_resources?.chapterId);

    await aiPerformanceCloseEvent(performance_id);
    dispatch(setPerformanceId(undefined));
    await aiHomeworkCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
    });
    await aiHomeworkReviewCloseEvent({
      homeworkId: selected_homework_data?.homeworkId,
    });

    //
    if (selectedSubjectId === undefined) {
      await aiBookCloseEvent(selected_book_id);
      dispatch(setHomeSelectedBookId(undefined));
    }
    // SESSION_STORAGE.removeItem('studentLessonSessionId');
    // SESSION_STORAGE.removeItem('studentResourceSessionId');
    // SESSION_STORAGE.removeItem('studentHomeworkSessionId');
    dispatch(setActiveHomeworSessionId(undefined));
    dispatch(setSelectedResourceSessionId(undefined));
  };

  useEffect(() => {
    dispatch(setResourceRedirectionPath(undefined));
    fetchStudentProfile();

    console.log('useEffect[]');
    commonAiCloseEvents();

    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  useEffect(() => {
    if (!isOffline && is_logged_in_offline) {
      offlineLogin();
    }
  }, [isOffline]);

  const offlineLogin = async () => {
    if (!userInfo) {
      return;
    }
    const offlineUser = await findOfflineUserRequestByParamsDexie({
      username: userInfo?.userName,
    });
    if (!offlineUser) {
      return;
    }
    const res2 =
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.validateLoginSession(
        {
          userName: offlineUser?.username,
          verificationCode: offlineUser?.passwordHash,
          role: ProfileRolesEnum.PROFILE_ROLE_STUDENT,
        }
      );
    setLocalStorage('activeSession', res2.activeSessionId.toString());
    dispatch(setIsLoggedInOffline(false));
    // window.location.reload();
  };
  async function fetchStudentProfile() {
    try {
      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.fetchStudentProfile(
          {
            studentProfileId: studentProfileId,
          }
        );
      const resp = response.data;
      if (resp) {
        const accessKey = resp.studentProfileId
          ? 'student-' + resp.studentProfileId.toString()
          : 'geneo';
        await storeOfflineAccessKeyInCache(accessKey);
        if (token) {
          resp.token = token;
        }
        dispatch(setUserInfo(resp));
      }
    } catch (err) {
      console.log(err);
    }
  }

  const navigate = useNavigate();
  const [modalState, setModalState] = useState(false);
  const [clickedFrom, setClickedFrom] = useState<
    'header' | 'learn' | undefined
  >(undefined);
  const onHomeworkClick = async () => {
    // console.log('Homework Clicked');
    navigate(HOMEWORK_STUDENT_DASHBOARD);
    await interactionEvent({
      url: 'Student_Home',
      context: 'navigation_bar',
      name: 'HOMEWORK',
      pathSegments: pathSegments,
    });
  };
  const onAnalyticsClick = async () => {
    // console.log('Homework Clicked');
    await interactionEvent({
      url: 'Student_Home',
      context: 'navigation_bar',
      name: 'PERFORMANCE',
      pathSegments: pathSegments,
    });
    navigate(PERFORMANCE_STUDENT_DASHBOARD);
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const headerIconList: HeaderIconProps[] = [
    {
      fileName: 'teach',
      type: 'png',
      cardText: 'Learn',
      onClick: async () => {
        if (typeof selectedSubjectId == 'number') {
          await interactionEvent({
            url: 'Student_Home',
            context: 'navigation_bar',
            name: 'LEARN',
            pathSegments: pathSegments,
          });
          const route = `${LEARN_DASHBOARD}/${selectedSubjectId}`;
          if (isOfflineFrontend) {
            navigate(`/offline${route}`);
            return;
          }
          navigate(route);
        } else {
          setClickedFrom('learn');
          setModalState(true);
        }
      },
    },
    {
      fileName: 'new-hw',
      type: 'png',
      cardText: 'Homework',
      onClick: onHomeworkClick,
      isDisabled: isOfflineFrontend,
    },
    {
      fileName: 'analytics',
      type: 'png',
      cardText: 'Performance',
      onClick: onAnalyticsClick,
      isDisabled: isOfflineFrontend,
    },
  ];

  // const showNotification = () => {
  //   Notification.requestPermission().then((result) => {
  //     if (result === 'granted') {
  //       new Notification('React Notification', {
  //         // title: 'geneo',
  //         body: 'This is a notification from your Geneo App!',
  //         // icon: 'path/to/icon.png',
  //       });
  //     }
  //   });
  // };

  // Check device type
  const deviceType = getDeviceType();

  return (
    <>
      {mobile && (
        <Box sx={{ p: `${pxToRem(20)} ${pxToRem(20)} 0` }}>
          <SecondaryButton
            witharrow={typeof selectedSubjectId === 'number' ? false : true}
            onClick={async () => {
              await interactionEvent({
                url: 'Student_Home',
                context: 'Subject_selection',
                name: 'CHOOSE_SUBJECT',
                pathSegments: pathSegments,
              });

              setClickedFrom('header');
              setModalState(true);
            }}
            // sx={{ position: 'relative', width: '100%' }}
          >
            <Typography
              variant="button"
              fontWeight="regular"
              textAlign={'left'}
              width={'100%'}
            >
              {typeof selectedSubjectId == 'number'
                ? subMap[selectedSubjectId].subjectName
                : 'Choose Subject'}
            </Typography>
            {typeof selectedSubjectId == 'number' && (
              <CloseIcon
                sx={{
                  position: 'absolute',
                  right: '10px',
                  display: 'inline-block',
                  fontSize: { xs: pxToRem(14), md: pxTovW(18) },
                }}
                onClick={async (ev) => {
                  ev.stopPropagation();
                  await aiBookCloseEvent(selected_book_id);
                  // console.log('aiBookCloseEvent - mobile');

                  dispatch(setHomeSelectedSubjectId(undefined));
                  dispatch(setHomeSelectedBookId(undefined));
                }}
              />
            )}
          </SecondaryButton>
        </Box>
      )}

      <Box sx={{ position: { md: 'fixed' }, width: '100vw', zIndex: 1 }}>
        <HeaderIconWrapper icons={headerIconList} />
      </Box>

      <Box sx={styles.mainContainer}>
        {isOfflineFrontend && (
          <Box
            sx={{
              marginTop: { xs: pxToRem(-15), md: pxTovW(10) },
              marginBottom: { xs: pxToRem(10), md: pxTovW(10) },
            }}
          >
            <ConfigSelector />
          </Box>
        )}
        {!isOfflineFrontend ? <ActiveHomeworkList /> : null}
        {!isOfflineFrontend ? <OngoingLessonList /> : null}
        {!isOfflineFrontend ? <RecommendedResourcesList /> : null}

        {/* <TextBookList /> */}
      </Box>
      <StudentSubjectPopup
        modalState={modalState}
        setModalState={setModalState}
        displayData={learnSubjects.map((sub) => ({
          subject: sub.subjectName,
          icon: sub.iconUrl,
          color: sub.textColor,
          onClick: async () => {
            dispatch(setHomeSelectedBookId(sub.bookId));
            await interactionEvent({
              url: 'Student_Home',
              context: 'Subject_selection',
              name: sub.subjectName.toUpperCase(),
              pathSegments: pathSegments,
            });

            await aiBookCloseEvent(selected_book_id);
            // console.log('aiBookCloseEvent  - mobile');

            await aiBookOpenEvent(sub.bookId);

            if (clickedFrom === 'learn') {
              // dispatch(setHomeSelectedSubjectId(sub.subjectId))
              await interactionEvent({
                url: 'Student_Home',
                context: 'navigation_bar',
                name: 'LEARN',
                pathSegments: pathSegments,
              });
              const route = `${LEARN_DASHBOARD}/${sub.subjectId}`;
              if (isOfflineFrontend) {
                navigate(`/offline${route}`);
                return;
              }
              navigate(route);
            } else {
              dispatch(setHomeSelectedSubjectId(sub.subjectId));
            }

            setModalState(false);
          },
        }))}
        title="Choose Subject"
      />
      {/* {deviceType === 'web' && <Notification />} */}
    </>
  );
};

export default Home;
