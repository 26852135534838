// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/learning_management/lms.common.apis.proto (package geneo.lms.common.apis, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Any, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ProfileRolesEnum } from "@protos/user_management/ums.db_pb";
import { ContentLockStatusType, LessonNodeLock, SchoolContentModuleNodeLock } from "@protos/school_management/school.db_pb";
import { Module_ModuleCategoryEnum } from "@protos/content_management/content.db_pb";
import { Subject_SubjectEnum } from "@protos/common/common.db_pb";
import { SessionModeEnum, SessionStatusEnum, TeacherContentResponse } from "@protos/learning_management/lms.db_pb";

/**
 * @generated from enum geneo.lms.common.apis.FileUploadPurpose
 */
export enum FileUploadPurpose {
  /**
   * @generated from enum value: FILE_UPLOAD_FOR_UNDEFINED = 0;
   */
  FILE_UPLOAD_FOR_UNDEFINED = 0,

  /**
   * @generated from enum value: FILE_UPLOAD_FOR_HW_ASSET = 1;
   */
  FILE_UPLOAD_FOR_HW_ASSET = 1,

  /**
   * @generated from enum value: FILE_UPLOAD_FOR_PROFILE = 2;
   */
  FILE_UPLOAD_FOR_PROFILE = 2,

  /**
   * @generated from enum value: FILE_UPLOAD_FOR_HW_SUBMISSION = 3;
   */
  FILE_UPLOAD_FOR_HW_SUBMISSION = 3,

  /**
   * @generated from enum value: FILE_UPLOAD_TEACHER_RESOURCE = 4;
   */
  FILE_UPLOAD_TEACHER_RESOURCE = 4,

  /**
   * @generated from enum value: FILE_UPLOAD_TEACHER_QUESTION = 5;
   */
  FILE_UPLOAD_TEACHER_QUESTION = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(FileUploadPurpose)
proto3.util.setEnumType(FileUploadPurpose, "geneo.lms.common.apis.FileUploadPurpose", [
  { no: 0, name: "FILE_UPLOAD_FOR_UNDEFINED" },
  { no: 1, name: "FILE_UPLOAD_FOR_HW_ASSET" },
  { no: 2, name: "FILE_UPLOAD_FOR_PROFILE" },
  { no: 3, name: "FILE_UPLOAD_FOR_HW_SUBMISSION" },
  { no: 4, name: "FILE_UPLOAD_TEACHER_RESOURCE" },
  { no: 5, name: "FILE_UPLOAD_TEACHER_QUESTION" },
]);

/**
 * @generated from enum geneo.lms.common.apis.MimeEnum
 */
export enum MimeEnum {
  /**
   * Represents an undefined file type
   *
   * @generated from enum value: MIME_UNDEFINED = 0;
   */
  MIME_UNDEFINED = 0,

  /**
   * @generated from enum value: MIME_PNG = 1;
   */
  MIME_PNG = 1,

  /**
   * @generated from enum value: MIME_JPEG = 2;
   */
  MIME_JPEG = 2,

  /**
   * @generated from enum value: MIME_JPG = 3;
   */
  MIME_JPG = 3,

  /**
   * @generated from enum value: MIME_SVG = 4;
   */
  MIME_SVG = 4,

  /**
   * @generated from enum value: MIME_PDF = 5;
   */
  MIME_PDF = 5,

  /**
   * @generated from enum value: MIME_PPT = 6;
   */
  MIME_PPT = 6,

  /**
   * @generated from enum value: MIME_DOC = 7;
   */
  MIME_DOC = 7,

  /**
   * @generated from enum value: MIME_PPTX = 8;
   */
  MIME_PPTX = 8,

  /**
   * @generated from enum value: MIME_DOCX = 9;
   */
  MIME_DOCX = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(MimeEnum)
proto3.util.setEnumType(MimeEnum, "geneo.lms.common.apis.MimeEnum", [
  { no: 0, name: "MIME_UNDEFINED" },
  { no: 1, name: "MIME_PNG" },
  { no: 2, name: "MIME_JPEG" },
  { no: 3, name: "MIME_JPG" },
  { no: 4, name: "MIME_SVG" },
  { no: 5, name: "MIME_PDF" },
  { no: 6, name: "MIME_PPT" },
  { no: 7, name: "MIME_DOC" },
  { no: 8, name: "MIME_PPTX" },
  { no: 9, name: "MIME_DOCX" },
]);

/**
 * @generated from message geneo.lms.common.apis.GetLockStatusByIdsRequest
 */
export class GetLockStatusByIdsRequest extends Message<GetLockStatusByIdsRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 2;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: optional int32 school_class_section_id = 3;
   */
  schoolClassSectionId?: number;

  /**
   * @generated from field: optional int32 subject_id = 4;
   */
  subjectId?: number;

  /**
   * @generated from field: repeated string lesson_ids = 5;
   */
  lessonIds: string[] = [];

  /**
   * @generated from field: repeated string resource_ids = 6;
   */
  resourceIds: string[] = [];

  constructor(data?: PartialMessage<GetLockStatusByIdsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.GetLockStatusByIdsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 3, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "lesson_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLockStatusByIdsRequest {
    return new GetLockStatusByIdsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLockStatusByIdsRequest {
    return new GetLockStatusByIdsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLockStatusByIdsRequest {
    return new GetLockStatusByIdsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLockStatusByIdsRequest | PlainMessage<GetLockStatusByIdsRequest> | undefined, b: GetLockStatusByIdsRequest | PlainMessage<GetLockStatusByIdsRequest> | undefined): boolean {
    return proto3.util.equals(GetLockStatusByIdsRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.GetLockStatusByIdsResponse
 */
export class GetLockStatusByIdsResponse extends Message<GetLockStatusByIdsResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: repeated geneo.school.db.LessonNodeLock lessonInfo = 4;
   */
  lessonInfo: LessonNodeLock[] = [];

  /**
   * @generated from field: repeated geneo.lms.common.apis.ResourceLock resourceInfo = 5;
   */
  resourceInfo: ResourceLock[] = [];

  constructor(data?: PartialMessage<GetLockStatusByIdsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.GetLockStatusByIdsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lessonInfo", kind: "message", T: LessonNodeLock, repeated: true },
    { no: 5, name: "resourceInfo", kind: "message", T: ResourceLock, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLockStatusByIdsResponse {
    return new GetLockStatusByIdsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLockStatusByIdsResponse {
    return new GetLockStatusByIdsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLockStatusByIdsResponse {
    return new GetLockStatusByIdsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLockStatusByIdsResponse | PlainMessage<GetLockStatusByIdsResponse> | undefined, b: GetLockStatusByIdsResponse | PlainMessage<GetLockStatusByIdsResponse> | undefined): boolean {
    return proto3.util.equals(GetLockStatusByIdsResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.ResourceLock
 */
export class ResourceLock extends Message<ResourceLock> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: geneo.school.db.ContentLockStatusType lock_status = 2;
   */
  lockStatus = ContentLockStatusType.CONTENT_LOCK_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<ResourceLock>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.ResourceLock";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lock_status", kind: "enum", T: proto3.getEnumType(ContentLockStatusType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceLock {
    return new ResourceLock().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceLock {
    return new ResourceLock().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceLock {
    return new ResourceLock().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceLock | PlainMessage<ResourceLock> | undefined, b: ResourceLock | PlainMessage<ResourceLock> | undefined): boolean {
    return proto3.util.equals(ResourceLock, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.ModuleContentLock
 */
export class ModuleContentLock extends Message<ModuleContentLock> {
  /**
   * @generated from field: int32 module_id = 1;
   */
  moduleId = 0;

  /**
   * @generated from field: geneo.content.db.Module.ModuleCategoryEnum category = 2;
   */
  category = Module_ModuleCategoryEnum.MODULE_CATEGORY_UNDEFINED;

  /**
   * @generated from field: geneo.school.db.ContentLockStatusType lock_status = 3;
   */
  lockStatus = ContentLockStatusType.CONTENT_LOCK_STATUS_UNDEFINED;

  /**
   * @generated from field: optional string lesson_id = 4;
   */
  lessonId?: string;

  /**
   * @generated from field: optional string resource_id = 5;
   */
  resourceId?: string;

  constructor(data?: PartialMessage<ModuleContentLock>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.ModuleContentLock";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum) },
    { no: 3, name: "lock_status", kind: "enum", T: proto3.getEnumType(ContentLockStatusType) },
    { no: 4, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleContentLock {
    return new ModuleContentLock().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleContentLock {
    return new ModuleContentLock().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleContentLock {
    return new ModuleContentLock().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleContentLock | PlainMessage<ModuleContentLock> | undefined, b: ModuleContentLock | PlainMessage<ModuleContentLock> | undefined): boolean {
    return proto3.util.equals(ModuleContentLock, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.ModuleLockInfoFetchRequest
 */
export class ModuleLockInfoFetchRequest extends Message<ModuleLockInfoFetchRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 school_class_section_id = 2;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: optional int32 module_id = 4;
   */
  moduleId?: number;

  /**
   * @generated from field: optional geneo.content.db.Module.ModuleCategoryEnum category = 5;
   */
  category?: Module_ModuleCategoryEnum;

  constructor(data?: PartialMessage<ModuleLockInfoFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.ModuleLockInfoFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleLockInfoFetchRequest {
    return new ModuleLockInfoFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleLockInfoFetchRequest {
    return new ModuleLockInfoFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleLockInfoFetchRequest {
    return new ModuleLockInfoFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleLockInfoFetchRequest | PlainMessage<ModuleLockInfoFetchRequest> | undefined, b: ModuleLockInfoFetchRequest | PlainMessage<ModuleLockInfoFetchRequest> | undefined): boolean {
    return proto3.util.equals(ModuleLockInfoFetchRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.StudentModuleLockInfoFetchRequest
 */
export class StudentModuleLockInfoFetchRequest extends Message<StudentModuleLockInfoFetchRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 subject_id = 2;
   */
  subjectId = 0;

  /**
   * @generated from field: optional int32 module_id = 3;
   */
  moduleId?: number;

  /**
   * @generated from field: optional geneo.content.db.Module.ModuleCategoryEnum category = 4;
   */
  category?: Module_ModuleCategoryEnum;

  constructor(data?: PartialMessage<StudentModuleLockInfoFetchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.StudentModuleLockInfoFetchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "category", kind: "enum", T: proto3.getEnumType(Module_ModuleCategoryEnum), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StudentModuleLockInfoFetchRequest {
    return new StudentModuleLockInfoFetchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StudentModuleLockInfoFetchRequest {
    return new StudentModuleLockInfoFetchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StudentModuleLockInfoFetchRequest {
    return new StudentModuleLockInfoFetchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StudentModuleLockInfoFetchRequest | PlainMessage<StudentModuleLockInfoFetchRequest> | undefined, b: StudentModuleLockInfoFetchRequest | PlainMessage<StudentModuleLockInfoFetchRequest> | undefined): boolean {
    return proto3.util.equals(StudentModuleLockInfoFetchRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.ModuleLockInfoFetchResponse
 */
export class ModuleLockInfoFetchResponse extends Message<ModuleLockInfoFetchResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.common.apis.ContentLockModuleData data = 4;
   */
  data?: ContentLockModuleData;

  constructor(data?: PartialMessage<ModuleLockInfoFetchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.ModuleLockInfoFetchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ContentLockModuleData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModuleLockInfoFetchResponse {
    return new ModuleLockInfoFetchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModuleLockInfoFetchResponse {
    return new ModuleLockInfoFetchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModuleLockInfoFetchResponse {
    return new ModuleLockInfoFetchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModuleLockInfoFetchResponse | PlainMessage<ModuleLockInfoFetchResponse> | undefined, b: ModuleLockInfoFetchResponse | PlainMessage<ModuleLockInfoFetchResponse> | undefined): boolean {
    return proto3.util.equals(ModuleLockInfoFetchResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.TeacherContentLockUpdateRequest
 */
export class TeacherContentLockUpdateRequest extends Message<TeacherContentLockUpdateRequest> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: int32 school_class_section_id = 2;
   */
  schoolClassSectionId = 0;

  /**
   * @generated from field: int32 subject_id = 3;
   */
  subjectId = 0;

  /**
   * @generated from field: geneo.lms.common.apis.ModuleContentLock moduleInfo = 4;
   */
  moduleInfo?: ModuleContentLock;

  constructor(data?: PartialMessage<TeacherContentLockUpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.TeacherContentLockUpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "school_class_section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "moduleInfo", kind: "message", T: ModuleContentLock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherContentLockUpdateRequest {
    return new TeacherContentLockUpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherContentLockUpdateRequest {
    return new TeacherContentLockUpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherContentLockUpdateRequest {
    return new TeacherContentLockUpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherContentLockUpdateRequest | PlainMessage<TeacherContentLockUpdateRequest> | undefined, b: TeacherContentLockUpdateRequest | PlainMessage<TeacherContentLockUpdateRequest> | undefined): boolean {
    return proto3.util.equals(TeacherContentLockUpdateRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.TeacherContentLockUpdateResponse
 */
export class TeacherContentLockUpdateResponse extends Message<TeacherContentLockUpdateResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: geneo.lms.common.apis.ContentLockModuleData data = 4;
   */
  data?: ContentLockModuleData;

  constructor(data?: PartialMessage<TeacherContentLockUpdateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.TeacherContentLockUpdateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ContentLockModuleData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeacherContentLockUpdateResponse {
    return new TeacherContentLockUpdateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeacherContentLockUpdateResponse {
    return new TeacherContentLockUpdateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeacherContentLockUpdateResponse {
    return new TeacherContentLockUpdateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeacherContentLockUpdateResponse | PlainMessage<TeacherContentLockUpdateResponse> | undefined, b: TeacherContentLockUpdateResponse | PlainMessage<TeacherContentLockUpdateResponse> | undefined): boolean {
    return proto3.util.equals(TeacherContentLockUpdateResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.ContentLockModuleData
 */
export class ContentLockModuleData extends Message<ContentLockModuleData> {
  /**
   * @generated from field: geneo.school.db.SchoolContentModuleNodeLock module_updated_lock_info = 1;
   */
  moduleUpdatedLockInfo?: SchoolContentModuleNodeLock;

  constructor(data?: PartialMessage<ContentLockModuleData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.ContentLockModuleData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "module_updated_lock_info", kind: "message", T: SchoolContentModuleNodeLock },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContentLockModuleData {
    return new ContentLockModuleData().fromJsonString(jsonString, options);
  }

  static equals(a: ContentLockModuleData | PlainMessage<ContentLockModuleData> | undefined, b: ContentLockModuleData | PlainMessage<ContentLockModuleData> | undefined): boolean {
    return proto3.util.equals(ContentLockModuleData, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.RunLLMResponseEvaluationCronRequest
 */
export class RunLLMResponseEvaluationCronRequest extends Message<RunLLMResponseEvaluationCronRequest> {
  constructor(data?: PartialMessage<RunLLMResponseEvaluationCronRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.RunLLMResponseEvaluationCronRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunLLMResponseEvaluationCronRequest {
    return new RunLLMResponseEvaluationCronRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunLLMResponseEvaluationCronRequest {
    return new RunLLMResponseEvaluationCronRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunLLMResponseEvaluationCronRequest {
    return new RunLLMResponseEvaluationCronRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RunLLMResponseEvaluationCronRequest | PlainMessage<RunLLMResponseEvaluationCronRequest> | undefined, b: RunLLMResponseEvaluationCronRequest | PlainMessage<RunLLMResponseEvaluationCronRequest> | undefined): boolean {
    return proto3.util.equals(RunLLMResponseEvaluationCronRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.RunLLMResponseEvaluationCronResponse
 */
export class RunLLMResponseEvaluationCronResponse extends Message<RunLLMResponseEvaluationCronResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<RunLLMResponseEvaluationCronResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.RunLLMResponseEvaluationCronResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunLLMResponseEvaluationCronResponse {
    return new RunLLMResponseEvaluationCronResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunLLMResponseEvaluationCronResponse {
    return new RunLLMResponseEvaluationCronResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunLLMResponseEvaluationCronResponse {
    return new RunLLMResponseEvaluationCronResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RunLLMResponseEvaluationCronResponse | PlainMessage<RunLLMResponseEvaluationCronResponse> | undefined, b: RunLLMResponseEvaluationCronResponse | PlainMessage<RunLLMResponseEvaluationCronResponse> | undefined): boolean {
    return proto3.util.equals(RunLLMResponseEvaluationCronResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.RunLLMQuestionAdditionCronRequest
 */
export class RunLLMQuestionAdditionCronRequest extends Message<RunLLMQuestionAdditionCronRequest> {
  constructor(data?: PartialMessage<RunLLMQuestionAdditionCronRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.RunLLMQuestionAdditionCronRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunLLMQuestionAdditionCronRequest {
    return new RunLLMQuestionAdditionCronRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunLLMQuestionAdditionCronRequest {
    return new RunLLMQuestionAdditionCronRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunLLMQuestionAdditionCronRequest {
    return new RunLLMQuestionAdditionCronRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RunLLMQuestionAdditionCronRequest | PlainMessage<RunLLMQuestionAdditionCronRequest> | undefined, b: RunLLMQuestionAdditionCronRequest | PlainMessage<RunLLMQuestionAdditionCronRequest> | undefined): boolean {
    return proto3.util.equals(RunLLMQuestionAdditionCronRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.RunLLMQuestionAdditionCronResponse
 */
export class RunLLMQuestionAdditionCronResponse extends Message<RunLLMQuestionAdditionCronResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  constructor(data?: PartialMessage<RunLLMQuestionAdditionCronResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.RunLLMQuestionAdditionCronResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunLLMQuestionAdditionCronResponse {
    return new RunLLMQuestionAdditionCronResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunLLMQuestionAdditionCronResponse {
    return new RunLLMQuestionAdditionCronResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunLLMQuestionAdditionCronResponse {
    return new RunLLMQuestionAdditionCronResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RunLLMQuestionAdditionCronResponse | PlainMessage<RunLLMQuestionAdditionCronResponse> | undefined, b: RunLLMQuestionAdditionCronResponse | PlainMessage<RunLLMQuestionAdditionCronResponse> | undefined): boolean {
    return proto3.util.equals(RunLLMQuestionAdditionCronResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.SignedUrlRequest
 */
export class SignedUrlRequest extends Message<SignedUrlRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: optional int32 homework_id = 2;
   */
  homeworkId?: number;

  /**
   * @generated from field: optional string question_id = 3;
   */
  questionId?: string;

  /**
   * @generated from field: optional int32 hw_session_id = 4;
   */
  hwSessionId?: number;

  /**
   * @generated from field: repeated geneo.lms.common.apis.FileInfo files_info = 5;
   */
  filesInfo: FileInfo[] = [];

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 6;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: geneo.lms.common.apis.FileUploadPurpose file_upload_purpose = 7;
   */
  fileUploadPurpose = FileUploadPurpose.FILE_UPLOAD_FOR_UNDEFINED;

  /**
   * @generated from field: optional int32 section_id = 8;
   */
  sectionId?: number;

  /**
   * @generated from field: optional int32 subject_id = 9;
   */
  subjectId?: number;

  constructor(data?: PartialMessage<SignedUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.SignedUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "hw_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "files_info", kind: "message", T: FileInfo, repeated: true },
    { no: 6, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 7, name: "file_upload_purpose", kind: "enum", T: proto3.getEnumType(FileUploadPurpose) },
    { no: 8, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrlRequest {
    return new SignedUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrlRequest {
    return new SignedUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrlRequest {
    return new SignedUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrlRequest | PlainMessage<SignedUrlRequest> | undefined, b: SignedUrlRequest | PlainMessage<SignedUrlRequest> | undefined): boolean {
    return proto3.util.equals(SignedUrlRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.SignedUrlForUploadRequest
 */
export class SignedUrlForUploadRequest extends Message<SignedUrlForUploadRequest> {
  /**
   * @generated from field: int64 person_id = 1;
   */
  personId = protoInt64.zero;

  /**
   * @generated from field: optional int32 homework_id = 2;
   */
  homeworkId?: number;

  /**
   * @generated from field: optional string question_id = 3;
   */
  questionId?: string;

  /**
   * @generated from field: optional int32 hw_session_id = 4;
   */
  hwSessionId?: number;

  /**
   * @generated from field: repeated geneo.lms.common.apis.FileInfo files_info = 5;
   */
  filesInfo: FileInfo[] = [];

  /**
   * @generated from field: geneo.ums.db.ProfileRolesEnum person_type = 6;
   */
  personType = ProfileRolesEnum.PROFILE_ROLE_UNDEFINED;

  /**
   * @generated from field: geneo.lms.common.apis.FileUploadPurpose file_upload_purpose = 7;
   */
  fileUploadPurpose = FileUploadPurpose.FILE_UPLOAD_FOR_UNDEFINED;

  /**
   * @generated from field: optional int32 section_id = 8;
   */
  sectionId?: number;

  /**
   * @generated from field: optional int32 subject_id = 9;
   */
  subjectId?: number;

  /**
   * @generated from field: optional int32 resource_id = 10;
   */
  resourceId?: number;

  constructor(data?: PartialMessage<SignedUrlForUploadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.SignedUrlForUploadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "person_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "hw_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "files_info", kind: "message", T: FileInfo, repeated: true },
    { no: 6, name: "person_type", kind: "enum", T: proto3.getEnumType(ProfileRolesEnum) },
    { no: 7, name: "file_upload_purpose", kind: "enum", T: proto3.getEnumType(FileUploadPurpose) },
    { no: 8, name: "section_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "subject_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "resource_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrlForUploadRequest {
    return new SignedUrlForUploadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrlForUploadRequest {
    return new SignedUrlForUploadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrlForUploadRequest {
    return new SignedUrlForUploadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrlForUploadRequest | PlainMessage<SignedUrlForUploadRequest> | undefined, b: SignedUrlForUploadRequest | PlainMessage<SignedUrlForUploadRequest> | undefined): boolean {
    return proto3.util.equals(SignedUrlForUploadRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.FileInfo
 */
export class FileInfo extends Message<FileInfo> {
  /**
   * Image-name
   *
   * @generated from field: string original_file_path = 1;
   */
  originalFilePath = "";

  /**
   * @generated from field: geneo.lms.common.apis.MimeEnum mime_type = 2;
   */
  mimeType = MimeEnum.MIME_UNDEFINED;

  /**
   * @generated from field: optional float size = 3;
   */
  size?: number;

  constructor(data?: PartialMessage<FileInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.FileInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "original_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mime_type", kind: "enum", T: proto3.getEnumType(MimeEnum) },
    { no: 3, name: "size", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileInfo {
    return new FileInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileInfo {
    return new FileInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileInfo {
    return new FileInfo().fromJsonString(jsonString, options);
  }

  static equals(a: FileInfo | PlainMessage<FileInfo> | undefined, b: FileInfo | PlainMessage<FileInfo> | undefined): boolean {
    return proto3.util.equals(FileInfo, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.SignedUrl
 */
export class SignedUrl extends Message<SignedUrl> {
  /**
   * @generated from field: string signed_url = 1;
   */
  signedUrl = "";

  /**
   * @generated from field: optional string original_file_path = 2;
   */
  originalFilePath?: string;

  constructor(data?: PartialMessage<SignedUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.SignedUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signed_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "original_file_path", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrl {
    return new SignedUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrl {
    return new SignedUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrl {
    return new SignedUrl().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrl | PlainMessage<SignedUrl> | undefined, b: SignedUrl | PlainMessage<SignedUrl> | undefined): boolean {
    return proto3.util.equals(SignedUrl, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.SignedUrlReponse
 */
export class SignedUrlReponse extends Message<SignedUrlReponse> {
  /**
   * @generated from field: repeated geneo.lms.common.apis.SignedUrl response = 1;
   */
  response: SignedUrl[] = [];

  constructor(data?: PartialMessage<SignedUrlReponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.SignedUrlReponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response", kind: "message", T: SignedUrl, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrlReponse {
    return new SignedUrlReponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrlReponse {
    return new SignedUrlReponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrlReponse {
    return new SignedUrlReponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrlReponse | PlainMessage<SignedUrlReponse> | undefined, b: SignedUrlReponse | PlainMessage<SignedUrlReponse> | undefined): boolean {
    return proto3.util.equals(SignedUrlReponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.SignedUrlForUploadReponse
 */
export class SignedUrlForUploadReponse extends Message<SignedUrlForUploadReponse> {
  /**
   * @generated from field: repeated geneo.lms.common.apis.SignedUrl response = 1;
   */
  response: SignedUrl[] = [];

  constructor(data?: PartialMessage<SignedUrlForUploadReponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.SignedUrlForUploadReponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "response", kind: "message", T: SignedUrl, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignedUrlForUploadReponse {
    return new SignedUrlForUploadReponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignedUrlForUploadReponse {
    return new SignedUrlForUploadReponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignedUrlForUploadReponse {
    return new SignedUrlForUploadReponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignedUrlForUploadReponse | PlainMessage<SignedUrlForUploadReponse> | undefined, b: SignedUrlForUploadReponse | PlainMessage<SignedUrlForUploadReponse> | undefined): boolean {
    return proto3.util.equals(SignedUrlForUploadReponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.GetResponseMarksFromEvaluatorRequest
 */
export class GetResponseMarksFromEvaluatorRequest extends Message<GetResponseMarksFromEvaluatorRequest> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 question_id = 2;
   */
  questionId = 0;

  /**
   * @generated from field: repeated string answer = 3;
   */
  answer: string[] = [];

  constructor(data?: PartialMessage<GetResponseMarksFromEvaluatorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.GetResponseMarksFromEvaluatorRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "question_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResponseMarksFromEvaluatorRequest {
    return new GetResponseMarksFromEvaluatorRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResponseMarksFromEvaluatorRequest {
    return new GetResponseMarksFromEvaluatorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResponseMarksFromEvaluatorRequest {
    return new GetResponseMarksFromEvaluatorRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetResponseMarksFromEvaluatorRequest | PlainMessage<GetResponseMarksFromEvaluatorRequest> | undefined, b: GetResponseMarksFromEvaluatorRequest | PlainMessage<GetResponseMarksFromEvaluatorRequest> | undefined): boolean {
    return proto3.util.equals(GetResponseMarksFromEvaluatorRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.GetResponseMarksFromEvaluatorResponse
 */
export class GetResponseMarksFromEvaluatorResponse extends Message<GetResponseMarksFromEvaluatorResponse> {
  /**
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * @generated from field: int32 question_id = 2;
   */
  questionId = 0;

  /**
   * @generated from field: float marks = 3;
   */
  marks = 0;

  /**
   * @generated from field: repeated string remarks = 4;
   */
  remarks: string[] = [];

  constructor(data?: PartialMessage<GetResponseMarksFromEvaluatorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.GetResponseMarksFromEvaluatorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "question_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "marks", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "remarks", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResponseMarksFromEvaluatorResponse {
    return new GetResponseMarksFromEvaluatorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResponseMarksFromEvaluatorResponse {
    return new GetResponseMarksFromEvaluatorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResponseMarksFromEvaluatorResponse {
    return new GetResponseMarksFromEvaluatorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetResponseMarksFromEvaluatorResponse | PlainMessage<GetResponseMarksFromEvaluatorResponse> | undefined, b: GetResponseMarksFromEvaluatorResponse | PlainMessage<GetResponseMarksFromEvaluatorResponse> | undefined): boolean {
    return proto3.util.equals(GetResponseMarksFromEvaluatorResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.RunLMSScriptRequest
 */
export class RunLMSScriptRequest extends Message<RunLMSScriptRequest> {
  /**
   * @generated from field: string script_name = 1;
   */
  scriptName = "";

  constructor(data?: PartialMessage<RunLMSScriptRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.RunLMSScriptRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "script_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunLMSScriptRequest {
    return new RunLMSScriptRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunLMSScriptRequest {
    return new RunLMSScriptRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunLMSScriptRequest {
    return new RunLMSScriptRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RunLMSScriptRequest | PlainMessage<RunLMSScriptRequest> | undefined, b: RunLMSScriptRequest | PlainMessage<RunLMSScriptRequest> | undefined): boolean {
    return proto3.util.equals(RunLMSScriptRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.RunLMSScriptResponse
 */
export class RunLMSScriptResponse extends Message<RunLMSScriptResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * @generated from field: optional google.protobuf.Any data = 4;
   */
  data?: Any;

  constructor(data?: PartialMessage<RunLMSScriptResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.RunLMSScriptResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: Any, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RunLMSScriptResponse {
    return new RunLMSScriptResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RunLMSScriptResponse {
    return new RunLMSScriptResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RunLMSScriptResponse {
    return new RunLMSScriptResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RunLMSScriptResponse | PlainMessage<RunLMSScriptResponse> | undefined, b: RunLMSScriptResponse | PlainMessage<RunLMSScriptResponse> | undefined): boolean {
    return proto3.util.equals(RunLMSScriptResponse, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.fixSubjectiveQuestionTimeRequest
 */
export class fixSubjectiveQuestionTimeRequest extends Message<fixSubjectiveQuestionTimeRequest> {
  /**
   * @generated from field: string script_name = 1;
   */
  scriptName = "";

  constructor(data?: PartialMessage<fixSubjectiveQuestionTimeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.fixSubjectiveQuestionTimeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "script_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): fixSubjectiveQuestionTimeRequest {
    return new fixSubjectiveQuestionTimeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): fixSubjectiveQuestionTimeRequest {
    return new fixSubjectiveQuestionTimeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): fixSubjectiveQuestionTimeRequest {
    return new fixSubjectiveQuestionTimeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: fixSubjectiveQuestionTimeRequest | PlainMessage<fixSubjectiveQuestionTimeRequest> | undefined, b: fixSubjectiveQuestionTimeRequest | PlainMessage<fixSubjectiveQuestionTimeRequest> | undefined): boolean {
    return proto3.util.equals(fixSubjectiveQuestionTimeRequest, a, b);
  }
}

/**
 * @generated from message geneo.lms.common.apis.fixSubjectiveQuestionTimeResponse
 */
export class fixSubjectiveQuestionTimeResponse extends Message<fixSubjectiveQuestionTimeResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * Updated QuestionIds
   *
   * @generated from field: repeated string updateQIds = 4;
   */
  updateQIds: string[] = [];

  constructor(data?: PartialMessage<fixSubjectiveQuestionTimeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.fixSubjectiveQuestionTimeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updateQIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): fixSubjectiveQuestionTimeResponse {
    return new fixSubjectiveQuestionTimeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): fixSubjectiveQuestionTimeResponse {
    return new fixSubjectiveQuestionTimeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): fixSubjectiveQuestionTimeResponse {
    return new fixSubjectiveQuestionTimeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: fixSubjectiveQuestionTimeResponse | PlainMessage<fixSubjectiveQuestionTimeResponse> | undefined, b: fixSubjectiveQuestionTimeResponse | PlainMessage<fixSubjectiveQuestionTimeResponse> | undefined): boolean {
    return proto3.util.equals(fixSubjectiveQuestionTimeResponse, a, b);
  }
}

/**
 * CreateTeacherResourceSessionRequest represents a request to create a teacher's resource session.
 *
 * @generated from message geneo.lms.common.apis.CreateTeacherResourceSessionRequest
 */
export class CreateTeacherResourceSessionRequest extends Message<CreateTeacherResourceSessionRequest> {
  /**
   * ID of the teacher associated with the resource session
   *
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the resource associated with the session
   *
   * @generated from field: string resource_id = 2;
   */
  resourceId = "";

  /**
   * ID of the school where the lesson session takes place
   *
   * @generated from field: int32 school_id = 3;
   */
  schoolId = 0;

  /**
   * Academic year associated with the lesson session
   *
   * @generated from field: int32 academic_year = 4;
   */
  academicYear = 0;

  /**
   * Grade associated with the lesson session
   *
   * @generated from field: int32 class_id = 5;
   */
  classId = 0;

  /**
   * Section associated with the lesson session
   *
   * @generated from field: string section = 6;
   */
  section = "";

  /**
   * Subject associated with the lesson session
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 7;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * Module ID associated with the lesson session
   *
   * @generated from field: int32 module_id = 8;
   */
  moduleId = 0;

  /**
   * ID of the teacher's lesson session (optional)
   *
   * @generated from field: int32 teacher_lesson_session_id = 9;
   */
  teacherLessonSessionId = 0;

  /**
   * Mode of the resource session (e.g., online, offline, etc.)
   *
   * @generated from field: geneo.lms.db.SessionModeEnum session_mode = 10;
   */
  sessionMode = SessionModeEnum.SESSION_MODE_UNDEFINED;

  /**
   * Start time of the resource session
   *
   * @generated from field: google.protobuf.Timestamp start_time = 11;
   */
  startTime?: Timestamp;

  /**
   * Status of the resource session
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 12;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<CreateTeacherResourceSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.CreateTeacherResourceSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "academic_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 8, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "teacher_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "session_mode", kind: "enum", T: proto3.getEnumType(SessionModeEnum) },
    { no: 11, name: "start_time", kind: "message", T: Timestamp },
    { no: 12, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTeacherResourceSessionRequest {
    return new CreateTeacherResourceSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTeacherResourceSessionRequest {
    return new CreateTeacherResourceSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTeacherResourceSessionRequest {
    return new CreateTeacherResourceSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTeacherResourceSessionRequest | PlainMessage<CreateTeacherResourceSessionRequest> | undefined, b: CreateTeacherResourceSessionRequest | PlainMessage<CreateTeacherResourceSessionRequest> | undefined): boolean {
    return proto3.util.equals(CreateTeacherResourceSessionRequest, a, b);
  }
}

/**
 * CreateTeacherResourceSessionResponse represents a response for creating a teacher's resource session.
 *
 * @generated from message geneo.lms.common.apis.CreateTeacherResourceSessionResponse
 */
export class CreateTeacherResourceSessionResponse extends Message<CreateTeacherResourceSessionResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * ID of the created teacher's resource session
   *
   * @generated from field: int32 teacher_resource_session_id = 4;
   */
  teacherResourceSessionId = 0;

  constructor(data?: PartialMessage<CreateTeacherResourceSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.CreateTeacherResourceSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "teacher_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTeacherResourceSessionResponse {
    return new CreateTeacherResourceSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTeacherResourceSessionResponse {
    return new CreateTeacherResourceSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTeacherResourceSessionResponse {
    return new CreateTeacherResourceSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTeacherResourceSessionResponse | PlainMessage<CreateTeacherResourceSessionResponse> | undefined, b: CreateTeacherResourceSessionResponse | PlainMessage<CreateTeacherResourceSessionResponse> | undefined): boolean {
    return proto3.util.equals(CreateTeacherResourceSessionResponse, a, b);
  }
}

/**
 * UpdateTeacherResourceSessionRequest represents a request to update a teacher's resource session.
 *
 * @generated from message geneo.lms.common.apis.UpdateTeacherResourceSessionRequest
 */
export class UpdateTeacherResourceSessionRequest extends Message<UpdateTeacherResourceSessionRequest> {
  /**
   * ID of the teacher's resource session
   *
   * @generated from field: int32 teacher_resource_session_id = 1;
   */
  teacherResourceSessionId = 0;

  /**
   * ID of the teacher associated with the resource session
   *
   * @generated from field: int64 teacher_id = 2;
   */
  teacherId = protoInt64.zero;

  /**
   * ID of the resource associated with the session
   *
   * @generated from field: string resource_id = 3;
   */
  resourceId = "";

  /**
   * Status of the resource session
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 4;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * Teacher content response (JSON as string)
   *
   * @generated from field: repeated geneo.lms.db.TeacherContentResponse response = 5;
   */
  response: TeacherContentResponse[] = [];

  /**
   * End time of the resource session (optional)
   *
   * @generated from field: google.protobuf.Timestamp end_time = 6;
   */
  endTime?: Timestamp;

  constructor(data?: PartialMessage<UpdateTeacherResourceSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.UpdateTeacherResourceSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 5, name: "response", kind: "message", T: TeacherContentResponse, repeated: true },
    { no: 6, name: "end_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTeacherResourceSessionRequest {
    return new UpdateTeacherResourceSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTeacherResourceSessionRequest {
    return new UpdateTeacherResourceSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTeacherResourceSessionRequest {
    return new UpdateTeacherResourceSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTeacherResourceSessionRequest | PlainMessage<UpdateTeacherResourceSessionRequest> | undefined, b: UpdateTeacherResourceSessionRequest | PlainMessage<UpdateTeacherResourceSessionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTeacherResourceSessionRequest, a, b);
  }
}

/**
 * UpdateTeacherResourceSessionResponse represents a response for updating a teacher's resource session.
 *
 * @generated from message geneo.lms.common.apis.UpdateTeacherResourceSessionResponse
 */
export class UpdateTeacherResourceSessionResponse extends Message<UpdateTeacherResourceSessionResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * ID of the updated teacher's resource session
   *
   * @generated from field: int32 teacher_resource_session_id = 4;
   */
  teacherResourceSessionId = 0;

  constructor(data?: PartialMessage<UpdateTeacherResourceSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.UpdateTeacherResourceSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "teacher_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTeacherResourceSessionResponse {
    return new UpdateTeacherResourceSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTeacherResourceSessionResponse {
    return new UpdateTeacherResourceSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTeacherResourceSessionResponse {
    return new UpdateTeacherResourceSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTeacherResourceSessionResponse | PlainMessage<UpdateTeacherResourceSessionResponse> | undefined, b: UpdateTeacherResourceSessionResponse | PlainMessage<UpdateTeacherResourceSessionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateTeacherResourceSessionResponse, a, b);
  }
}

/**
 * CreateStudentResourceSessionRequest represents a request to create a student's resource session.
 *
 * @generated from message geneo.lms.common.apis.CreateStudentResourceSessionRequest
 */
export class CreateStudentResourceSessionRequest extends Message<CreateStudentResourceSessionRequest> {
  /**
   * ID of the student associated with the resource session
   *
   * @generated from field: int64 student_id = 1;
   */
  studentId = protoInt64.zero;

  /**
   * ID of the resource associated with the session
   *
   * @generated from field: string resource_id = 2;
   */
  resourceId = "";

  /**
   * ID of the school where the lesson session takes place
   *
   * @generated from field: int32 school_id = 3;
   */
  schoolId = 0;

  /**
   * Academic year associated with the resource session
   *
   * @generated from field: int32 academic_year = 4;
   */
  academicYear = 0;

  /**
   * Grade associated with the resource session
   *
   * @generated from field: int32 class_id = 5;
   */
  classId = 0;

  /**
   * Section associated with the resource session
   *
   * @generated from field: string section = 6;
   */
  section = "";

  /**
   * Subject associated with the resource session
   *
   * @generated from field: geneo.common.db.Subject.SubjectEnum subject = 7;
   */
  subject = Subject_SubjectEnum.UNDEFINED;

  /**
   * Module ID associated with the resource session
   *
   * @generated from field: int32 module_id = 8;
   */
  moduleId = 0;

  /**
   * ID of the student's lesson session (optional)
   *
   * @generated from field: int32 student_lesson_session_id = 9;
   */
  studentLessonSessionId = 0;

  /**
   * Mode of the resource session (e.g., online, offline, etc.)
   *
   * @generated from field: geneo.lms.db.SessionModeEnum session_mode = 10;
   */
  sessionMode = SessionModeEnum.SESSION_MODE_UNDEFINED;

  /**
   * Start time of the resource session
   *
   * @generated from field: google.protobuf.Timestamp start_time = 11;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 12;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<CreateStudentResourceSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.CreateStudentResourceSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "school_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "academic_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "class_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "section", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "subject", kind: "enum", T: proto3.getEnumType(Subject_SubjectEnum) },
    { no: 8, name: "module_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "student_lesson_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "session_mode", kind: "enum", T: proto3.getEnumType(SessionModeEnum) },
    { no: 11, name: "start_time", kind: "message", T: Timestamp },
    { no: 12, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStudentResourceSessionRequest {
    return new CreateStudentResourceSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStudentResourceSessionRequest {
    return new CreateStudentResourceSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStudentResourceSessionRequest {
    return new CreateStudentResourceSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStudentResourceSessionRequest | PlainMessage<CreateStudentResourceSessionRequest> | undefined, b: CreateStudentResourceSessionRequest | PlainMessage<CreateStudentResourceSessionRequest> | undefined): boolean {
    return proto3.util.equals(CreateStudentResourceSessionRequest, a, b);
  }
}

/**
 * CreateStudentResourceSessionResponse represents a response for creating a student's resource session.
 *
 * @generated from message geneo.lms.common.apis.CreateStudentResourceSessionResponse
 */
export class CreateStudentResourceSessionResponse extends Message<CreateStudentResourceSessionResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * ID of the created student's resource session
   *
   * @generated from field: int32 student_resource_session_id = 4;
   */
  studentResourceSessionId = 0;

  constructor(data?: PartialMessage<CreateStudentResourceSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.CreateStudentResourceSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "student_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateStudentResourceSessionResponse {
    return new CreateStudentResourceSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateStudentResourceSessionResponse {
    return new CreateStudentResourceSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateStudentResourceSessionResponse {
    return new CreateStudentResourceSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateStudentResourceSessionResponse | PlainMessage<CreateStudentResourceSessionResponse> | undefined, b: CreateStudentResourceSessionResponse | PlainMessage<CreateStudentResourceSessionResponse> | undefined): boolean {
    return proto3.util.equals(CreateStudentResourceSessionResponse, a, b);
  }
}

/**
 * UpdateStudentResourceSessionRequest represents a request to update a student's resource session.
 *
 * @generated from message geneo.lms.common.apis.UpdateStudentResourceSessionRequest
 */
export class UpdateStudentResourceSessionRequest extends Message<UpdateStudentResourceSessionRequest> {
  /**
   * ID of the student's resource session
   *
   * @generated from field: int32 student_resource_session_id = 1;
   */
  studentResourceSessionId = 0;

  /**
   * ID of the student associated with the resource session
   *
   * @generated from field: int64 student_id = 2;
   */
  studentId = protoInt64.zero;

  /**
   * ID of the resource associated with the session
   *
   * @generated from field: string resource_id = 3;
   */
  resourceId = "";

  /**
   * Status of the resource session
   *
   * @generated from field: geneo.lms.db.SessionStatusEnum session_status = 4;
   */
  sessionStatus = SessionStatusEnum.SESSION_STATUS_UNDEFINED;

  /**
   * Teacher content response (JSON as string)
   *
   * @generated from field: repeated geneo.lms.db.TeacherContentResponse response = 5;
   */
  response: TeacherContentResponse[] = [];

  /**
   * End time of the resource session (optional)
   *
   * @generated from field: google.protobuf.Timestamp end_time = 6;
   */
  endTime?: Timestamp;

  constructor(data?: PartialMessage<UpdateStudentResourceSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.UpdateStudentResourceSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "student_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "student_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_status", kind: "enum", T: proto3.getEnumType(SessionStatusEnum) },
    { no: 5, name: "response", kind: "message", T: TeacherContentResponse, repeated: true },
    { no: 6, name: "end_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentResourceSessionRequest {
    return new UpdateStudentResourceSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentResourceSessionRequest {
    return new UpdateStudentResourceSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentResourceSessionRequest {
    return new UpdateStudentResourceSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentResourceSessionRequest | PlainMessage<UpdateStudentResourceSessionRequest> | undefined, b: UpdateStudentResourceSessionRequest | PlainMessage<UpdateStudentResourceSessionRequest> | undefined): boolean {
    return proto3.util.equals(UpdateStudentResourceSessionRequest, a, b);
  }
}

/**
 * UpdateStudentResourceSessionResponse represents a response for updating a student's resource session.
 *
 * @generated from message geneo.lms.common.apis.UpdateStudentResourceSessionResponse
 */
export class UpdateStudentResourceSessionResponse extends Message<UpdateStudentResourceSessionResponse> {
  /**
   * Status code
   *
   * @generated from field: int32 status = 1;
   */
  status = 0;

  /**
   * Error code
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * Error message
   *
   * @generated from field: string message = 3;
   */
  message = "";

  /**
   * ID of the updated student's resource session
   *
   * @generated from field: int32 student_resource_session_id = 4;
   */
  studentResourceSessionId = 0;

  constructor(data?: PartialMessage<UpdateStudentResourceSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.lms.common.apis.UpdateStudentResourceSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "student_resource_session_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStudentResourceSessionResponse {
    return new UpdateStudentResourceSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStudentResourceSessionResponse {
    return new UpdateStudentResourceSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStudentResourceSessionResponse {
    return new UpdateStudentResourceSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStudentResourceSessionResponse | PlainMessage<UpdateStudentResourceSessionResponse> | undefined, b: UpdateStudentResourceSessionResponse | PlainMessage<UpdateStudentResourceSessionResponse> | undefined): boolean {
    return proto3.util.equals(UpdateStudentResourceSessionResponse, a, b);
  }
}

